<template>
  <div class="form-content" style="padding: 20px">
    <el-steps :active="active" finish-status="success" align-center style="margin-bottom: 20px">
      <el-step title="Client Information" icon="el-icon-office-building" :status="clientStatus"></el-step>
      <el-step title="Application Information" icon="el-icon-medal" :status="appStatus"></el-step>
      <el-step title="Done" icon="el-icon-check" :status="lastStatus"></el-step>
    </el-steps>
    <!-- CLIENT FORM -->
    <el-card>
      <el-form :model="clientForm" ref="clientForm" label-position="top" v-if="active === 0">
        <el-form-item label="New or existing client" prop="type"
          :rules="[
            { required: true, message: 'This field is required', trigger: [ 'change', 'blur' ] }
          ]">
          <el-select
            style="width: 50%"
            v-model="clientForm.type"
            placeholder="Please Select">
            <el-option label="Existing Client" value="existing"></el-option>
            <el-option label="New Client" value="new"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Client / Company Name" prop="client" v-if="clientForm.type === 'existing'"
          :rules="[
            { required: true, message: 'This field is required', trigger: [ 'change', 'blur' ] }
          ]">
          <el-select
            style="width: 50%"
            v-model="clientForm.client"
            value-key="code"
            filterable
            default-first-option
            placeholder="Find existing Client">
            <el-option
              v-for="item in clients"
              :key="item.code"
              :label="item.name"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="clientForm.type === 'new'"
          label="Client Code"
          prop="code"
          :rules="[
            { required: true, type: 'string', pattern: /^[A-Z]{2}$/, message: 'Must be exact 2 uppercase letters. E.g: HC, DR', trigger: [ 'change', 'blur' ] }
          ]">
          <el-input
            v-model="clientForm.code"
            name="code"
            style="max-width: 280px;"
            type="text"
            autocomplete="off"
            placeholder="E.g: HC, DR" />
            <p class="form-help-text">For internal use only</p>
        </el-form-item>
        <el-form-item
          v-if="clientForm.type === 'new'"
          label="Client Name"
          prop="name"
          :rules="[
            { min: 3, message: 'At least 3 characters', trigger: [ 'change', 'blur', 'input' ] },
            { required: true, message: 'This is required', trigger: [ 'change', 'blur' ] }
          ]">
          <el-input
            v-model="clientForm.name"
            name="code"
            style="max-width: 280px;"
            type="text"
            autocomplete="off"
            placeholder="E.g: TravelToMe" />
            <p class="form-help-text">Owner / Company Name</p>
        </el-form-item>
      </el-form>

      <!-- APPLICATION FORM -->
      <el-form
        v-if="active === 1"
        label-position="top"
        :model="localForm"
        ref="localForm"
        class="">
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="12">
            <el-form-item
              label="Label"
              prop="label"
              :rules="[
                { min: 3, message: 'At least 3 characters', trigger: [ 'change', 'blur', 'input' ] },
                { required: true, message: 'This is required', trigger: [ 'change', 'blur' ] }
              ]">
              <el-input
                v-model="localForm.label"
                name="formLabel"
                style="max-width: 280px;"
                type="text"
                autocomplete="off"
                placeholder="E.g: TravelToMe" />
                <p class="form-help-text">For internal use only</p>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12">
            <el-form-item
              label="App code"
              prop="appCode"
              :error="appCodeError"
              style="margin-bottom: 60px;"
              :rules="[
                { required: true, type: 'string', pattern: /^[A-Z]{4}$/, message: 'Must be exact 4 uppercase letters. E.g: ZUMA', trigger: [ 'change', 'blur' ] }
              ]">
              <template slot="label">
                App code
              </template>
              <el-input
                style="max-width: 280px;"
                v-model.trim="localForm.appCode"
                name="formAppCode"
                type="text"
                autocomplete="off"
                placeholder="E.g: ZUMA" />
                <p class="form-help-text">A unique identity for this app. It must be 4 capital letters. <br >E.g: ZUMA, GLOB, TIFR ...
                  <br> <span class="has-text-dark has-text-weight-semibold">(Modification is not allowed afterwards)</span>
                </p>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item
          label="Brand name"
          prop="brandName"
          :rules="[
            { min: 3, message: 'At least 3 characters', trigger: [ 'change', 'blur', 'input' ] },
            { required: true, message: 'This is required', trigger: [ 'change', 'blur' ] }
          ]">
          <template slot="label">
            Brand name / Site name
          </template>
          <el-input
            v-model="localForm.brandName"
            name="formBrandName"
            type="text"
            autocomplete="off"
            placeholder="How do you want your customer to address you?" />
            <p class="form-help-text">Display as booking site name too</p>
        </el-form-item>
        <el-form-item
          label="Owner"
          prop="owner"
          :rules="[
            { min: 3, message: 'At least 3 characters', trigger: [ 'change', 'blur', 'input' ] },
            { required: true, message: 'This is required', trigger: [ 'change', 'blur' ] }
          ]">
          <template slot="label">
            Owner / Company name
          </template>
          <el-input
            v-model="localForm.owner"
            name="formOwner"
            type="text"
            autocomplete="off"
            placeholder="E.g: mycompany pte. ltd." />
            <p class="form-help-text">Your company legal name. It will be show in terms & privacy page</p>
        </el-form-item>
        <el-form-item
          style="width: 90%;"
          label="Use RTX DNS"
          prop="autoRegisterDns"
        >
          <el-switch
            v-model="localForm.autoRegisterDns"
            active-color="#15b77c"
            inactive-color="#909399"
            active-text="Use RTX Domain"
            inactive-text="Setup Later"
          />
          <p class="form-help-text">Use Rakuten Travel xChange DNS as a base domain.</p>
        </el-form-item>
        <el-form-item
          v-if="localForm.autoRegisterDns"
          label="Domain Name"
          prop="subDomainName"
          :rules="[
            { min: 2, message: 'At least 2 characters', trigger: [ 'change', 'blur', 'input' ] }
          ]">
          <template slot="label">
            Booking Site URL
          </template>
          <el-input
            v-model="localForm.subDomainName"
            name="subDomainName"
            type="text"
            autocomplete="off"
            placeholder="your-subdomain-name">
            <template slot="prepend">https://</template>
            <el-select v-model="localForm.domainName" slot="append" placeholder="Select Domain Name">
              <el-option label="hotelbookingservices.co" value="hotelbookingservices.co"></el-option>
              <el-option label="hchotelconnect.com" value="hchotelconnect.com"></el-option>
            </el-select>
          </el-input>
          <p class="form-help-text">Your Booking Site URL.
            <span class="has-text-dark has-text-weight-semibold">(Can be configured later)</span>
          </p>
        </el-form-item>
      </el-form>

      <div v-if="isSuccess && active === 2">
        <i class="el-icon-success has-text-success is-size-1 is-pulled-left" />
        <div style="margin-left: 70px;">
          <h5 class="is-size-5 has-text-weight-semibold has-text-black-ter">
            New application {{ localForm.label }} has been created successfully
          </h5>
          <div class="gap"></div>
          <div class="message is-small">
            <div class="message-body">
              <p class="has-text-dark has-text-weight-semibold">Next steps:</p>
              <ul class="has-text-primary">
                <li>- Configure Application Features.</li>
                <li>- Configure Application Settings.</li>
                <li>- Configure Payment.</li>
                <li>- Add external user.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="list is-gapless">
        <div class="list-content">
          <el-button
            v-if="active > 0 && active < 2"
            style="margin-top: 12px;"
            @click="active--"
            :loading="isLoading">
            Back
          </el-button>
        </div>
        <div class="list-action">
          <el-button
            style="margin-top: 12px;"
            @click="next"
            :loading="isLoading"
            type="success">
            {{ active === 1 ? 'Submit' : 'Next' }}
          </el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { applicationsNew } from '../factories/manage'

export default {
  name: 'NewApplicationForm',
  props: {
    email: {
      type: String
    },
    clients: {
      types: Array
    }
  },
  data () {
    return {
      active: 0,
      appStatus: 'wait',
      clientStatus: 'process',
      lastStatus: 'wait',
      isSuccess: false,
      isLoading: false,
      appCodeError: undefined,
      error: null,
      localForm: {},
      clientForm: {}
    }
  },
  computed: {
    ...mapState({
    })
  },
  created () {
  },
  methods: {
    next () {
      if (this.active === 0) {
        this.$refs.clientForm.validate((valid) => {
          if (valid) {
            if (this.clientForm.type === 'existing') {
              this.localForm.owner = this.clientForm.client.name
            } else {
              this.localForm.owner = this.clientForm.name
            }
            this.active++
            this.clientStatus = 'success'
          } else {
            this.clientStatus = 'error'
          }
        })
      } else if (this.active === 1) {
        this.isLoading = true
        this.isSuccess = false
        this.error = null
        this.$refs.localForm.validate((valid) => {
          if (valid) {
            this.appStatus = 'success'
            this.appCodeError = undefined
            const payload = {
              ...this.localForm,
              client: {}
            }
            if (this.clientForm.type === 'existing') {
              payload.client.type = this.clientForm.type
              payload.client.name = this.clientForm.client.name
              payload.client.code = this.clientForm.client.code
            } else {
              payload.client.type = this.clientForm.type
              payload.client.name = this.clientForm.name
              payload.client.code = this.clientForm.code
            }
            applicationsNew(payload)
              .then(() => {
                this.isSuccess = true
                this.isLoading = false
                this.active++
                this.lastStatus = 'success'
              }).catch((err) => {
                this.isLoading = false
                if (err.code === 'appcode_exist') {
                  this.appCodeError = `${this.localForm.appCode} has been taken. Change it and try again`
                  this.error = {
                    message: 'App code has been taken.',
                    suggestedActions: [
                      { message: 'Change the App code' }
                    ]
                  }
                  this.appStatus = 'error'
                  return
                }
                this.error = {
                  message: err.message
                }
              })
          } else {
            this.isLoading = false
            this.appStatus = 'error'
          }
        })
      } else {
        this.handleClose()
      }
    },
    handleClose () {
      this.$router.push({
        name: 'manageApplicationsDetails',
        params: { appCode: this.localForm.appCode }
      })
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .el-input-group__append .el-select {
    width: 300px !important;
  }
</style>
