<template>
  <div>
    <span v-if="skip">HIDE</span>
    <span v-else>
      <slot name="content">{{ content }}</slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'PricingPreviewCell',
  props: {
    skip: {
      type: Boolean,
      required: true
    },
    content: {
      type: String,
      required: true
    }
  }
}
</script>

<style>

</style>
