<template>
  <section class="section is-small">
    <div class="">
      <h4 class="title is-4">Roles Management</h4>
      <div class="columns" v-if="permissionCodes.length">
        <div class="column">
          <RolesFilter @change="handleRoleFiltered" />
        </div>
        <div class="column is-narrow" style="padding-top: 1.7rem;">
          <el-button type="primary" icon="el-icon-plus" @click="addNewRole">Add new role</el-button>
        </div>
        <div class="column is-narrow" style="padding-top: 1.7rem;">
          <el-button type="success" @click="publish">Publish</el-button>
        </div>
      </div>
      <PpGap />
      <div class="box" v-if="permissionCodes.length">
        <el-table :data="roles" row-key="code" max-height="650" border style="width: 100%" size="medium" class="users--table" header-cell-class-name="users--table-th" :row-class-name="tableRowClassName">
          <el-table-column label="Roles" prop="label" width="300" fixed sortable>
            <template slot-scope="{ row }">
              <span class="has-text-weight-semibold">
                {{ row.name }} {{row.clientCode ? '(' + row.clientCode + ')' : ''}}
              </span>
              <br>
              <span class="has-text-grey">
                {{ row.type | roleTypeName }}
              </span>
              <div style="float:right;">
                <el-link type="primary" :underline="false" @click="handelEditRole(row)">Edit</el-link>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Select all" width="90" align="center" header-align="center">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.select" @change="(v)=>{handleToggleAll(v,scope.row)}"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column v-for="(group,groupName) of groupedPermissions" :key="groupName" :label="groupName" align="center" header-align="center">
            <el-table-column v-for="(checkField) of group" :key="checkField.code" align="center" min-width="110" show-overflow-tooltip>
              <template slot="header">
                <el-tooltip class="item" effect="light" placement="top" v-if="checkField.description">
                  <span slot="content" v-html="checkField.description"></span>
                  <span>{{ checkField.label }}</span>
                </el-tooltip>
                <span v-else>{{ checkField.label }}</span>
              </template>
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.permissionCheckboxs[checkField.code]" :disabled="mx_user.level < 900 && checkField.internal" @change="(v)=>{handleCheckBoxChange(v,scope.row)}"></el-checkbox>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <RoleEditModal :open.sync="openForm" :role="editRole" :roles="roles" @submit="roleFormSubmit" @cancel="roleFormCancel" />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { rolesList, updateRolesPermissions, createRole, updateRole, getPermissions } from '../../factories/manage'
import RolesFilter from '../../components/RolesFilter'
import { xor } from 'lodash'
import RoleEditModal from '../../components/RoleEditModal'
import { Message } from 'element-ui'

/**
 * role.editStatus 0: none, 1:edit, 2:new
 */
export default {
  name: 'ManageRoles',
  components: { RoleEditModal, RolesFilter },
  props: {
    query: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  filters: {
    roleTypeName (val) {
      const types = [
        {
          label: 'Internal',
          value: 'INTERNAL'
        },
        {
          label: 'Client',
          value: 'CLIENT'
        },
        {
          label: 'Application',
          value: 'APPLICATION'
        }
      ]
      const f = types.find(e => e.value === val)
      return f && f.label
    }
  },
  data () {
    return {
      roles: [],
      openForm: false,
      editRole: undefined
    }
  },
  computed: {
    ...mapGetters(['groupedPermissions', 'permissionCodes'])
  },
  created () {
    rolesList()
    getPermissions()
  },
  methods: {
    handleRoleFiltered (selectRoles) {
      this.roles = selectRoles
    },
    handleToggleAll (v, row) {
      let keys = Object.keys(row.permissionCheckboxs)
      let values = {}
      keys.forEach((k) => {
        values[k] = v
      })
      row.permissionCheckboxs = Object.assign({}, values)
    },
    handleCheckBoxChange (v, row) {
      if (!v) {
        row.select = false
      } else if (Object.values(row.permissionCheckboxs).every((item) => item)) {
        row.select = true
      }
    },
    publish () {
      this.$confirm(`Are you sure you want to publish changes?`, 'Publish Changes', {
        confirmButtonText: 'Yes, publish',
        cancelButtonText: 'No, discard changes',
        dangerouslyUseHTMLString: true,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            let postData = this.sanitizePostData()
            if (!postData) {
              done()
              return
            }
            instance.confirmButtonLoading = true
            instance.confirmButtonText = 'Please wait...'
            updateRolesPermissions(postData).then((data) => {
              rolesList().then(() => {
                Message({
                  message: 'Roles Permissions has been updated',
                  type: 'success'
                })

                instance.confirmButtonLoading = false
                done()
              })
            }).catch(e => {
              instance.confirmButtonLoading = false
              done()
            })
          } else {
            done()
          }
        }
      }).catch(e => {
        console.log(e)
      })
    },
    sanitizePostData () {
      let data = {}
      let isChanged = false
      this.roles.forEach(role => {
        // Modified or not
        let checkBoxTrueData = []
        let keys = Object.keys(role.permissionCheckboxs)
        keys.forEach((k) => {
          if (role.permissionCheckboxs[k]) {
            checkBoxTrueData.push(k)
          }
        })
        let oldData = [...role.permissions]
        let diff = xor(oldData, checkBoxTrueData)
        if (diff.length || role.editStatus > 0) {
          isChanged = true
          data[role.code] = checkBoxTrueData
        }
      })
      return isChanged ? data : false
    },
    roleFormSubmit (data) {
      if (this.editRole) { // edit
        this.editRole.name = data.name
        this.editRole.description = data.description
        updateRole({
          code: this.editRole.code,
          name: this.editRole.name,
          description: this.editRole.description,
          inactive: this.editRole.inactive
        }).then(data => {
          rolesList().then(() => {
            Message({
              message: 'role has been updated',
              type: 'success'
            })

            if (this.editRole.editStatus === 0) {
              this.editRole.editStatus = 1
            }
          })
        })
      } else { // new
        let permissionCheckboxs = {}
        this.permissionCodes.forEach((code) => {
          permissionCheckboxs[code] = false
        })
        let newRole = Object.assign({}, {
          ...data,
          editStatus: 2,
          permissions: [],
          select: false,
          permissionCheckboxs
        })
        // this.roles.unshift(newRole)
        createRole(newRole).then((data) => {
          rolesList().then(() => {
            Message({
              message: 'New role has been added',
              type: 'success'
            })
          })
        })
      }
    },
    roleFormCancel () {
      this.editRole = undefined
    },
    addNewRole () {
      this.openForm = true
    },
    handelEditRole (row) {
      console.log(row)
      this.editRole = row
      this.openForm = true
    },
    tableRowClassName ({ row, rowIndex }) {
      if (row.editStatus === 1) {
        return 'warning-row'
      } else if (row.editStatus === 2) {
        return 'success-row'
      }
      return ''
    }
  }
}
</script>

<style lang="scss">
  @import '../../styles/bulma-variables';

  .el-table .cell {
    word-break: break-word !important;
  }

  .el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
</style>
