<template>
  <section class="section is-small">
    <div v-if="localDetails && localDetails.role">
      <div class="list is-gapless">
        <div class="list-pre">
          <HistoryLink
            :historyBack="true"
            label="back"
            :route="{ name: 'manageUsers' }">
            <i class="el-icon-back is-size-4" />
          </HistoryLink>
        </div>
        <div class="list-content">
          <h4 class="title is-5">{{details.displayName || details.email}}</h4>
        </div>
        <div class="list-action">
          <!-- <el-button
            type="primary"
            icon="el-icon-plus"
            size="small">
            INVITE NEW USER
          </el-button> -->
        </div>
      </div>
      <div class="columns">
        <div class="column is-5">
          <div class="list">
            <div class="list-content">
              <div class="list-label has-text-primary has-text-weight-semibold">Details</div>
            </div>
          </div>
        <el-form
          ref="localForm"
          @submit.native.prevent=""
          :model="localDetails">
          <div class="box is-stick">
            <div class="list has-shadow-bottom is-comfort" v-if="checkPermission(['USERS_EDIT'])">
              <div class="list-content">
                <div class="list-value  has-text-weight-semibold">User status</div>
                <div class="list-label"
                  v-if="details.inactive">
                  User can not login and all his activities has been suspended.
                </div>

              </div>
              <div :class="['list-action is-uppercase is-flex']">
                <InactiveFlag :inactive="details.inactive" />
                &nbsp;
                <el-switch
                  v-model="localDetails.inactive"
                  @change="doDetailsSubmit({ inactive: localDetails.inactive })"
                  :active-value="false"
                  :inactive-value="true"
                  active-color="#15b77c"
                  inactive-color="#909399"/>
              </div>
            </div>
            <div class="list is-comfort has-shadow-bottom">
              <div class="list-content">
                <div class="list-label">Email</div>
                  <el-form-item
                    prop="email"
                    :rules="[
                      {
                        required: true,
                        message: 'This is required.',
                        trigger: ['blur', 'change', 'input']
                      },
                      {
                        type: 'email',
                        message: 'Please input correct email address',
                        trigger: ['blur', 'change'],
                      },
                    ]"
                  >
                    <el-input
                      :disabled="!checkPermission(['USERS_EDIT'])"
                      name="formEmail"
                      size="small"
                      placeholder="Current Email address"
                      v-model="localDetails.email">
                    </el-input>
                  </el-form-item>
              </div>
              <div class="list-action">
                <el-button type="primary"
                  size="small"
                  :disabled="localDetails.email === details.email"
                  @click="doDetailsSubmit({ email: details.email, newEmail: localDetails.email }, 'email')"
                >
                  SAVE
              </el-button>
              </div>
            </div>
            <div class="list is-comfort has-shadow-bottom">
              <div class="list-content">
                <div class="list-label">Display name</div>
                  <el-form-item
                    prop="displayName"
                    :rules="[
                    { required: true, message: 'This is required.', trigger: ['blur', 'change', 'input']}
                    ]">
                    <el-input
                      :disabled="!checkPermission(['USERS_EDIT'])"
                      name="formDisplayName"
                      size="small"
                      placeholder="How to address this user?"
                      v-model="localDetails.displayName">
                    </el-input>
                  </el-form-item>
              </div>
              <div class="list-action">
                <el-button type="primary"
                  size="small"
                  :disabled="localDetails.displayName === details.displayName"
                  @click="doDetailsSubmit({ displayName: localDetails.displayName }, 'displayName')">SAVE</el-button>
              </div>
            </div>
            <div class="list is-comfort has-shadow-bottom">
              <div class="list-content">
                <div class="list-label">Role</div>
                <div class="list-value">
                  <el-form-item
                    prop="role.code"
                    :rules="[
                      { required: true, message: 'This is required.' }
                    ]">
                  <el-select
                    :disabled="!checkPermission(['USERS_EDIT'])"
                    autocomplete="no"
                    placeholder="Select user role"
                    v-model="localDetails.role.code"
                    name="formRoleCode"
                    style="width: 100%;"
                    @change="doDetailsSubmit({ roleCode: localDetails.role.code }, 'role.code')">
                    <el-option v-for="it in rolesToUse" :key="it.a3" :value="it.code" :label="it.name">
                    <span class="is-pulled-left">{{ it.name }} {{mx_user.roleType === 'INTERNAL' && it.clientCode ? '(' + it.clientCode + ')' : ''}}</span>
                    <el-tag
                      v-if="it.description"
                      class="is-pulled-right"
                      style="margin: 6px;"
                      size="mini"
                      :type="it.type === 'INTERNAL' ? 'danger' : 'primary'">
                        <span>{{it.description}}</span>
                    </el-tag>
                  </el-option>
                  </el-select>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="list is-comfort">
              <div class="list-content">
                <div class="list-label">Invited by</div>
                <div class="list-value">{{ details.invitedBy && details.invitedBy.displayName }}</div>
              </div>
            </div>
          </div>
          </el-form>
          <div class="box">
            <div class="list">
              <div class="list-content">
                <div class="list-label has-text-primary has-text-weight-semibold">
                  <i class="el-icon-time"/> History
                </div>
              </div>
            </div>
            <div class="list is-small has-shadow-bottom"
              v-for="it in sort(history)"
              :key="it.createdAt">
              <div class="list-content">
                <div class="list-label">{{ it.createdAt | date }}</div>
                  <div class="list-value">
                    {{ getType(it.type) }} &nbsp;
                    <el-popover
                      v-if="it.req"
                      placement="top-start"
                      title="Request"
                      trigger="click"
                    >
                      <pre class="is-size-7">{{ it.req }}</pre>
                      <a class="is-size-7" slot="reference">Request</a>
                    </el-popover>
                    &nbsp;
                    <el-popover
                      v-if="it.res"
                      placement="top-start"
                      title="Response"
                      trigger="click"
                    >
                      <pre class="is-size-7">{{ it.res }}</pre>
                      <a class="is-size-7" slot="reference">Response</a>
                    </el-popover>
                    &nbsp;
                    <el-popover
                      v-if="it.res"
                      placement="top-start"
                      title="Request"
                      trigger="click"
                    >
                      <pre class="is-size-7" v-if="mailStatus">{{ mailStatus }}</pre>
                      <el-button
                        slot="reference"
                        type="primary"
                        icon="el-icon-search"
                        @click="checkStatus(it.res)"
                        :loading="checkMailStatusLoading"
                        size="small">
                        Check Mail Status
                      </el-button>
                    </el-popover>
                </div>
              </div>
              <div class="list-action has-text-right" style="width: 100px;">
                <div class="list-label">By</div>
                <div class="list-value has-text-ellipsis">{{ it.by || '–' }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-7">
          <div class="list">
            <div class="list-content">
              <div class="list-label has-text-primary has-text-weight-semibold">Applications ({{localApps.length}})</div>
            </div>
            <div class="list-action">

            </div>
          </div>
          <div class="box">
            <div class="list has-border-bottom" style="min-height: 54px;" v-if="checkPermission(['USERS_EDIT'])">
              <div class="list-content">
                <span class="list-value has-text-weight-semibold" v-if="addingNewApp">
                  Click add to assign it to this user.</span>
              </div>
              <div class="list-action">
                <el-button
                  v-if="!addingNewApp"
                  type="primary"
                  icon="el-icon-plus"
                  @click="addNewApp(true)"
                  :loading="loadingNewApp"
                  :disabled="loadingNewApp"
                  size="small">
                  ADD NEW
                </el-button>
              </div>
            </div>
            <div v-if="addingNewApp">
            <div class="nested--table">
              <div class="list has-border-bottom">
                <div class="list-content">
                <p class="title is-7 is-marginless">All applications</p>
                </div>
                <div class="list-action">
                  <el-button
                  class="is-size-4"
                  type="text"
                  icon="el-icon-close"
                  size="mini"
                  @click="addNewApp(false)"
                  >
                </el-button>
                </div>
              </div>
            <el-table
              empty-text="This user has access to all applications"
              class="users--table"
              :data="localAppsList"
              size="mini"
              stripe
              max-height="300"
              header-cell-class-name="users--table-th"
              style="width: 100%">
              <el-table-column
                class="has-text-ellipsis"
                label="Label"
                prop="label"
                min-width="140">
              </el-table-column>
              <el-table-column
                fixed="right"
                prop="inactive"
                label=""
                width="90"
                align="center">
                <template slot-scope="{ row }">
                  <el-button
                    @click="handleAppRow(row)"
                    size="mini"
                    type="success">
                    ADD
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            </div>
            <div class="list">
              <div class="list-content has-text-centered">
                <div class="is-size-7"><i class="el-icon-question" /> Click the add button above to assign application to this user</div>
                <i class="el-icon-d-arrow-right is-size-4" style="transform: rotate(90deg)" />
              </div>
            </div>
            </div>
            <el-table
              empty-text="Not assign any app yet"
              class="users--table"
              :data="localApps"
              stripe
              header-cell-class-name="users--table-th"
              style="width: 100%">
              <el-table-column
                label="#"
                type="index"
                width="30">
              </el-table-column>
              <el-table-column
                class="has-text-ellipsis"
                label="Label"
                prop="label"
                min-width="140">
                <template slot-scope="{ row }">
                  <router-link
                    :to="{ name: 'manageApplicationsDetails', params: { appCode: row.appCode } }">
                    {{ row.label }}
                  </router-link>
                </template>
              </el-table-column>
              <el-table-column
                prop="inactive"
                label="Status"
                width="90"
                align="center">
                <template slot-scope="{ row }">
                  <InactiveFlag :inactive="row && row.inactive" />
                </template>
              </el-table-column>
              <el-table-column
                v-if="checkPermission(['USERS_EDIT'])"
                label=""
                width="100"
                align="center">
                <template slot-scope="{ row }">
                <el-switch
                  v-model="row.inactive"
                  @change="handleAppRow(row)"
                  :active-value="false"
                  :inactive-value="true"
                  active-color="#15b77c"
                  inactive-color="#909399">
                </el-switch>
                &nbsp;
                <el-popover
                  placement="top"
                  width="180"
                  v-model="row.deleteConfirmation">
                  <div style="text-align: left;">
                    <p class="title is-6">Are you sure?</p>
                    <div style="text-align: right; margin: 0">
                      <el-button size="mini" type="text" @click="row.deleteConfirmation = false">NO</el-button>
                      <el-button type="danger" size="mini" @click="handleAppRow(row, true)">YES</el-button>
                    </div>
                  </div>
                  <el-button
                    slot="reference"
                    class="has-text-danger is-size-6"
                    type="text"
                    icon="el-icon-delete"
                    size="mini">
                  </el-button>
                </el-popover>
                </template>
              </el-table-column>
            </el-table>
            <div class="list" v-if="checkPermission(['USERS_EDIT'])">
              <div class="list-content">
                <p class="is-size-7"><i class="el-icon-info has-text-primary"/> Toggle it to "inactive" to remove this user from the application. E.g: Temporary revoke the user access to the application</p>
                <p class="is-size-7"><i class="el-icon-info has-text-primary"/> Click "delete" to completely removed from this user. You might add it back later.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="list-content">
        <div class="list-value has-text-weight-semibold">User not found or you don't have permission to view this user</div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import {
  usersOne,
  rolesList,
  applicationsEditUsers,
  applicationsList,
  usersEdit,
  checkMailStatus
} from '../../factories/manage'
import checkPermission from '@/plugins/permission'

export default {
  name: 'ManageUsersDetails',
  props: {
    uid: {
      type: String,
      required: true
    },
    query: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      localDetails: {},
      localApps: [],
      addingNewApp: false,
      loadingNewApp: false,
      checkMailStatusLoading: false,
      mailStatus: {}
    }
  },
  computed: {
    ...mapState({
      details: state => state.usersDetails.details,
      role: state => state.usersDetails.details.role && state.usersDetails.details.role.name,
      history: state => state.usersDetails.details.history,
      apps: state => state.usersDetails.details.apps,
      appsList: state => state.apps.list,
      allRoles: state => state.roles.list
    }),
    ...mapGetters(['appRoles']),
    appIndex () {
      return this.localApps.map(ele => ele.appCode)
    },
    localAppsList () {
      return JSON.parse(
        JSON.stringify(
          this.appsList.filter(ele => !this.appIndex.includes(ele.appCode))
        )
      ).sort((a, b) => a.label.toUpperCase() <= b.label.toUpperCase() ? -1 : 1) || []
    },
    rolesToUse () {
      return this.allRoles.filter(role => role.type === this.details.role.type && role.clientCode === this.details.role.clientCode)
    }
  },
  watch: {
    'apps': {
      immediate: true,
      handler (nV) {
        this.localApps = this.removeNull(JSON.parse(JSON.stringify(nV))).sort((a, b) => a.createdAt <= b.createdAt ? -1 : 1)
      }
    },
    'details': {
      immediate: true,
      handler (nV) {
        this.localDetails = JSON.parse(JSON.stringify(nV))
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    rolesList()
    usersOne({ uid: to.params.uid })
      .then(() => next())
      .catch((err) => {
        console.error(err)
        next()
      })
  },
  methods: {
    checkPermission,
    removeNull (val) {
      if (val == null) return val
      return val.filter(ele => ele != null)
    },
    addNewApp (boo) {
      this.loadingNewApp = boo
      applicationsList()
        .then(() => {
          this.addingNewApp = boo
          this.loadingNewApp = false
        })
        .catch(() => {
          this.loadingNewApp = false
        })
    },
    doDetailsSubmit (val, field) {
      const body = {
        ...val,
        uid: this.uid
      }
      const submit = () => {
        usersEdit(body)
          .then(() => {
            return usersOne({ uid: this.uid })
          })
          .then(() => {
            this.$refs.localForm.clearValidate()
          })
          .catch((err) => {
            console.error(err)
          })
      }

      if (field == null) {
        submit()
      } else {
        this.$refs.localForm.validateField(field, (error) => {
          if (!error) {
            submit()
          }
        })
      }
    },
    handleAppRow (val, isDelete) {
      let body = isDelete
        ? { delete: true }
        : {
          inactive: val.inactive,
          roleCode: this.localDetails.role.code
        }
      applicationsEditUsers({
        ...body,
        appCode: val.appCode,
        uid: this.uid
      }).then(() => {
        return usersOne({ uid: this.uid })
      }).catch((err) => {
        console.error(err)
      })
    },
    sort (val) {
      if (!val) return
      const cloned = JSON.parse(JSON.stringify(val))
      return cloned.sort((a, b) => b.createdAt - a.createdAt)
    },
    getType (historyType) {
      let result = historyType
      switch (historyType) {
        case 'CREATED':
          result = 'User has been created'
          break
        case 'EDITED':
          result = 'User has been edited'
          break
        case 'INVITATION_ISSUED':
          result = 'Sending invitation email'
          break
        case 'INVITATION_REISSUED':
          result = 'Resend invitation email'
          break
        case 'INVITATION_ACCEPTED':
          result = 'Invitation has been accepted'
          break
        case 'FORGOT_PASSWORD':
          result = 'Forgot Password'
          break
      }
      return result
    },
    checkStatus (payload) {
      this.checkMailStatusLoading = true
      if (!payload || payload.length === 0) return
      const mailId = payload[0]._id
      checkMailStatus({
        clientCode: this.details.role.clientCode,
        mailId
      }).then(res => {
        this.checkMailStatusLoading = false
        this.mailStatus = res.details
      }).catch(err => {
        console.log(err)
        this.checkMailStatusLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/bulma-variables';
  .autouser .el-select-dropdown__item {
    padding: $bleed / 2 $bleed;
    height: auto;
  }
  .users--table /deep/ .users--table-th {
    padding-bottom: $bleed;
    color: $grey;
    border-color: $grey-lighter;
    border-width: 2px;
    background-color: $white-bis;
  }
  .users--table /deep/ .is-hidden {
    display: table-cell !important;
  }
  .nested--table {
    margin: $bleed * 2;
    border: 1px solid $grey-lighter;
  }
</style>
