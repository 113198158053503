<template>
  <section class="section is-small">
    <div class="">
      <h4 class="title is-4">Features Management</h4>
      <div class="columns" v-if="featureCodes.length">
        <div class="column">
          <ApplicationFilter @change="handleAppFiltered" />
        </div>
        <div class="column is-narrow" style="padding-top: 1.7rem;">
          <el-button type="success" icon="el-icon-plus" @click="publish">Publish</el-button>
        </div>
      </div>
      <PpGap />
      <div class="box" v-if="featureCodes.length">
        <el-table :data="apps" row-key="appCode" max-height="650" border style="width: 100%" size="medium" class="users--table" header-cell-class-name="users--table-th">
          <el-table-column label="Application" prop="label" width="300" fixed sortable>
            <template slot-scope="{ row }">
              <router-link class="has-text-weight-semibold" :to="{ name: 'manageApplicationsDetails', params: { appCode: row.appCode} }">
                {{ row.label }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column label="Select all" width="90" align="center" header-align="center">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.select" @change="(v)=>{handleToggleAll(v,scope.row)}"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column v-for="(group,groupName) of groupedFeatures" :key="groupName" :label="groupName" align="center" header-align="center">
            <el-table-column v-for="(checkField) of group" :key="checkField.code" align="center" min-width="110" show-overflow-tooltip>
              <template slot="header">
                <el-tooltip class="item" effect="light" placement="top">
                  <span slot="content" v-html="checkField.description"></span>
                  <span>{{ checkField.label }}</span>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.featureCheckboxs[checkField.code]" :disabled="mx_user.level < 900 && checkField.internal" @change="(v)=>{handleCheckBoxChange(v,scope.row)}"></el-checkbox>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
      <div v-else>
        <div class="list-content">
          <div class="list-value has-text-weight-semibold">You don't have any feature to manage</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { applicationsList, updateFeatures, getFeatures } from '../../factories/manage'
import ApplicationFilter from '../../components/ApplicationFilter'
import { xor } from 'lodash'
import { Message } from 'element-ui'

export default {
  name: 'ManageFeatures',
  components: { ApplicationFilter },
  props: {
    query: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      apps: []
    }
  },
  computed: {
    ...mapGetters(['groupedFeatures', 'featureCodes'])
  },
  created () {
    applicationsList()
    getFeatures()
  },
  methods: {
    handleAppFiltered (selectApps) {
      this.apps = selectApps
    },
    handleToggleAll (v, row) {
      let keys = Object.keys(row.featureCheckboxs)
      let values = {}
      keys.forEach((k) => {
        values[k] = v
      })
      row.featureCheckboxs = Object.assign({}, values)
    },
    handleCheckBoxChange (v, row) {
      if (!v) {
        row.select = false
      } else if (Object.values(row.featureCheckboxs).every((item) => item)) {
        row.select = true
      }
    },
    publish () {
      this.$confirm(`Are you sure you want to publish changes?`, 'Publish Changes', {
        confirmButtonText: 'Yes, publish',
        cancelButtonText: 'No, discard changes',
        dangerouslyUseHTMLString: true,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            let postData = this.sanitizePostData()
            if (!postData) {
              done()
              return
            }
            instance.confirmButtonLoading = true
            instance.confirmButtonText = 'Please wait...'
            updateFeatures(postData).then((data) => {
              applicationsList().then(() => {
                Message({
                  message: 'Application features has been updated',
                  type: 'success'
                })
                instance.confirmButtonLoading = false
                done()
              })
            }).catch(e => {
              instance.confirmButtonLoading = false
              done()
            })
          } else {
            done()
          }
        }
      }).catch(e => {
        console.log(e)
      })
    },
    sanitizePostData () {
      let data = {}
      let isChanged = false
      this.apps.forEach(app => {
        // Modified or not
        let checkBoxTrueData = []
        let keys = Object.keys(app.featureCheckboxs)
        keys.forEach((k) => {
          if (app.featureCheckboxs[k]) {
            checkBoxTrueData.push(k)
          }
        })
        let oldData = [...app.features]
        let diff = xor(oldData, checkBoxTrueData)
        if (diff.length) {
          isChanged = true
          data[app.appCode] = checkBoxTrueData
        }
      })
      return isChanged ? data : false
    }
  }
}
</script>

<style lang="scss">
  @import '../../styles/bulma-variables';
  .el-table .cell {
    word-break: break-word !important;
  }
</style>
