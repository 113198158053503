<template>
  <el-upload
    ref="upload"
    action="#"
    :name="name"
    :multiple="false"
    :show-file-list="false"
    :before-upload="handleBeforeUpload"
    :auto-upload="true"
    class="upload-container"
    :http-request="httpRequest"
  >
    <img :src="localValue.url" v-if="localValue.url">
    <div class="overlay"></div>
    <i v-show="localValue.url&&!uploading" class="el-icon-delete" @click.prevent="handleRemove"></i>
    <i v-show="!localValue.url&&!uploading" class="el-icon-plus" slot="trigger"></i>
    <i v-show="uploading" class="el-icon-loading"></i>
  </el-upload>
</template>

<script>
import lv2 from '../factories/lv2Api'

export default {
  name: 'LogoUpload',
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          id: '',
          url: '',
          width: ''
        }
      }
    },
    name: {
      type: String,
      default: 'brand'
    },
    size: {
      type: Number,
      default: 500 // kb
    }
  },
  data () {
    return {
      localValue: { ...this.value },
      uploading: false
    }
  },
  watch: {
    'value': {
      immediate: true,
      handler (nV) {
        this.localValue = { ...nV }
      }
    }
  },
  methods: {
    handleBeforeUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isPng = file.type === 'image/png'

      const isLtSize = file.size / 1024 / 1024 <= this.size / 1000

      if (!isJPG && !isPng) {
        this.$message.error('Please upload a image in JPG / PNG format.')
      }
      if (!isLtSize) {
        this.$message.error(`Please upload a image smaller than ${this.size}kb.`)
      }
      return (isJPG || isPng) && isLtSize
    },
    handleSuccess (res) {
      this.localValue = {
        id: '',
        url: res.url,
        width: ''
      }
      this.onChange()
    },
    handleRemove () {
      this.$confirm('Do you want to remove the image?', 'Tips', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.$refs.upload.clearFiles()
        this.localValue = {
          id: '',
          url: '',
          width: ''
        }
        // TODO remove file from server?
        this.onChange()
      })
    },
    onChange () {
      this.$emit('change', this.localValue)
    },
    handleUploadError (error) {
      console.log(error)
      this.$message.error('Upload failed.')
    },
    httpRequest (options) {
      this.uploading = true
      let data = new FormData()
      data.append(options.filename, options.file)
      lv2.post('/uploadFiles', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        const results = response.data.outlets.results
        if (results.length > 0) {
          this.handleSuccess(results[0]) // LogoUpload only upload one file
        }
      }).catch((error) => {
        this.handleUploadError(error)
      }).finally(() => {
        this.uploading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
  $border-radius: 5px;
  .upload-container {
    width: 148px;
    height: 148px;
    overflow: hidden;
    position: relative;
    border-radius: $border-radius;
    border: 1px dashed #c0ccda;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 24px;
      position: absolute;
      z-index: 3;
      cursor: pointer;
      color: #fff;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      line-height: 148px;
      display: block;

    }

    img {
      object-fit: contain;
      object-position: center center;
      position: relative;
      z-index: 1;
    }

    .overlay {
      border-radius: $border-radius;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.4);
      position: absolute;
      z-index: 2;

    }
  }
</style>
