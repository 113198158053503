<template>
  <div>
    <el-form
      ref="localForm"
      label-position="top"
      :model="localForm">
        <el-form-item style="width: 100%" label="Group Name">
          <el-input
            name="name"
            size="medium"
            autocomplete="off"
            placeholder="Name of Group"
            v-model="localForm.name">
          </el-input>
        </el-form-item>
        <el-form-item style="width: 100%" label="Keywords" class="border">
          <el-select
            style="width: 100%"
            v-model="localForm.keywords"
            multiple
            filterable
            allow-create
            default-first-option
            no-data-text="Type any keywords to be matched with hotel name">
          </el-select>
          <p class="form-help-text">Keywords to be matched with hotel name</p>
        </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'PreferredHotelForm',
  props: {
    value: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data () {
    return {
      localForm: {
        name: '',
        keywords: []
      }
    }
  },
  mounted () {
    this.localForm = this.value
  },
  methods: {
    handleBlur () {
      this.$emit('input', { ...this.localForm })
    }
  }
}
</script>
