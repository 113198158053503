var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.error)?_c('div',[_c('i',{staticClass:"el-icon-warning has-text-danger is-size-1 is-pulled-left"}),_c('div',{staticStyle:{"margin-left":"70px"}},[_c('h5',{staticClass:"is-size-5 has-text-weight-semibold has-text-black-ter"},[_vm._v(" Error! "+_vm._s(_vm.error.message)+" ")]),_c('div',{staticClass:"gap"}),(_vm.error.suggestedActions)?_c('div',{staticClass:"message is-small"},[_c('div',{staticClass:"message-body"},[_c('p',{staticClass:"has-text-dark has-text-weight-semibold"},[_vm._v("You might want to:")]),_c('ul',{staticClass:"has-text-primary"},_vm._l((_vm.error.suggestedActions),function(it){return _c('li',{key:it.message},[_vm._v(" "+_vm._s(it.message)+" ")])}),0)])]):_vm._e()]),_c('div',{staticClass:"gap-x2"})]):_vm._e(),(_vm.isSuccess)?_c('div',[_c('i',{staticClass:"el-icon-success has-text-success is-size-1 is-pulled-left"}),_c('div',{staticStyle:{"margin-left":"70px"}},[_c('h5',{staticClass:"is-size-5 has-text-weight-semibold has-text-black-ter"},[_vm._v(" Invitation had been sent to "+_vm._s(_vm.localForm.email)+" ")]),_c('div',{staticClass:"gap"}),(!_vm.appCode)?_c('div',{staticClass:"message is-small"},[_vm._m(0)]):_vm._e(),_c('div',{staticClass:"list is-gapless"},[_c('div',{staticClass:"list-content"}),_c('div',{staticClass:"list-action"},[_c('el-button',{on:{"click":_vm.handleClose}},[_vm._v("Close")])],1)])])]):_c('div',{staticClass:"form-content is-gapless"},[_c('el-form',{ref:"localForm",attrs:{"label-position":"top","model":_vm.localForm},nativeOn:{"submit":function($event){return _vm.doSubmit.apply(null, arguments)}}},[_c('el-form-item',{attrs:{"label":"Email","prop":"email","rules":[
            { type: 'email', message: 'Invalid email format', trigger: [ 'change', 'blur', 'input' ] },
            { required: true, message: 'Email is required', trigger: [ 'change', 'blur' ] }
          ]}},[_c('el-input',{attrs:{"name":"formEmail","type":"email","autocomplete":"off","placeholder":"E.g: yourname@gmail.com"},model:{value:(_vm.localForm.email),callback:function ($$v) {_vm.$set(_vm.localForm, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"localForm.email"}})],1),_c('el-form-item',{attrs:{"label":"Role","prop":"roleCode","rules":[
            { required: true, message: 'Role is required', trigger: [ 'change', 'blur' ] }
          ]}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"autocomplete":"no","placeholder":"Select user role","name":"formRoleCode"},model:{value:(_vm.localForm.roleCode),callback:function ($$v) {_vm.$set(_vm.localForm, "roleCode", $$v)},expression:"localForm.roleCode"}},_vm._l((_vm.rolesToUse),function(it){return _c('el-option',{key:it.code,attrs:{"value":it.code,"label":it.name}},[_c('span',{staticClass:"is-pulled-left"},[_vm._v(_vm._s(it.name)+" "+_vm._s(_vm.mx_user.roleType === 'INTERNAL' && it.clientCode ? '(' + it.clientCode + ')' : ''))]),(it.description)?_c('el-tag',{staticClass:"is-pulled-right",staticStyle:{"margin":"6px"},attrs:{"size":"mini","type":it.type === 'INTERNAL' ? 'danger' : 'primary'}},[_c('span',[_vm._v(_vm._s(it.description))])]):_vm._e()],1)}),1)],1),(!_vm.appCode && _vm.apps.length > 0 && (_vm.mx_user.roleType === 'INTERNAL' || _vm.mx_user.roleType === 'CLIENT'))?_c('el-form-item',{attrs:{"label":"Applications","prop":"appCodes","rules":[
            { required: true, message: 'At least one application must be selected', trigger: [ 'change', 'blur' ] }
          ]}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"value-key":"appCode","multiple":"","filterable":"","default-first-option":"","placeholder":"Assign application to the user invited"},model:{value:(_vm.localForm.appCodes),callback:function ($$v) {_vm.$set(_vm.localForm, "appCodes", $$v)},expression:"localForm.appCodes"}},_vm._l((_vm.apps),function(item){return _c('el-option',{key:item.appCode,attrs:{"label":`${item.label}`,"value":item.appCode}})}),1),_c('p',{staticClass:"form-help-text"},[_c('strong',[_vm._v("This user will be able to access selected applications.")])])],1):_vm._e(),_c('div',{staticClass:"list is-gapless"},[_c('div',{staticClass:"list-content"}),_c('div',{staticClass:"list-action"},[_c('el-button',{attrs:{"native-type":"submit","loading":_vm.isLoading,"type":"success"}},[_vm._v(" Invite ")])],1)])],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"message-body"},[_c('p',{staticClass:"has-text-dark has-text-weight-semibold"},[_vm._v("Meanwhile, you might want to:")]),_c('ul',{staticClass:"has-text-primary"},[_c('li',[_vm._v("Assign application to the created new user.")])])])
}]

export { render, staticRenderFns }