<template>
  <section class="section is-small">
    <!-- Content -->
    <div class="columns">
      <div class="column is-8 is-offset-2">
        <el-tabs tab-position="left">
          <el-tab-pane label="Static Data" v-if="checkPermission(['STATIC_DATA_API'])">
            <div class="list">
              <div class="list-content">
                <div class="list-title has-text-primary has-text-weight-semibold">Static Data Details</div>
              </div>
            </div>
            <div class="box">
              <div class="form-content">
                <el-form
                  inline
                  ref="v3Form"
                  label-position="top"
                  @submit.native.prevent
                  :model="localDetails.config"
                >
                  <div class="list is-narrow is-gapless">
                    <div class="list-content">
                      <div class="list-title has-text-weight-semibold">Live credential</div>
                    </div>
                  </div>
                  <el-form-item
                    style="width: 60%;"
                    label="API key"
                    prop="zuesProdApiKey"
                    :rules="[
                    { required: true, message: 'This is required.', trigger: ['blur', 'change', 'input']}
                    ]"
                  >
                    <el-input
                      size="medium"
                      name="zuesProdApiKey"
                      autocomplete="off"
                      placeholder="E.g: xxxxx-xxxxxx-xxxxx-xxxx-xx"
                      v-model="localDetails.config.zuesProdApiKey"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    style="width: 33%;"
                    label="App ID"
                    prop="zuesProdAppId"
                    :rules="[
                      { required: true, message: 'This is required.', trigger: ['blur', 'change', 'input']},
                      { type: 'number', message: 'It must be integer', trigger: ['blur', 'change', 'input']}
                    ]"
                  >
                    <el-input
                      style="width: 90px;"
                      size="medium"
                      name="zuesProdAppId"
                      autocomplete="off"
                      placeholder="E.g: id"
                      v-model.number="localDetails.config.zuesProdAppId"
                    ></el-input>
                    <p class="form-help-text">For reference purpose.</p>
                  </el-form-item>
                  <div class="list is-gapless has-border-top is-narrow"></div>
                  <div class="list is-narrow is-gapless">
                    <div class="list-content">
                      <div class="list-title has-text-weight-semibold">Test credential</div>
                    </div>
                  </div>
                  <el-form-item style="width: 60%;" label="Test API key" prop="zuesTestApiKey">
                    <el-input
                      name="zuesTestApiKey"
                      autocomplete="off"
                      size="medium"
                      placeholder="E.g: xxxxx-xxxxxx-xxxxx-xxxx-xx"
                      v-model="localDetails.config.zuesTestApiKey"
                    ></el-input>
                  </el-form-item>
                  <el-form-item style="width: 33%;" label="Test App ID" prop="zuesTestAppId">
                    <el-input
                      style="width: 90px;"
                      name="zuesProdAppId"
                      size="medium"
                      autocomplete="off"
                      placeholder="E.g: id"
                      v-model.number="localDetails.config.zuesTestAppId"
                    ></el-input>
                    <p class="form-help-text">For reference purpose.</p>
                  </el-form-item>
                  <el-form-item
                    style="width: 100%"
                    label="Specific Mode"
                    prop="specificMode"
                  >
                    <BlockFlag
                      class="has-text-weight-semibold"
                      style="padding-left: 6px"
                      :boo="localDetails.config.v3Keys.specificMode"
                      falseText="Disallow"
                      trueText="Allow"
                    />&nbsp;
                    <el-switch
                      v-model="localDetails.config.v3Keys.specificMode"
                      active-color="#15b77c"
                      inactive-color="#909399"
                    />
                  </el-form-item>
                  <div v-if="localDetails.config.v3Keys.specificMode" style="margin-bottom: 20px">
                    <V3CredentialForm
                      v-for="(item, idx) in localDetails.config.v3Keys.specificKeys"
                      :key="idx"
                      v-model="localDetails.config.v3Keys.specificKeys[idx]"
                      style="margin-bottom: 10px" />
                    <el-button type="primary" size="small" icon="el-icon-plus" @click="handleAddSpecificKey()">Add key</el-button>
                  </div>
                  <div class="list is-gapless has-border-top is-narrow"></div>
                  <el-form-item style="width: 90%;" label="Webhook URL">
                    <el-input
                      readonly
                      type="textarea"
                      autocomplete="off"
                      size="medium"
                      v-model="v3WebhookUrl"
                    ></el-input>
                    <p class="form-help-text">Copy and paste this into your V3 webhook setting</p>
                  </el-form-item>
                </el-form>
              </div>
              <div class="list is-comfort has-border-top">
                <div class="list-content"></div>
                <div class="list-action">
                  <el-button type="text" :disabled="v3FormNew" @click="resetV3Form()">RESET</el-button>&nbsp;
                  <el-button
                    type="success"
                    size="medium"
                    :disabled="v3FormNew"
                    @click="saveV3({
                      zuesTestAppId: localDetails.config.zuesTestAppId,
                      zuesTestApiKey: localDetails.config.zuesTestApiKey,
                      zuesProdAppId: localDetails.config.zuesProdAppId,
                      zuesProdApiKey: localDetails.config.zuesProdApiKey,
                      v3Keys: localDetails.config.v3Keys
                    })"
                  >SAVE</el-button>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Mail Setting" v-if="checkPermission(['MAIL_SETTING'])">
            <!-- MAIL FORM -->
            <div class="list">
              <div class="list-content">
                <div class="list-title has-text-primary has-text-weight-semibold">Mail Setting</div>
              </div>
            </div>
            <article class="box">
              <div class="form-content">
                <el-form
                  ref="mailForm"
                  label-position="top"
                  @submit.native.prevent=""
                  :model="localDetails.config.more.emails"
                  inline>
                  <el-form-item
                    style="width: 90%;"
                    label="Custom Mandrill Account"
                    prop="customMandrill"
                  >
                    <BlockFlag
                      style="margin-left:5px"
                      :boo="localDetails.config.more.emails.customMandrill"
                      falseText="NO"
                      trueText="YES"
                    />&nbsp;
                    <el-switch
                      v-model="localDetails.config.more.emails.customMandrill"
                      active-color="#15b77c"
                      inactive-color="#909399"
                    />
                    <p class="form-help-text">If set to <strong>'NO'</strong>&nbsp;&nbsp;<u>RTX's Mandrill account</u> will be used</p>
                  </el-form-item>
                  <el-form-item
                    v-if="localDetails.config.more.emails.customMandrill"
                    prop="mandrillAPIKey"
                    style="width:100%">
                    <div class="is-inline-block" slot="label">
                      Mandrill API Key
                    </div>
                    <el-input
                      name="mandrillAPIKey"
                      size="medium"
                      autocomplete="off"
                      placeholder="xxxxxxxxx_xxxxxxxxxx"
                      v-model="localDetails.config.more.emails.mandrillAPIKey">
                    </el-input>
                    <p class="form-help-text">Please check <a href="https://mandrill.zendesk.com/hc/en-us/articles/205582197-Where-do-I-find-my-SMTP-credentials-">Mandrill's Guide</a> on how to get API key</p>
                  </el-form-item>
                </el-form>
              </div>
              <div class="list is-comfort has-border-top">
                <div class="list-content">
                </div>
                <div class="list-action">
                  <el-button type="text"
                    :disabled="mailInfoFormNew"
                    @click="resetMailForm()">
                    RESET
                  </el-button>
                  &nbsp;
                  <el-button type="success"
                    size="medium"
                    :disabled="mailInfoFormNew"
                    @click="saveMailConfig({
                      more: {
                        emails: {
                          ...localDetails.config.more.emails
                        }
                      }
                    })">SAVE</el-button>
                </div>
              </div>
            </article>
          </el-tab-pane>
          <el-tab-pane label="Fax Setting" v-if="hasFeatures(['ENABLE_FAX']) && checkPermission(['FAX_SETTING']) && localDetails.config.more.faxSettings">
            <!-- FAX FORM -->
            <div class="list">
              <div class="list-content">
                <div class="list-title has-text-primary has-text-weight-semibold">Fax Setting</div>
              </div>
            </div>
            <article class="box">
              <div class="form-content">
                <el-form
                  ref="faxForm"
                  label-position="top"
                  @submit.native.prevent=""
                  :model="localDetails.config.more.interfax"
                  inline>
                  <el-form-item
                    style="width: 90%;"
                    label="Live Mode"
                    prop="liveMode"
                  >
                    <BlockFlag
                      style="margin-left:5px"
                      :boo="localDetails.config.more.interfax.liveMode"
                      falseText="NO"
                      trueText="YES"
                    />&nbsp;
                    <el-switch
                      v-model="localDetails.config.more.interfax.liveMode"
                      active-color="#15b77c"
                      inactive-color="#909399"
                    />
                  </el-form-item>
                  <div class="message is-small is-marginless is-warning" v-if="!localDetails.config.more.interfax.liveMode">
                    <div class="message-body">
                      <p class="is-size-7 has-line-height-1-25 has-text-dark">
                        These limitations are:
                        <ol>
                          <li>
                            By destination: a developer account can send faxes to one of the following numbers:
                            <ul>
                              <li>- A designated fax number provided during registration (see below).</li>
                              <li>- A dummy number that will mimic behavior of fax delivery (see below).</li>
                            </ul>
                          </li>
                          <li>
                            By total cost: a developer account is issued with a certain amount of free credits for sending faxes. The cost of faxes sent will be deducted from these credits until they run out. Once depleted, the developer will need to contact our developer support to add more credits to their account.
                          </li>
                        </ol>
                      </p>
                    </div>
                  </div>
                  <el-form-item
                    v-if="localDetails.config.more.interfax.liveMode"
                    :rules="[
                      { required: true, message: 'Username is required', trigger: [ 'change', 'blur' ] }
                    ]"
                    prop="username"
                    style="width:100%">
                    <div class="is-inline-block" slot="label">
                      Username
                    </div>
                    <el-input
                      name="username"
                      size="medium"
                      autocomplete="off"
                      placeholder="johndoe"
                      v-model="localDetails.config.more.interfax.username">
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    v-if="localDetails.config.more.interfax.liveMode"
                    :rules="[
                      { required: true, message: 'Password is required', trigger: [ 'change', 'blur' ] }
                    ]"
                    prop="password"
                    style="width:100%">
                    <div class="is-inline-block" slot="label">
                      Password
                    </div>
                    <el-input
                      name="password"
                      size="medium"
                      autocomplete="off"
                      placeholder="xxx"
                      v-model="localDetails.config.more.interfax.password">
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    v-if="localDetails.config.more.interfax.liveMode"
                    :rules="[
                      { required: true, message: 'API URL is required', trigger: [ 'change', 'blur' ] }
                    ]"
                    prop="url"
                    style="width:100%">
                    <div class="is-inline-block" slot="label">
                      API URL
                    </div>
                    <el-input
                      name="url"
                      size="medium"
                      autocomplete="off"
                      placeholder="https://interfax.com"
                      v-model="localDetails.config.more.interfax.url">
                    </el-input>
                  </el-form-item>
                </el-form>
              </div>
              <div class="list is-comfort has-border-top">
                <div class="list-content">
                </div>
                <div class="list-action">
                  <el-button type="text"
                    :disabled="faxFormNew"
                    @click="$refs.faxForm.resetFields()">
                    RESET
                  </el-button>
                  &nbsp;
                  <el-button type="success"
                    size="medium"
                    :disabled="faxFormNew"
                    @click="saveFaxConfig({
                      more: {
                        interfax: {
                          ...localDetails.config.more.interfax
                        }
                      }
                    })">SAVE</el-button>
                </div>
              </div>
            </article>
          </el-tab-pane>
          <el-tab-pane label="3rd Party" v-if="checkPermission(['THIRD_PARTY'])">
            <!-- Google tag FORM -->
            <div class="list">
              <div class="list-content">
                <div class="list-title has-text-primary has-text-weight-semibold">
                  Google tag manager ID
                </div>
              </div>
            </div>
            <article class="box">
              <div class="form-content">
                <el-form
                  ref="thirdPartyForm"
                  label-position="top"
                  @submit.native.prevent=""
                  :model="localDetails.config.more">
                  <el-form-item
                    prop="gtm.tagId">
                    <div class="is-inline-block" slot="label">
                      <span>Google tag manager ID</span>&nbsp;<OfflineConfig />
                    </div>
                    <el-input
                      name="gtmId"
                      size="medium"
                      autocomplete="off"
                      placeholder="E.g: GTM-XXXXX"
                      v-model="localDetails.config.more.gtm.tagId">
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    style="width: 100%;"
                    label="Google map api key"
                    :rules="[
                      { required: true, message: 'This is required.', trigger: ['blur', 'change', 'input']}
                    ]"
                    prop="googleMap.apiKey"
                  >
                    <template slot="label">
                      <span>Google map API key</span>&nbsp;
                      <OfflineConfig />
                    </template>
                    <el-input
                      type="text"
                      size="medium"
                      name="mapApiKey"
                      autocomplete="off"
                      placeholder="xxxx"
                      v-model="localDetails.config.more.googleMap.apiKey"
                    ></el-input>
                    <p class="form-help-text">
                      Get it from Google
                      <a
                        href="https://developers.google.com/maps/documentation/embed/get-api-key"
                        target="_blank"
                      >(show me how)</a>
                    </p>
                  </el-form-item>
                </el-form>
                <div class="message is-small is-primary is-marginless">
                  <div class="message-body">
                    <p class="is-size-7 has-line-height-1-25 has-text-weight-semibold">
                      <i class="el-icon-warning has-text-primary" /> Please use a billing enabled key.
                      <br />
                      Oh remember to whitelisted your hostname ({{localDetails.config.domainName}}) too.
                    </p>
                  </div>
                </div>
                <PpGap />
              </div>
              <div class="list is-comfort has-border-top">
                <div class="list-content">
                </div>
                <div class="list-action">
                  <el-button type="text"
                  :disabled="gtmFormNew && gooleMapFormNew"
                    @click="$refs.thirdPartyForm.resetFields()">
                    RESET
                  </el-button>
                  &nbsp;
                  <el-button type="success"
                    size="medium"
                    :disabled="gtmFormNew && gooleMapFormNew"
                    @click="submitThirdPartyForm({
                      gtm: {
                        tagId: localDetails.config.more.gtm.tagId,
                      },
                      googleMap: {
                        apiKey: localDetails.config.more.googleMap.apiKey
                      }
                    })">SAVE</el-button>
                </div>
              </div>
            </article>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <PpGap size="large" />
  </section>
</template>

<script>
import V3CredentialForm from '@/components/V3CredentialForm'
import checkPermission from '@/plugins/permission'
import hasFeatures from '@/plugins/feature'

export default {
  name: 'ManageApplicationsDetailsCredentials',
  components: {
    V3CredentialForm
  },
  props: {
    appCode: {
      type: String,
      required: true
    },
    query: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      debounceTimer: undefined,
      localDetails: {},
      loadingNewApp: false,
      selectedRow: {},
      isFetching: false
    }
  },
  computed: {
    details () {
      return this.$parent.details
    },
    v3FormNew () {
      let specificKeysNotChanged = false
      if (this.localDetails.config.v3Keys.specificMode) {
        for (let i = 0; i < this.details.config.v3Keys.specificKeys.length; i++) {
          if (
            (this.details.config.v3Keys.specificKeys[i].live.appId === this.localDetails.config.v3Keys.specificKeys[i].live.appId) &&
              (this.details.config.v3Keys.specificKeys[i].live.apiKey === this.localDetails.config.v3Keys.specificKeys[i].live.apiKey) &&
              (this.details.config.v3Keys.specificKeys[i].test.appId === this.localDetails.config.v3Keys.specificKeys[i].test.appId) &&
              (this.details.config.v3Keys.specificKeys[i].test.apiKey === this.localDetails.config.v3Keys.specificKeys[i].test.apiKey)
          ) {
            specificKeysNotChanged = true
          }
        }
      }
      return (
        this.localDetails.config.zuesTestAppId ===
          this.details.config.zuesTestAppId &&
        this.localDetails.config.zuesTestApiKey ===
          this.details.config.zuesTestApiKey &&
        this.localDetails.config.zuesProdAppId ===
          this.details.config.zuesProdAppId &&
        this.localDetails.config.zuesProdApiKey ===
          this.details.config.zuesProdApiKey &&
        this.localDetails.config.v3Keys.specificMode ===
          this.details.config.v3Keys.specificMode &&
        this.localDetails.config.v3Keys.specificKeys.length ===
          this.details.config.v3Keys.specificKeys.length &&
        specificKeysNotChanged
      )
    },
    v3WebhookUrl () {
      return `${process.env.VUE_APP_LV2_API_HOST}/web/callback/order`
    },
    gooleMapFormNew () {
      return (
        (this.localDetails.config.more.googleMap &&
          this.localDetails.config.more.googleMap.apiKey) ===
        (this.details.config.more.googleMap &&
          this.details.config.more.googleMap.apiKey)
      )
    },
    /**
     * Check if mail config form is edited
     */
    mailInfoFormNew () {
      return this.localDetails.config.more.emails.mandrillAPIKey === this.details.config.more.emails.mandrillAPIKey &&
      this.localDetails.config.more.emails.customMandrill === this.details.config.more.emails.customMandrill
    },
    /**
     * Check if Google Tag form is edited
     */
    gtmFormNew () {
      return (this.localDetails.config.more.gtm && this.localDetails.config.more.gtm.tagId) ===
        (this.details.config.more.gtm && this.details.config.more.gtm.tagId)
    },
    faxFormNew () {
      return this.localDetails.config.more.interfax.url === this.details.config.more.interfax.url &&
        this.localDetails.config.more.interfax.username === this.details.config.more.interfax.username &&
        this.localDetails.config.more.interfax.password === this.details.config.more.interfax.password &&
        this.localDetails.config.more.interfax.liveMode === this.details.config.more.interfax.liveMode
    }
  },
  watch: {
    details: {
      immediate: true,
      handler (nV) {
        this.localDetails = JSON.parse(JSON.stringify(nV))
        this.localDetails.config.more = {
          googleMap: {},
          gtm: {},
          ...this.localDetails.config.more
        }
      }
    }
  },
  methods: {
    checkPermission,
    hasFeatures,
    saveV3 (val) {
      const body = {
        ...val,
        appCode: this.appCode
      }
      this.$refs.v3Form.validate(valid => {
        if (valid) {
          // validate v3Keys value
          const specificKeys = val.v3Keys.specificKeys
          const testKeys = specificKeys.map(key => key.test)
          const liveKeys = specificKeys.map(key => key.live)
          if (val.v3Keys.specificMode && (!testKeys.filter(t => t.appId && t.apiKey).length || !liveKeys.filter(t => t.appId && t.apiKey).length)) {
            this.$message.error('Invalid additional keys form.')
            return
          }
          this.$parent
            .postApplicationEditConfigurations(body)
            .then(() => this.$refs.v3Form.clearValidate())
        }
      })
    },
    submitThirdPartyForm (val) {
      const body = {
        more: {
          ...val
        },
        appCode: this.appCode
      }
      this.$refs.thirdPartyForm.validate(valid => {
        if (valid) {
          this.$parent.postApplicationEditConfigurations(body)
            .then(() => this.$refs.thirdPartyForm.clearValidate())
        }
      })
    },
    resetV3Form () {
      this.$refs.v3Form.resetFields()
      this.localDetails.config.v3Keys = this.details.config.v3Keys
    },
    handleAddSpecificKey () {
      this.localDetails.config.v3Keys.specificKeys.push({ live: {}, test: {} })
    },
    /**
     * @onsubmit Mail Config Form
     */
    saveMailConfig (val) {
      const body = {
        ...val,
        appCode: this.appCode
      }
      this.$refs.mailForm.validate((valid) => {
        if (valid) {
          this.$parent.postApplicationEditConfigurations(body)
            .then(() => this.$refs.mailForm.clearValidate())
        }
      })
    },
    saveFaxConfig (val) {
      const body = {
        ...val,
        appCode: this.appCode
      }
      this.$refs.faxForm.validate((valid) => {
        if (valid) {
          this.$parent.postApplicationEditConfigurations(body)
            .then(() => this.$refs.faxForm.clearValidate())
        }
      })
    },
    /**
     * @onreset Mail Form
     * Reseting using el-form is not doable because its nested object. instead we set those fields back manually
     */
    resetMailForm () {
      this.localDetails.config.more.emails.customMandrill = this.details.config.more.emails.customMandrill
      this.localDetails.config.more.emails.mandrillAPIKey = this.details.config.more.emails.mandrillAPIKey
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/bulma-variables";
.autouser .el-select-dropdown__item {
  padding: $bleed / 2 $bleed;
  height: auto;
}
.currency-checkbox {
  margin-left: 0;
  width: 90px;
}
.nested--table {
  margin: $bleed * 2;
  border: 1px solid $grey-lighter;
}
.payment-from--name {
  width: 100%;
}
@include mobile {
  .payment-from--name {
    width: 100%;
  }
}
// TODO: put it into variable
.payment-base {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: $text;
  display: block;
  font-size: inherit;
  outline: none;
  padding: 0px 15px;
  width: 100%;
  height: 40px;
  line-height: 40px;
}
.payment-invalid {
  border-color: $danger;
}
.payment-complete {
  border-color: $success;
}
.payment-invalid + .payment-error-message {
  transform: translateY(0);
  opacity: 1;
}
.payment-error-message {
  transition: transform 100ms ease-out;
  transform: translateY(-10px);
  opacity: 0;
}
</style>
