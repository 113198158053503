<template>
  <div>
    <div v-if="error">
      <i class="el-icon-warning has-text-danger is-size-1 is-pulled-left" />
      <div style="margin-left: 70px;">
        <h5 class="is-size-5 has-text-weight-semibold has-text-black-ter">
          Error! {{ error.message }}
        </h5>
        <div class="gap"></div>
        <div class="message is-small"
          v-if="error.suggestedActions">
          <div class="message-body">
            <p class="has-text-dark has-text-weight-semibold">You might want to:</p>
            <ul class="has-text-primary">
              <li v-for="it in error.suggestedActions" :key="it.message">
                {{ it.message }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="gap-x2"></div>
    </div>
    <div
      v-if="isSuccess">
      <i class="el-icon-success has-text-success is-size-1 is-pulled-left" />
      <div style="margin-left: 70px;">
        <h5 class="is-size-5 has-text-weight-semibold has-text-black-ter">
          Invitation had been sent to {{ localForm.email }}
        </h5>
        <div class="gap"></div>
        <div class="message is-small"
          v-if="!appCode">
          <div class="message-body">
            <p class="has-text-dark has-text-weight-semibold">Meanwhile, you might want to:</p>
            <ul class="has-text-primary">
              <li>Assign application to the created new user.</li>
            </ul>
          </div>
        </div>
        <div class="list is-gapless">
          <div class="list-content"></div>
          <div class="list-action">
            <el-button
              @click="handleClose"
            >Close</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-content is-gapless"
      v-else>
        <el-form
          label-position="top"
          :model="localForm"
          ref="localForm"
          @submit.native="doSubmit"
          class="">
          <el-form-item
            label="Email"
            prop="email"
            :rules="[
              { type: 'email', message: 'Invalid email format', trigger: [ 'change', 'blur', 'input' ] },
              { required: true, message: 'Email is required', trigger: [ 'change', 'blur' ] }
            ]">
            <el-input
              v-model.trim="localForm.email"
              name="formEmail"
              type="email"
              autocomplete="off"
              placeholder="E.g: yourname@gmail.com" />
          </el-form-item>
          <el-form-item
            label="Role"
            prop="roleCode"
            :rules="[
              { required: true, message: 'Role is required', trigger: [ 'change', 'blur' ] }
            ]">
            <el-select
              autocomplete="no"
              placeholder="Select user role"
              v-model="localForm.roleCode"
              name="formRoleCode"
              style="width: 100%;">
              <el-option v-for="it in rolesToUse" :key="it.code" :value="it.code" :label="it.name">
                <span class="is-pulled-left">{{ it.name }} {{mx_user.roleType === 'INTERNAL' && it.clientCode ? '(' + it.clientCode + ')' : ''}}</span>
                <el-tag
                  v-if="it.description"
                  class="is-pulled-right"
                  style="margin: 6px;"
                  size="mini"
                  :type="it.type === 'INTERNAL' ? 'danger' : 'primary'">
                    <span>{{it.description}}</span>
                </el-tag>
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <div class="message is-danger is-small"
            v-if="codeToRole(localForm.roleCode).level === 800">
            <div class="message-header is-uppercase">Client role type selected</div>
            <div class="message-body"><i class="el-icon-warning" /> Please only apply it to internal user.</div>
          </div> -->
          <el-form-item
            v-if="!appCode && apps.length > 0 && (mx_user.roleType === 'INTERNAL' || mx_user.roleType === 'CLIENT')"
            label="Applications"
            prop="appCodes"
            :rules="[
              { required: true, message: 'At least one application must be selected', trigger: [ 'change', 'blur' ] }
            ]">
            <el-select
              v-model="localForm.appCodes"
              value-key="appCode"
              multiple
              filterable
              default-first-option
              placeholder="Assign application to the user invited"
              style="width:100%"
            >
              <el-option
                v-for="item in apps"
                :key="item.appCode"
                :label="`${item.label}`"
                :value="item.appCode">
              </el-option>
            </el-select>
            <p class="form-help-text"><strong>This user will be able to access selected applications.</strong></p>
          </el-form-item>
          <div class="list is-gapless">
            <div class="list-content">
            </div>
            <div class="list-action">
              <el-button
                native-type="submit"
                :loading="isLoading"
                type="success">
                Invite
              </el-button>
            </div>
          </div>
        </el-form>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { applicationsList, rolesList, usersInvite, applicationsEditInvite, usersOne } from '../factories/manage'

export default {
  name: 'UserInvitationForm',
  props: {
    email: {
      type: String
    },
    appCode: {
      type: String
    }
  },
  data () {
    return {
      isSuccess: false,
      isLoading: false,
      error: null,
      localForm: {
        roleCode: '',
        email: this.email,
        appCodes: []
      }
    }
  },
  computed: {
    ...mapState({
      roles: state => state.roles.list,
      apps: state => state.apps.list
    }),
    ...mapGetters([
      'appRoles',
      'codeToRole',
      'user'
    ]),
    rolesToUse () {
      return this.appCode ? this.appRoles : this.roles
    }
  },
  created () {
    rolesList()
    applicationsList()
    // we want to get availabel apps on current logged in user
    if (this.user.level <= 777) {
      usersOne({ uid: this.user.uid })
    }
  },
  methods: {
    handleClose () {
      this.$emit('close')
    },
    doSubmit (e) {
      e.preventDefault()
      this.isLoading = true
      this.isSuccess = false
      this.error = null
      this.$refs.localForm.validate((valid) => {
        if (valid) {
          Promise.resolve()
            .then(() => {
              /**
               * If appCode exits it's a direct invitation we gonna use diffrent endpoint
               */
              const role = this.rolesToUse.find(r => r.code === this.localForm.roleCode)
              if (this.appCode) {
                return applicationsEditInvite({
                  ...this.localForm,
                  clientCode: role.clientCode,
                  appCode: this.appCode
                })
              }
              return usersInvite({
                ...this.localForm,
                clientCode: role.clientCode
              })
            })
            .then(() => {
              this.isSuccess = true
            })
            .catch((err) => {
              this.isLoading = false
              if (err.code === 'email_exist') {
                this.error = {
                  message: 'Email already taken.',
                  suggestedActions: [
                    { message: 'Resend the invitation email' },
                    { message: 'View the associated user' }
                  ]
                }
                return
              }
              this.error = {
                message: err.message
              }
            })
        } else {
          this.isLoading = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';

</style>
