<template>
  <el-form :inline="true" label-position="top" :model="localForm"
    ref="localForm" status-icon>
    <el-form-item
        class="payment-from--name"
        label="Cardholder Name"
        prop="name"
        :rules="[
        { required: true, message: 'Cardholder Name is required', trigger: [ 'change', 'blur' ] }
      ]">
      <el-input
          name="paymentName"
          placeholder="Cardholder Name" v-model="localForm.name"
          autocomplete="cc-name"
        />
    </el-form-item>
    <el-form-item
      :error="errorMessage"
      style="width: 100%">
      <div id="card-element" ref="card"></div>
    </el-form-item>
    <el-button
      :loading="isFetching"
      :disabled="isFetching"
      type="success"
      size="small"
      @click="submit()"
    >SAVE</el-button>
  </el-form>
</template>

<script>
/**
 * Setup custom font
 */
const fonts = [{
  cssSrc: 'https://use.typekit.net/atr1ocu.css'
}]

/**
 * Style, recomanded to use style instead of class coz it's served from IFrame
 */
const style = {
  base: {
    fontFamily: '"myriad-pro",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei",Arial,sans-serif',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '40px',
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: '#c0c4cc'
    }
  }
}

const classes = {
  base: 'payment-base',
  invalid: 'payment-invalid',
  complete: 'payment-complete'
}

export default {
  name: 'StripeCreditCardForm',
  props: {
    stripePk: String,
    clientSecret: String
  },
  data () {
    return {
      stripe: undefined, // inject Stripe instance later
      elements: undefined, // Stripe elements
      card: undefined, // Strip cardb
      errorMessage: '', // Store strip error message
      localForm: {
        name: ''
      },
      isFetching: false
    }
  },
  mounted () {
    this.stripe = window.Stripe(this.stripePk, {
      betas: ['payment_intent_beta_3']
    })
    this.elements = this.stripe.elements({ fonts })
    this.card = this.elements.create('card', {
      hidePostalCode: true,
      style,
      classes
    })
    this.card.mount('#card-element')
    this.card.addEventListener('change', ({ error }) => {
      this.errorMessage = error ? error.message : ''
    })
  },
  methods: {
    validateForm () {
      return new Promise((resolve, reject) => {
        this.$refs.localForm.validate((valid, fields) => {
          resolve({ valid, fields })
        })
      })
    },
    async submit () {
      this.isFetching = true
      try {
        const { valid } = await this.validateForm()
        if (valid) {
          const { setupIntent, error } = await this.stripe.confirmCardSetup(
            this.clientSecret,
            {
              payment_method: {
                card: this.card,
                billing_details: { name: this.localForm.name }
              }
            }
          )
          if (error) {
            this.errorMessage = error.message
          } else {
            if (setupIntent.status === 'succeeded') {
              this.$emit('submitted', setupIntent.payment_method)
            }
          }
        }
        this.isFetching = false
      } catch (err) {
        this.isFetching = false
        this.errorMessage = err.message
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .payment-from--name {
    width: 100%;
  }
  @include mobile {
    .payment-from--name {
      width: 100%;
    }
  }
  .payment-base {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: $text;
    display: block;
    font-size: inherit;
    outline: none;
    padding: 0px 15px;
    width: 100%;
    height: 40px;
    line-height: 40px;
  }
  .payment-invalid {
    border-color: $danger;
  }
  .payment-complete {
    border-color: $success;
  }
  .payment-invalid + .payment-error-message {
    transform: translateY(0);
    opacity: 1;
  }
  .payment-error-message {
    transition: transform 100ms ease-out;
    transform: translateY(-10px);
    opacity: 0;
  }
</style>
