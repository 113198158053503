<template>
  <div :class="['user-label', suppendedClass]">
    <router-link
      :to="{ name: 'manageUsersDetails', params: { uid: uid }}">
      <div class="user-label--displayname">
        {{ displayName || '–' }}
        <el-tooltip
          v-if="inactive"
          :content="toolTipMessage" placement="top-start">
          <i class="el-icon-question" />
        </el-tooltip>
      </div>
    </router-link>
    <div class="user-label--email">{{ email }}</div>
  </div>
</template>

<script>
import checkPermission from '@/plugins/permission'
export default {
  name: 'UserLabel',
  props: {
    uid: {
      type: String,
      required: true
    },
    displayName: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    },
    inactive: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    suppendedClass () {
      return !this.newUser && this.inactive ? 'user-label--suppended' : ''
    },
    newUser () {
      return this.displayName === '' && this.inactive
    },
    toolTipMessage () {
      return this.newUser ? 'User has yet to accept invitation' : 'User has been suppended globally'
    }
  },
  methods: {
    checkPermission
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .user-label {

  }
  .user-label--displayname {
    line-height: 1.25;
    font-weight: 600;
    // .el-icon-question {
    //   color: $orange;
    // }
    .user-label--suppended & {
      text-decoration: line-through !important;
      opacity: 0.68;
      color: $grey;
      font-weight: 400;
      .el-icon-question {
        color: inherit;
      }
    }
  }
  .user-label--email {
    line-height: 1.25;
    font-size: 0.85em;
    opacity: 0.68;
  }
</style>
