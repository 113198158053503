<template>
  <PpModal v-model="open">
    <PpCard class="box has-border is-flex">
      <div class="role-form-content">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-position="top"
          status-icon
          class=""
          @submit.native.prevent>
          <div>
            <h3 v-if="role" class="is-size-4 has-text-black has-text-weight-bold">Edit Role</h3>
            <h3 v-else class="is-size-4 has-text-black has-text-weight-bold">Add New Role</h3>
          </div>
          <el-form-item
            prop="type"
            :rules="[
              { required: true, message: 'Role type is required', trigger: [ 'change', 'blur' ] }
            ]">
            <template slot="label">
              <span>
                Role Type
                <el-tooltip class="item" effect="light" placement="top">
                  <span slot="content" v-html="roleTypeHint"/>
                  <span><i class="el-icon-question" /></span>
                </el-tooltip>
              </span>
            </template>
            <el-select v-model="form.type" placeholder="Please select" style="width: 100% !important">
              <el-option
                v-for="item in roleTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <p class="form-help-text">This role will be <b>only</b> available for use, based on role type. see hints above for more info</p>
          </el-form-item>
          <el-form-item
            label="Role Name"
            prop="name">
            <el-input
              v-model="form.name" @blur="handleNameFieldBlur" />
          </el-form-item>
          <el-form-item
            label="Role Description"
            prop="description">
            <el-input
              type="textarea"
              autosize
              v-model="form.description" />
          </el-form-item>
          <div class="has-text-right">
            <el-button
              style="min-width: 115px;"
              @click="handleCancel">
              Cancel
            </el-button>
            <el-button
              type="primary"
              style="min-width: 125px;"
              native-type="button"
              @click="handleSubmit">
              Submit
            </el-button>
          </div>
        </el-form>
      </div>
    </PpCard>
  </PpModal>
</template>

<script>

import { mapGetters } from 'vuex'
import { union } from 'lodash'

export default {
  name: 'RoleEditModal',
  props: {
    open: {
      type: Boolean,
      default: false
    },
    role: {
      type: Object
    },
    roles: {
      type: Array
    }
  },
  data () {
    return {
      form: {
        name: undefined,
        code: undefined,
        description: undefined,
        type: undefined
      },
      rules: {
        name: [
          {
            type: 'string',
            message: 'Please type your role name in A-Z,a-z.',
            trigger: [
              'change',
              'blur'
            ],
            pattern: /^[a-z]+(\s+[a-z]+)*$/ig
          },
          {
            required: true,
            message: 'Role name is required.',
            trigger: [
              'change', 'blur'
            ]
          }
        ]
      }
    }
  },
  watch: {
    role (nV) {
      if (nV) {
        this.form.name = nV.name
        this.form.code = nV.code
        this.form.type = nV.type
        this.form.description = nV.description
      } else {
        this.form.name = undefined
        this.form.code = undefined
        this.form.type = undefined
        this.form.description = undefined
      }
    }
  },
  computed: {
    ...mapGetters(['rolesTableData']),
    codes () {
      return union(
        this.roles.map((r) => r.code.toUpperCase()),
        this.rolesTableData.map((r) => r.code.toUpperCase())
      )
    },
    roleTypes () {
      const types = [
        {
          label: 'Internal',
          value: 'INTERNAL'
        },
        {
          label: 'Client',
          value: 'CLIENT'
        },
        {
          label: 'Application',
          value: 'APPLICATION'
        }
      ]
      // Internal role should only be available for Internal User
      if (this.mx_user.roleType !== 'INTERNAL') {
        types.splice(0, 1)
      }
      if (this.mx_user.roleType === 'APPLICATION') {
        types.splice(0, 1)
      }

      return types
    },
    roleTypeHint () {
      if (this.mx_user.roleType === 'INTERNAL') {
        return `
        <ul>
          <li><b>Internal</b>: Internal Role (RTX)</li>
          <li><b>Client</b>: Internal user of Company / Owner of the applications</li>
          <li><b>Application</b>: User Application level managed by Company</li>
        </ul>
      `
      }
      return `
        <ul>
          <li>Client: Company / Owner of the applications</li>
          <li><b>Application</b>: User Application level managed by Company</li>
        </ul>
      `
    }
  },
  methods: {
    handleSubmit (e) {
      e.preventDefault()
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.$emit('submit', { ...this.form })
          this.handleClose()
        }
      })
    },
    handleClose () {
      this.$refs.form.resetFields()
      this.$emit('update:open', false)
    },
    handleCancel () {
      this.$emit('update:open', false)
      this.handleClose()
      this.$emit('cancel')
    },
    handleNameFieldBlur () {
      if (this.form.name && /^[a-z]+(\s+[a-z]+)*$/ig.test(this.form.name)) {
        this.form.code = this.form.name.trim().replace(/\s+/g, '_').toUpperCase()
      }
    }
  }
}
</script>

<style lang="scss">
  .role-form-content {
    min-width: 480px;
  }
  .el-select-dropdown {
    &.el-popper {
      z-index: 9999 !important;
    }
  }
  .el-tooltip__popper.is-light {
    z-index: 9999 !important;
  }
</style>
