<template>
  <section class="section is-small">
    <!-- Content -->
    <div class="columns">
      <div class="column is-7 is-offset-2">
        <el-tabs tab-position="left">
          <el-tab-pane label="General" v-if="checkPermission(['GENERAL_INFO'])">
            <!-- Site FORM -->
            <div class="list">
              <div class="list-content">
                <div class="list-title has-text-primary has-text-weight-semibold">General Info</div>
              </div>
            </div>
            <article class="box">
              <div class="form-content">
                <el-form
                  ref="generalInfoForm"
                  label-position="top"
                  @submit.native.prevent=""
                  :model="localDetails.config">
                  <el-form-item
                    prop="brandName"
                    :rules="[
                    { required: true, message: 'This is required.', trigger: ['blur', 'change', 'input']}
                    ]">
                    <div class="is-inline-block" slot="label">
                      Brand name / Site name
                    </div>
                    <el-input
                      name="formAppBrandName"
                      size="medium"
                      autocomplete="off"
                      placeholder="How do you want your customer to know you?"
                      v-model="localDetails.config.brandName">
                    </el-input>
                    <p class="form-help-text">Display as booking site name too</p>
                  </el-form-item>
                  <el-form-item
                    prop="owner"
                    label="Owner/Company name"
                    :rules="[
                    { required: true, message: 'This is required.', trigger: ['blur', 'change', 'input']}
                    ]">
                    <div class="is-inline-block" slot="label">
                      Owner / Company name
                    </div>
                    <el-input
                      name="formAppOwner"
                      size="medium"
                      autocomplete="off"
                      placeholder="E.g: YourCompany Pte ltd"
                      v-model="localDetails.config.owner">
                    </el-input>
                    <p class="form-help-text">It will be shown in terms & privacy page.</p>
                  </el-form-item>
                  <el-form-item
                    prop="domainName"
                    label="Domain/Hostname"
                    :rules="[
                      { required: true, message: 'This is required.', trigger: ['blur', 'change', 'input']},
                      { type: 'string', pattern: /^https?:\/\//, message: 'Must start with https://', trigger: ['blur', 'input']},
                      { type: 'url', message: 'Must be valid hostname.', trigger: ['blur', 'change', 'input']}
                    ]">
                    <div class="is-inline-block" slot="label">
                      Domain Name
                    </div>
                    <el-input
                      name="formAppOwner"
                      autocomplete="off"
                      size="medium"
                      placeholder="E.g: https://mycompnay.com"
                      v-model="localDetails.config.domainName">
                    </el-input>
                    <p class="form-help-text">Domain name of your booking site must be https</p>
                  </el-form-item>
                  <div class="message is-small is-marginless is-primary">
                    <div class="message-body">
                      <p class="is-size-7 has-line-height-1-25 has-text-dark">
                        How will you like your customer to access your booking site?<br>E.g: https://travel.mycompany.com
                      </p>
                    </div>
                  </div>
                </el-form>
              </div>
              <div class="list is-comfort has-border-top">
                <div class="list-content">
                </div>
                <div class="list-action">
                  <el-button type="text"
                  :disabled="generalInfoFormNew"
                    @click="$refs.generalInfoForm.resetFields()">
                    RESET
                  </el-button>
                  &nbsp;
                  <el-button type="success"
                    size="medium"
                    :disabled="generalInfoFormNew"
                    @click="saveGeneralInfo({
                      domainName: localDetails.config.domainName,
                      brandName: localDetails.config.brandName,
                      owner: localDetails.config.owner
                    })">SAVE</el-button>
                </div>
              </div>
            </article>
          </el-tab-pane>
          <el-tab-pane label="Mail Setting" v-if="checkPermission(['MAIL_SETTING'])">
            <!-- MAIL FORM -->
            <div class="list">
              <div class="list-content">
                <div class="list-title has-text-primary has-text-weight-semibold">Mail Setting</div>
              </div>
            </div>
            <article class="box">
              <div class="form-content">
                <el-form
                  ref="mailForm"
                  label-position="top"
                  @submit.native.prevent=""
                  :model="localDetails.config.more.emails"
                  inline>
                  <el-form-item
                    prop="confirmationFromEmail"
                    style="width:100%">
                    <div class="is-inline-block" slot="label">
                      Mail Sender
                    </div>
                    <el-input
                      name="confirmationFromEmail"
                      size="medium"
                      autocomplete="off"
                      placeholder="john.doe@example.com"
                      v-model="localDetails.config.more.emails.confirmation.fromEmail">
                    </el-input>
                    <p class="form-help-text">This email will be used for default booking site mail sender</p>
                  </el-form-item>
                  <el-form-item
                    prop="contactSupportEmail"
                    style="width:100%">
                    <div class="is-inline-block" slot="label">
                      Customer Support Email
                    </div>
                    <el-input
                      name="contactSupportEmail"
                      size="medium"
                      autocomplete="off"
                      placeholder="john.doe@example.com"
                      v-model="localDetails.config.more.emails.contactSupportEmail">
                    </el-input>
                    <p class="form-help-text">This email will be displayed on voucher and booking email</p>
                  </el-form-item>
                  <el-form-item style="width: 100%" label="Accepted Domains" class="border">
                    <el-card shadow="never">
                      <el-tag
                        v-for="domain in acceptedDomains"
                        :key="domain"
                        closable
                        :disable-transitions="true"
                        @close="handleCloseDomain">
                        {{ domain }}
                      </el-tag>
                      <el-input
                        class="input-new-domain"
                        v-if="inputDomainVisible"
                        v-model="acceptedDomain"
                        ref="saveDomainInput"
                        size="small"
                        @keyup.enter.native="handleInputDomain"
                        @blur="handleInputDomain">
                      </el-input>
                      <el-button v-else class="button-new-domain" size="small" @click="showInputDomain">+ New Domain</el-button>
                    </el-card>
                    <p class="form-help-text">Customer only able to login within this domain</p>
                  </el-form-item>
                </el-form>
              </div>
              <div class="list is-comfort has-border-top">
                <div class="list-content">
                </div>
                <div class="list-action">
                  <el-button type="text"
                    :disabled="mailInfoFormNew"
                    @click="resetMailForm()">
                    RESET
                  </el-button>
                  &nbsp;
                  <el-button type="success"
                    size="medium"
                    :disabled="mailInfoFormNew"
                    @click="saveMailConfig({
                      more: {
                        emails: {
                          ...localDetails.config.more.emails
                        }
                      }
                    })">SAVE</el-button>
                </div>
              </div>
            </article>
          </el-tab-pane>
          <el-tab-pane label="Fax Setting" v-if="hasFeatures(['ENABLE_FAX']) && checkPermission(['FAX_SETTING']) && localDetails.config.more.faxSettings">
            <!-- FAX FORM -->
            <div class="list">
              <div class="list-content">
                <div class="list-title has-text-primary has-text-weight-semibold">Fax Setting</div>
              </div>
            </div>
            <article class="box">
              <div class="form-content">
                <el-form
                  ref="faxForm"
                  label-position="top"
                  @submit.native.prevent=""
                  :model="localDetails.config.more.faxSettings"
                  inline>
                  <el-form-item
                    :rules="[
                      { required: true, message: 'Please input fax number', trigger: [ 'change', 'blur' ] }
                    ]"
                    prop="faxNumber"
                    style="width:100%">
                    <div class="is-inline-block" slot="label">
                      Fax Number
                    </div>
                    <el-input
                      name="faxNumber"
                      size="medium"
                      autocomplete="off"
                      placeholder="#1234567"
                      v-model="localDetails.config.more.faxSettings.faxNumber">
                    </el-input>
                    <p class="form-help-text">Fax Number as a Sender.</p>
                  </el-form-item>
                  <el-form-item
                    prop="replyAddress"
                    style="width:100%">
                    <div class="is-inline-block" slot="label">
                      Reply Address
                    </div>
                    <el-input
                      name="replyAddress"
                      size="medium"
                      autocomplete="off"
                      placeholder="john.doe@gmail.com"
                      v-model="localDetails.config.more.faxSettings.replyAddress">
                    </el-input>
                    <p class="form-help-text">E-mail address to which feedback messages will be sent.</p>
                  </el-form-item>
                  <el-form-item
                    prop="iata"
                    style="width:100%">
                    <div class="is-inline-block" slot="label">
                      IATA No.
                    </div>
                    <el-input
                      name="iata"
                      size="medium"
                      autocomplete="off"
                      placeholder="#1234567"
                      v-model="localDetails.config.more.faxSettings.iata">
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    prop="roomAndTaxRemarks"
                    style="width:100%">
                    <div class="is-inline-block" slot="label">
                      Room & Tax Remarks
                    </div>
                    <el-input
                      type="textarea"
                      name="roomAndTaxRemarks"
                      size="medium"
                      autocomplete="off"
                      placeholder="Guest(s) should not be asked for a credit card at the time of check-in."
                      v-model="localDetails.config.more.faxSettings.roomAndTaxRemarks">
                    </el-input>
                    <p class="form-help-text">This will be displayed as a verbiage in a fax content.</p>
                  </el-form-item>
                </el-form>
              </div>
              <div class="list is-comfort has-border-top">
                <div class="list-content">
                </div>
                <div class="list-action">
                  <el-button type="text"
                    :disabled="faxFormNew"
                    @click="$refs.faxForm.resetFields()">
                    RESET
                  </el-button>
                  &nbsp;
                  <el-button type="success"
                    size="medium"
                    :disabled="faxFormNew"
                    @click="saveFaxConfig({
                      more: {
                        faxSettings: {
                          ...localDetails.config.more.faxSettings
                        }
                      }
                    })">SAVE</el-button>
                </div>
              </div>
            </article>
          </el-tab-pane>
          <el-tab-pane label="Marketplace" v-if="checkPermission(['MARKETPLACE_SETTING'])">
            <div class="list">
              <div class="list-content">
                <el-alert
                  title="These configurations will be saved automatically"
                  :closable="false"
                  type="warning"
                  show-icon>
                </el-alert>
              </div>
            </div>
            <div class="box is-stick">
              <div class="form-content">
                <div class="list is-narrow is-gapless">
                  <div class="list-content">
                    <div class="list-title has-text-primary has-text-weight-semibold">
                      Source Market
                    </div>
                  </div>
                  <div class="list-action">
                  </div>
                </div>
                <el-form
                  ref="currencyForm"
                  label-position="top"
                  @submit.native.prevent=""
                  :model="localDetails.config.more">
                  <el-form-item
                    label="Target country"
                    prop="targetCountry">
                      <el-select
                        filterable
                        size="medium"
                        autocomplete="no"
                        placeholder="Select country"
                        v-model="localDetails.config.more.targetCountry"
                        name="defaultSourceMarket"
                        @change="saveCurrencyForm({ more: { targetCountry: localDetails.config.more.targetCountry } }, 'targetCountry')"
                        >
                        <el-option v-for="it in countries" :key="it.a3" :value="it.a2" :label="it.lb" />
                      </el-select>
                    <p class="form-help-text">This help us to pre-fill country at checkout form when customer's location isn't available.</p>
                  </el-form-item>
                  <el-form-item
                    prop="defaultSourceMarket"
                    label="Default source market">
                      <el-select
                        filterable
                        size="medium"
                        autocomplete="no"
                        placeholder="Select country"
                        @change="saveCurrencyForm({ more: { defaultSourceMarket: localDetails.config.more.defaultSourceMarket } }, 'defaultSourceMarket')"
                        v-model="localDetails.config.more.defaultSourceMarket"
                        name="defaultSourceMarket"
                        >
                        <el-option v-for="it in countries" :key="it.a3" :value="it.a2" :label="it.lb" />
                      </el-select>
                    <p class="form-help-text has-text-dark has-text-weight-semibold">We will use this value if we failed to resolve country from your customer's location</p>
                  </el-form-item>
                  <div class="list is-gapless is-narrow"></div>
                  <div class="list is-gapless has-border-bottom is-narrow"></div>
                  <div class="list is-gapless is-narrow"></div>
                  <div class="list is-narrow is-gapless">
                    <div class="list-content">
                      <div class="list-title has-text-primary has-text-weight-semibold">
                        Displayed Currencies
                      </div>
                    </div>
                    <div class="list-action">
                    </div>
                  </div>
                  <el-form-item
                    prop="supportedCurrencies"
                    :rules="[
                      { type: 'array', required: true, message: 'Please select at least one currency', trigger: 'change' }
                    ]"
                    label="Supported display currencies">
                    <div class="is-inline-block" slot="label">
                      Supported display currencies
                    </div>
                    <el-select
                      v-model="localDetails.config.more.supportedCurrencies"
                      value-key="abbreviation"
                      multiple
                      filterable
                      default-first-option
                      placeholder="Choose currency want to display"
                      @change="doSelectCurrency()"
                      style="width:100%"
                    >
                      <el-option
                        v-for="item in currencies"
                        :key="item.abbreviation"
                        :label="`(${item.abbreviation}) ${item.currency}`"
                        :value="item">
                      </el-option>
                    </el-select>
                    <p class="form-help-text">Supported currencies are available at booking site as dropdown selection</p>
                  </el-form-item>
                  <el-form-item
                    label="Default display currency"
                    prop="defaultDisplayCurrency">
                    <el-select
                      size="medium"
                      style="width: 60%"
                      filterable
                      @change="saveCurrencyForm({ more: { defaultDisplayCurrency: localDetails.config.more.defaultDisplayCurrency } }, 'defaultDisplayCurrency')"
                      v-model="localDetails.config.more.defaultDisplayCurrency">
                      <el-option
                        v-for="item in localDetails.config.more.supportedCurrencies"
                        :key="item.abbreviation"
                        :label="`(${item.abbreviation}) ${item.currency}`"
                        :value="item.abbreviation"
                      ></el-option>
                    </el-select>
                    <p class="form-help-text">(Must be one of the supported currencies)</p>
                  </el-form-item>
                  <div class="list is-gapless is-narrow"></div>
                  <div class="list is-gapless has-border-bottom is-narrow"></div>
                  <div class="list is-gapless is-narrow"></div>
                  <div class="list is-narrow is-gapless">
                    <div class="list-content">
                      <div class="list-title has-text-primary has-text-weight-semibold">
                        Payment Currencies
                      </div>
                      <br>
                      <el-alert
                        :closable="false"
                        title="The payment currency is always related to how the payment gateway setup"
                        type="warning">
                      </el-alert>
                    </div>
                    <div class="list-action">
                    </div>
                  </div>
                  <el-form-item
                    style="width: 90%;"
                    label="Allow Multiple Payment Currency"
                    prop="enableMultiplePaymentCurrency"
                  >
                    <BlockFlag
                      style="margin-left:5px"
                      :boo="localDetails.config.more.enableMultiplePaymentCurrency"
                      falseText="DISALLOW"
                    />&nbsp;
                    <el-switch
                      v-model="localDetails.config.more.enableMultiplePaymentCurrency"
                      active-color="#15b77c"
                      inactive-color="#909399"
                      @change="doEnableMultiplePaymentCurrency()"
                    />
                    <p class="form-help-text">Allow user to select currency they wish to pay with at checkout page.</p>
                  </el-form-item>
                  <el-form-item
                    v-show="localDetails.config.more.enableMultiplePaymentCurrency"
                    prop="supportedPaymentCurrencies"
                    :rules="[
                      { type: 'array', required: true, message: 'Please select at least one currency', trigger: 'change' }
                    ]"
                    label="Supported payment currencies">
                    <div class="is-inline-block" slot="label">
                      Supported payment currencies
                    </div>
                    <el-select
                      v-model="localDetails.config.more.supportedPaymentCurrencies"
                      value-key="abbreviation"
                      multiple
                      filterable
                      default-first-option
                      placeholder="Choose currency want to enable on multiple payment currency"
                      @change="doSelectPaymentCurrency()"
                      style="width:100%"
                    >
                      <el-option
                        v-for="item in currencies"
                        :key="item.abbreviation"
                        :label="`(${item.abbreviation}) ${item.currency}`"
                        :value="item">
                      </el-option>
                    </el-select>
                    <p class="form-help-text">Supported payment currencies are available at checkout page as dropdown selection</p>
                  </el-form-item>
                  <el-form-item
                    v-show="!localDetails.config.more.enableMultiplePaymentCurrency"
                    label="Default payment currency"
                    prop="defaultPaymentCurrency">
                    <el-select
                      size="medium"
                      style="width: 60%"
                      filterable
                      @change="saveCurrencyForm({ more: { defaultPaymentCurrency: localDetails.config.more.defaultPaymentCurrency } }, 'defaultPaymentCurrency')"
                      v-model="localDetails.config.more.defaultPaymentCurrency">
                      <el-option
                        v-for="item in localDetails.config.more.supportedCurrencies"
                        :key="item.abbreviation"
                        :label="`(${item.abbreviation}) ${item.currency}`"
                        :value="item.abbreviation"
                      ></el-option>
                    </el-select>
                    <p class="form-help-text">(Must be one of the supported currencies)</p>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Logos" v-if="checkPermission(['SITE_LOGO'])">
            <!-- Site Logo, Mail Logo, Site Logo (Mobile Version) -->
            <div class="list">
              <div class="list-content">
                <el-alert
                  title="These configurations will be automatically saved. Only JPG / PNG files can be uploaded, and no more than 500kb for logo."
                  :closable="false"
                  type="warning"
                  show-icon>
                </el-alert>
              </div>
            </div>
            <article class="box">
              <div class="form-content">
                <el-form
                  ref="imagesUploadForm"
                  label-position="top"
                  @submit.native.prevent=""
                  :model="localDetails.config.more">
                  <el-form-item label="Site logo">
                    <LogoUpload name="brand" :value="localDetails.config.more.images.brand" @change="(logoObj)=>submitSimpleForm({ more: { images:{...localDetails.config.more.images, brand:logoObj } }})" />
                  </el-form-item>
                  <el-form-item label="Site Logo (Mobile Version)">
                    <LogoUpload name="brandMobile" :value="localDetails.config.more.images.brandMobile" @change="(logoObj)=>submitSimpleForm({ more: { images:{ ...localDetails.config.more.images,brandMobile:logoObj } }})" />
                  </el-form-item>
                  <el-form-item label="Mail Logo">
                    <LogoUpload name="mail" :value="localDetails.config.more.images.mail" @change="(logoObj)=>submitSimpleForm({ more: { images:{ ...localDetails.config.more.images,mail:logoObj } }})" />
                  </el-form-item>
                  <el-form-item label="Home page hero background">
                    <LogoUpload name="homeBackground" :size="5000" :value="homeBackground" @change="handleBackgroundChanged" />
                  </el-form-item>

                </el-form>
              </div>
            </article>
          </el-tab-pane>
          <el-tab-pane label="Preferred Hotels Setting" v-if="hasFeatures(['ENABLE_PREFERRED_FILTER_HOTELS']) && checkPermission(['PREFERRED_HOTELS_SETTING'])">
            <!-- Preferred Hotel List Setting -->
            <div class="list">
              <div class="list-content">
                <el-alert
                  :closable="false"
                  type="info"
                  effect="dark"
                  show-icon>
                  <template slot="title">
                    <span>These configurations will be used for <strong>Filter for Preferred Hotels</strong> feature.
                    </span>
                  </template>
                </el-alert>
              </div>
            </div>
            <article class="box">
              <div class="form-content">
                <div v-for="(group, index) in preferredHotels" :key="index">
                  <el-card shadow="never" style="margin-bottom: 10px">
                    <PreferredHotelForm
                      ref="preferredHotelForm"
                      :key="index"
                      v-model="preferredHotels[index]"
                      style="margin-bottom: 10px"/>
                    <el-button type="danger" size="small" style="float:right;margin-bottom:10px" @click="handleDeleteGroup(index)">Delete</el-button>
                  </el-card>
                </div>
                <div class="list-action">
                  <el-button type="text" @click="handleResetPreferredHotel()" size="small">RESET</el-button>&nbsp;
                  <el-button
                    type="success"
                    size="small"
                    @click="submitSimpleForm({
                      client: {
                        preferredHotels
                      }
                    })"
                  >SAVE</el-button>&nbsp;
                  <el-button type="primary" @click="handleAddPreferredHotel()" size="small">ADD GROUP</el-button>
                </div>
              </div>
            </article>
          </el-tab-pane>
          <el-tab-pane label="Checkout Setting" v-if="checkPermission(['CHECKOUT_SETTING'])">
            <!-- Preferred Hotel List Setting -->
            <div class="list">
              <div class="list-content">
                <el-alert
                  :closable="false"
                  type="info"
                  effect="dark"
                  show-icon>
                  <template slot="title">
                    <span>These configurations will be used to manage checkout page fields setting</span>
                  </template>
                </el-alert>
              </div>
            </div>
            <article class="box">
              <div class="form-content">
                <el-form
                  ref="checkoutForm"
                  label-position="top"
                  @submit.native.prevent=""
                  :model="localDetails.config.client.checkoutPage"
                  inline>
                  <div class="list is-narrow is-gapless">
                    <div class="list-content">
                      <div class="list-title has-text-primary has-text-weight-semibold">
                        GL Code
                      </div>
                    </div>
                  </div>
                  <el-form-item
                    style="width: 90%;"
                    label="Is this mandatory field?"
                    prop="glCodeMandatory"
                  >
                    <BlockFlag
                      style="margin-left:5px"
                      :boo="localDetails.config.client.checkoutPage.glCodeMandatory"
                      falseText="NO"
                      trueText="YES"
                    />&nbsp;
                    <el-switch
                      v-model="localDetails.config.client.checkoutPage.glCodeMandatory"
                      active-color="#15b77c"
                      inactive-color="#909399"
                    />
                  </el-form-item>
                  <el-form-item
                    prop="glCodeFieldName"
                    style="width:100%">
                    <div class="is-inline-block" slot="label">
                      Field Name
                    </div>
                    <el-input
                      name="glCodeFieldName"
                      size="medium"
                      autocomplete="off"
                      placeholder="eg: GL Code, Employee ID"
                      v-model="localDetails.config.client.checkoutPage.glCodeFieldName">
                    </el-input>
                    <p class="form-help-text">
                      Default: GL Code/Department Code
                    </p>
                  </el-form-item>

                  <div class="message is-small is-primary is-marginless">
                    <div class="message-body">
                      <p class="is-size-7 has-line-height-1-25 has-text-weight-semibold">
                        <i class="el-icon-warning has-text-primary" /> Preview:
                        <br />
                        <img src="@/assets/glcode-example.png" />
                      </p>
                    </div>
                  </div>

                  <div class="list is-gapless is-narrow"></div>
                  <div class="list is-gapless has-border-bottom is-narrow"></div>
                  <div class="list is-gapless is-narrow"></div>

                  <div class="list is-narrow is-gapless">
                    <div class="list-content">
                      <div class="list-title has-text-primary has-text-weight-semibold">
                        Reason for selection
                      </div>
                    </div>
                  </div>
                  <el-form-item
                    style="width: 90%;"
                    label="Display this field on checkout form?"
                    prop="displayReasonField"
                  >
                    <BlockFlag
                      style="margin-left:5px"
                      :boo="localDetails.config.client.checkoutPage.displayReasonField"
                      falseText="NO"
                      trueText="YES"
                    />&nbsp;
                    <el-switch
                      v-model="localDetails.config.client.checkoutPage.displayReasonField"
                      active-color="#15b77c"
                      inactive-color="#909399"
                    />
                  </el-form-item>
                  <el-form-item
                    style="width: 90%;"
                    label="Is this mandatory field?"
                    prop="reasonFieldMandatory"
                  >
                    <BlockFlag
                      style="margin-left:5px"
                      :boo="localDetails.config.client.checkoutPage.reasonFieldMandatory"
                      falseText="NO"
                      trueText="YES"
                    />&nbsp;
                    <el-switch
                      v-model="localDetails.config.client.checkoutPage.reasonFieldMandatory"
                      active-color="#15b77c"
                      inactive-color="#909399"
                    />
                  </el-form-item>
                  <el-form-item
                    prop="reasonFieldName"
                    style="width:100%">
                    <div class="is-inline-block" slot="label">
                      Field Name
                    </div>
                    <el-input
                      name="reasonFieldName"
                      size="medium"
                      autocomplete="off"
                      placeholder="eg: GL Code, Employee ID"
                      v-model="localDetails.config.client.checkoutPage.reasonFieldName">
                    </el-input>
                    <p class="form-help-text">
                      Default: Was this the first available hotel in listing order? If "No", please explain the reason for your selection
                    </p>
                  </el-form-item>
                  <div class="message is-small is-primary is-marginless">
                    <div class="message-body">
                      <p class="is-size-7 has-line-height-1-25 has-text-weight-semibold">
                        <i class="el-icon-warning has-text-primary" /> Preview:
                        <br />
                        <img src="@/assets/reason-field-example.png" />
                      </p>
                    </div>
                  </div>
                </el-form>
              </div>
              <div class="list is-comfort has-border-top">
                <div class="list-content">
                </div>
                <div class="list-action">
                  <el-button type="success"
                    size="medium"
                    @click="submitSimpleForm({
                      client: {
                        ...localDetails.config.client,
                        ...localDetails.config.client.checkoutPage
                      }
                    })">SAVE</el-button>
                </div>
              </div>
            </article>
          </el-tab-pane>
          <el-tab-pane label="Autosuggest Settings" v-if="checkPermission(['AUTOSUGGEST_SETTING'])">
            <div class="list">
              <div class="list-content">
                <el-alert
                  title="These configurations will be saved automatically"
                  :closable="false"
                  type="warning"
                  show-icon>
                </el-alert>
              </div>
            </div>
            <div class="box is-stick">
              <div class="form-content">
                <el-form
                  ref="autosuggestForm"
                  label-position="top"
                  @submit.native.prevent=""
                  :model="localDetails.config.client.autosuggest">
                  <el-form-item
                    label="Sort By"
                    prop="sortBy">
                      <el-select
                        filterable
                        size="medium"
                        autocomplete="no"
                        placeholder="Select country"
                        v-model="localDetails.config.client.autosuggest.sortBy"
                        name="sortBy"
                        @change="submitSimpleForm({
                          client: {
                            ...localDetails.config.client,
                            ...localDetails.config.client.autosuggest
                          }
                        })"
                        >
                        <el-option value="airport" label="Airport" />
                        <el-option value="city" label="City" />
                        <el-option value="hotel" label="Hotel" />
                      </el-select>
                    <p class="form-help-text">This help us to pre-fill country at checkout form when customer's location isn't available.</p>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <PpGap size="large" />
  </section>
</template>

<script>
import PreferredHotelForm from '@/components/PreferredHotelForm'
import currencies from '../../plugins/currencies'
import countries from '../../plugins/countries'
import LogoUpload from '../../components/LogoUpload'
import checkPermission from '@/plugins/permission'
import hasFeatures from '@/plugins/feature'

export default {
  name: 'ManageApplicationsDetailsPreferences',
  components: {
    LogoUpload,
    PreferredHotelForm
  },
  props: {
    appCode: {
      type: String,
      required: true
    },
    query: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      debounceTimer: undefined,
      localDetails: {},
      loadingNewApp: false,
      currencies,
      countries,
      acceptedDomain: '',
      acceptedDomains: [],
      inputDomainVisible: false,
      preferredHotels: []
    }
  },
  computed: {
    details () {
      return this.$parent.details
    },
    /**
     * Check if general info form is edited
     */
    generalInfoFormNew () {
      return this.localDetails.config.domainName === this.details.config.domainName && this.localDetails.config.owner === this.details.config.owner &&
        this.localDetails.config.brandName === this.details.config.brandName
    },
    /**
     * Check if mail config form is edited
     */
    mailInfoFormNew () {
      return this.localDetails.config.more.emails.confirmation.fromEmail === this.details.config.more.emails.confirmation.fromEmail &&
        this.localDetails.config.more.emails.contactSupportEmail === this.details.config.more.emails.contactSupportEmail &&
        this.acceptedDomains.length === this.localDetails.config.more.emails.acceptedDomains.length
    },
    faxFormNew () {
      return this.localDetails.config.more.faxSettings.iata === this.details.config.more.faxSettings.iata &&
        this.localDetails.config.more.faxSettings.roomAndTaxRemarks === this.details.config.more.faxSettings.roomAndTaxRemarks &&
        this.localDetails.config.more.faxSettings.faxNumber === this.details.config.more.faxSettings.faxNumber &&
        this.localDetails.config.more.faxSettings.replyAddress === this.details.config.more.faxSettings.replyAddress
    },
    homeBackground () {
      return this.localDetails.config.more.images.collections.find((item) => item.id === 'HOME_HERO_BACKGROUND') || { id: '', url: '', width: '' }
    }
  },
  watch: {
    'details': {
      immediate: true,
      handler (nV) {
        this.localDetails = JSON.parse(JSON.stringify(nV))
        this.localDetails.config.more = { ...this.localDetails.config.more }
        // no acceptedDomains in existing data, define it
        if (this.localDetails.config.more.emails.acceptedDomains === undefined) this.localDetails.config.more.emails.acceptedDomains = []
        this.acceptedDomains = [...[], ...this.localDetails.config.more.emails.acceptedDomains]
        if (this.localDetails.config.client.preferredHotels === undefined) this.localDetails.config.client.preferredHotels = []
        this.preferredHotels = [...[], ...this.localDetails.config.client.preferredHotels]
        if (this.localDetails.config.client.checkoutPage === undefined) {
          this.localDetails.config.client = {
            ...this.localDetails.config.client,
            checkoutPage: {}
          }
        }
      }
    }
  },
  methods: {
    checkPermission,
    hasFeatures,
    /**
     * @onsubmit General Info Form
     */
    saveGeneralInfo (val) {
      const body = {
        ...val,
        appCode: this.appCode
      }
      this.$refs.generalInfoForm.validate((valid) => {
        if (valid) {
          this.$parent.postApplicationEditConfigurations(body)
            .then(() => this.$refs.generalInfoForm.clearValidate())
        }
      })
    },
    /**
     * @onsubmit Mail Config Form
     */
    saveMailConfig (val) {
      val.more.emails.acceptedDomains = this.acceptedDomains
      const body = {
        ...val,
        appCode: this.appCode
      }
      this.$refs.mailForm.validate((valid) => {
        if (valid) {
          this.$parent.postApplicationEditConfigurations(body)
            .then(() => this.$refs.mailForm.clearValidate())
        }
      })
    },
    saveFaxConfig (val) {
      const body = {
        ...val,
        appCode: this.appCode
      }
      this.$refs.faxForm.validate((valid) => {
        if (valid) {
          this.$parent.postApplicationEditConfigurations(body)
            .then(() => this.$refs.faxForm.clearValidate())
        }
      })
    },
    /**
     * @onsubmit Currency Form
     */
    saveCurrencyForm (val, field) {
      const body = {
        ...val,
        appCode: this.appCode
      }
      this.$refs.currencyForm.validateField(field, (error) => {
        if (!error) {
          if (body.more && body.more.supportedCurrencies) {
            body.more.supportedCurrencies = body.more.supportedCurrencies.filter(e => e)
          } else if (body.more && body.more.supportedPaymentCurrencies) {
            body.more.supportedPaymentCurrencies = body.more.supportedPaymentCurrencies.filter(e => e)
          }
          this.$parent.postApplicationEditConfigurations(body)
            .then(() => this.$refs.currencyForm.clearValidate())
        }
      })
    },
    /**
     * @onsubmit Mail Config Form
     */
    saveOtherSettingForm (val) {
      const body = {
        ...val,
        appCode: this.appCode
      }
      this.$refs.otherSettingForm.validate((valid) => {
        if (valid) {
          this.$parent.postApplicationEditConfigurations(body)
            .then(() => this.$refs.otherSettingForm.clearValidate())
        }
      })
    },
    /**
     * @onchange background upload
     */
    handleBackgroundChanged (bgObj) {
      let collections = JSON.parse(JSON.stringify(this.localDetails.config.more.images.collections))
      let findObj = collections.find((item) => item.id === 'HOME_HERO_BACKGROUND')
      if (findObj) {
        findObj.url = bgObj.url
      } else {
        collections.push({
          id: 'HOME_HERO_BACKGROUND',
          url: bgObj.url,
          width: ''
        })
      }
      const body = {
        more: { images: { ...this.localDetails.config.more.images, collections } },
        appCode: this.appCode
      }
      this.$parent.postApplicationEditConfigurations(body)
    },
    /**
     * @onchange Supported Currency Selection
     */
    doSelectCurrency () {
      this.saveCurrencyForm({ more: { supportedCurrencies: this.localDetails.config.more.supportedCurrencies } }, 'supportedCurrencies')
    },
    doSelectPaymentCurrency () {
      this.saveCurrencyForm({ more: { supportedPaymentCurrencies: this.localDetails.config.more.supportedPaymentCurrencies } }, 'supportedPaymentCurrencies')
    },
    doEnableMultiplePaymentCurrency () {
      this.saveCurrencyForm({ more: { enableMultiplePaymentCurrency: this.localDetails.config.more.enableMultiplePaymentCurrency } }, 'enableMultiplePaymentCurrency')
    },
    /**
     * @onreset Mail Form
     * Reseting using el-form is not doable because its nested object. instead we set those fields back manually
     */
    resetMailForm () {
      this.localDetails.config.more.emails.confirmation.fromEmail = this.details.config.more.emails.confirmation.fromEmail
      this.localDetails.config.more.emails.contactSupportEmail = this.details.config.more.emails.contactSupportEmail
      this.localDetails.config.more.emails.acceptedDomains = this.details.config.more.emails.acceptedDomains
    },
    /** Accepted Domains CTAs */
    handleCloseDomain (domain) {
      this.acceptedDomains.splice(this.acceptedDomains.indexOf(domain), 1)
    },
    showInputDomain () {
      this.inputDomainVisible = true
      this.$nextTick(_ => {
        this.$refs.saveDomainInput.$refs.input.focus()
      })
    },
    handleInputDomain () {
      let inputValue = this.acceptedDomain
      if (inputValue) {
        this.acceptedDomains.push(inputValue)
      }
      this.inputDomainVisible = false
      this.acceptedDomain = ''
    },
    handleAddPreferredHotel () {
      this.preferredHotels.push({
        name: '',
        keywords: []
      })
    },
    handleDeleteGroup (index) {
      this.preferredHotels.splice(index, 1)
    },
    handleResetPreferredHotel () {
      this.preferredHotels = [...[], ...this.localDetails.config.client.preferredHotels]
    },
    submitSimpleForm (val) {
      const body = {
        ...val,
        appCode: this.appCode
      }
      this.$parent
        .postApplicationEditConfigurations(body)
    }
  }
}
</script>

<style lang="scss">
  @import '../../styles/bulma-variables';
  .autouser .el-select-dropdown__item {
    padding: $bleed / 2 $bleed;
    height: auto;
  }
  .nested--table {
    margin: $bleed * 2;
    border: 1px solid $grey-lighter;
  }
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-domain {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-domain {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
</style>
