<template>
  <section class="section is-small">
    <div class="">
      <div class="list is-gapless">
        <div class="list-content">
          <h4 class="title is-4">Customers Management</h4>
        </div>
        <div class="list-action">
          <el-button
            v-if="checkPermission(['EDIT_CUSTOMERS'])"
            type="success"
            icon="el-icon-plus"
            size="small"
            @click.prevent="showInviteForm()">
            INVITE NEW CUSTOMER
          </el-button>
        </div>
      </div>
      <PpGap/>
      <article class="box">
        <div class="form-content">
          <el-form
            inline
            size="medium"
            label-position="top"
            ref="localForm"
            :model="localForm">
            <el-form-item
              prop="appCode"
              label="Application"
              name="appCode">
              <el-select
                style="min-width: 250px;"
                v-model="localForm.applicationCodes"
                multiple
                filterable
                collapse-tags
                value-key="appCode"
                @change="formChanged"
                placeholder="Any">
                <el-option
                  v-for="it in apps"
                  :key="it.appCode"
                  :label="it.label"
                  :value="it.appCode">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              prop="name"
              style="min-width: 260px;"
              label="Customer name"
              name="orderPerson"
              :rules="[{ type: 'string', min: 2, message: 'Type at least 2 characters to search.', trigger: [ 'change', 'input' ] }]">
              <el-input
                clearable
                v-model.lazy.trim="localForm.name"
                name="orderPerson"
                @input="formChanged"
                placeholder="E.g: James bond"></el-input>
              <p class="form-help-text">Customer's name</p>
            </el-form-item>
            <el-form-item
              v-if="isClearFormNeeded"
              style="width: 80px">
              <template slot="label">
                &nbsp;
              </template>
              <el-button
                size="medium"
                icon="el-icon-close"
                @click="doClearAllFields"
                type="danger">
                Clear All Filters
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </article>
      <div class="box" v-loading="isSearching">
        <el-table
          class="users--table"
          :data="rows"
          stripe
          header-cell-class-name="users--table-th"
          style="width: 100%">
          <el-table-column
            label="#"
            type="index"
            width="40"
            align="center">
          </el-table-column>
          <el-table-column
            label="User"
            width="250">
            <template slot-scope="scope">
              <div class="user-label--displayname">{{ scope.row.displayName || '–' }}</div>
              <div class="user-label--email">{{ scope.row.email }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="Application">
            <template slot-scope="scope">
              <el-tag
                class="users--tag"
                size="mini">
                {{ scope.row.app && scope.row.app.label }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="inactive"
            label="Status"
            align="center">
            <template slot-scope="scope">
              <InactiveFlag :inactive="scope.row.inactive"/>
            </template>
          </el-table-column>
          <el-table-column
            label="Joined at">
            <template slot-scope="scope">
              {{ scope.row.createdAt | date }}
            </template>
          </el-table-column>
          <el-table-column
            label="Actions"
            align="center"
            width="250"
          >
            <template slot-scope="{ row }">
              <div>
                <router-link
                  :to="{ name: 'manageCustomerDetails', params: { uid: row.uid } }">
                  <el-button
                    v-if="checkPermission(['EDIT_CUSTOMERS'])"
                    size="small"
                    type="primary"
                    style="margin-right: 10px"
                  >Edit
                  </el-button>
                </router-link>
                <el-button
                  v-if="row.inactive"
                  :loading="isSending"
                  size="small"
                  type="warning"
                  @click="() => resendInvitationEmail(row.email, row.app.appCode)"
                >Resend Invitation
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="list" v-if="summary">
          <div class="list-content"></div>
          <div class="list-action">
            <el-pagination
              @size-change="doChangeTableSize"
              @current-change="pageChanged"
              :current-page="localForm.page"
              :page-sizes="[10, 25, 60, 100, 300]"
              :page-size.sync="localForm.limit"
              layout="sizes, prev, pager, next"
              :total="summary.total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="Invite new customer" :visible.sync="inviteFormVisibility">
      <CustomerInvitationForm
        @close="closeInviteForm"
        v-if="inviteFormVisibility"
        v-bind="inviteForm" />
    </el-dialog>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import {
  customersList,
  customerSendInvitationEmail,
  applicationsList
} from '../../factories/manage'
import checkPermission from '@/plugins/permission'
import CustomerInvitationForm from '@/components/CustomerInvitationForm.vue'
import debounce from 'lodash/debounce'

const CUSTOMERS_TABLE_SIZE_KEY = 'lv2_customer_list_size'
const tableSize = {
  get () {
    let k
    try {
      k = Number(JSON.parse(localStorage.getItem(CUSTOMERS_TABLE_SIZE_KEY)))
    } catch (err) {
      console.error('No table size value found')
    }
    if (!k) {
      this.set(10)
      k = 10
    }
    return k
  },
  set (val) {
    localStorage.setItem(CUSTOMERS_TABLE_SIZE_KEY, JSON.stringify(val))
  }
}

export default {
  name: 'ManageUsers',
  components: {
    CustomerInvitationForm
  },
  props: {
    query: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isSending: false,
      inviteFormVisibility: false,
      inviteForm: {
        email: '',
        givenName: '',
        familyName: ''
      },
      localForm: {
        applicationCodes: [],
        name: '',
        limit: 10,
        page: 1
      }
    }
  },
  computed: {
    ...mapState({
      rows: state => state.customers.rows,
      count: state => state.customers.count,
      summary: state => state.customers.summary,
      apps: state => state.apps.list,
      isSearching: state => state.customers.isSearching
    }),
    isClearFormNeeded () {
      return this.localForm.name || this.localForm.applicationCodes.length > 0
    },
    formatLocalForm () {
      return {
        ...this.localForm,
        applicationCodes: this.localForm.applicationCodes.length ? this.localForm.applicationCodes.join(',') : undefined
      }
    }
  },
  created () {
    applicationsList()
    this.localForm.limit = tableSize.get()
    this.queryToForm()
    customersList(this.formatLocalForm)
  },
  watch: {
    $route (route) {
      if (route.name === 'manageCustomers') {
        this.queryToForm()
        customersList(this.formatLocalForm)
      }
    }
  },
  methods: {
    checkPermission,
    resendInvitationEmail (email, appCode) {
      this.$confirm('Are you sure want to resend invitation email?')
        .then(async () => {
          this.isSending = true
          await customerSendInvitationEmail({ email, appCode })
          this.isSending = false
        })
    },
    showInviteForm () {
      this.inviteFormVisibility = true
    },
    closeInviteForm () {
      this.inviteFormVisibility = false
      customersList()
    },
    queryToForm () {
      this.localForm = {
        ...this.localForm,
        applicationCodes: this.query.applicationCodes ? this.query.applicationCodes.split(',') : [],
        name: this.query.name ? this.query.name : undefined,
        page: this.query.page ? Number(this.query.page) : undefined
      }
    },
    search () {
      return this.$refs.localForm.validate()
        .then((valid) => {
          if (valid) {
            const sanitize = {
              ...(this.localForm.name ? { name: this.localForm.name } : undefined),
              ...(Array.isArray(this.localForm.applicationCodes) && this.localForm.applicationCodes.length > 0
                ? {
                  applicationCodes: this.localForm.applicationCodes.join(',')
                } : undefined),
              ...(this.localForm.limit ? { limit: this.localForm.limit } : undefined),
              ...(this.localForm.page ? { page: this.localForm.page } : undefined)
            }
            this.$router.replace({ query: sanitize })
          }
        })
    },
    debounceSearch: debounce(function () {
      this.search()
    }, 500),
    formChanged () {
      this.localForm.page = 1
      this.debounceSearch()
    },
    pageChanged (page) {
      this.localForm.page = page
      this.debounceSearch()
    },
    doClearAllFields () {
      this.localForm = {
        ...this.localForm,
        applicationCodes: [],
        name: undefined,
        page: 1
      }
      this.debounceSearch()
    },
    doChangeTableSize (limit) {
      this.localForm.page = 1 // <- reset to page 1
      tableSize.set(limit)
      this.debounceSearch()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/bulma-variables';
  .users--tag {
    margin: 0 $bleed;
  }
  .users--table /deep/ .is-hidden {
    display: table-cell !important;
  }
  .users--table /deep/ .has-text-ellipsis .cell {
    white-space: nowrap;
  }
  .users--table /deep/ .users--table-th {
    border-color: $grey-light;
    background-color: $white-bis;
  }
  .user-label--displayname {
    line-height: 1.25;
    font-weight: 600;
  }
  .user-label--email {
    line-height: 1.25;
    font-size: 0.85em;
    opacity: 0.68;
  }
</style>
