<template>
  <div v-loading="!iframeIsReady" style="min-height: 500px;">
    <div class="has-text-danger has-text-centered" v-if="callbackData&&callbackData.success===false && !isSubmitting">
      {{ callbackData.data.reason || 'Payment Failed' }}
    </div>
    <iframe
      name="pciframe"
      scrolling="no"
      frameborder="0"
      :src="apiUrl"
      :style="`width: 100%;height:${height}px`" v-show="iframeIsReady&&showIframe" @load="handleIframeLoaded"></iframe>
    <!-- <div class="has-text-danger" v-if="callbackData&&callbackData.success===false">PCI payment fail</div> -->
  </div>
</template>

<script>
const retry = function (fn, times, delay) {
  return new Promise(function (resolve, reject) {
    let attempt = function () {
      fn().then(resolve).catch(function (err) {
        if (times === 0) {
          reject(err)
        } else {
          times--
          setTimeout(function () {
            attempt()
          }, delay)
        }
      })
    }

    attempt()
  })
}

export default {
  name: 'PayAtHotelPayment',
  props: {
    paymentInfo: {
      type: Object,
      default: () => {},
      required: true
    },
    creatorReference: {
      type: String,
      default: () => '',
      required: true
    }
  },
  data () {
    return {
      iframeIsReady: false,
      height: 600,
      valid: false,
      receiver: undefined,
      callbackData: undefined,
      showIframe: true,
      isSubmitting: false
    }
  },
  computed: {
    host () {
      return this.paymentInfo && this.paymentInfo.apiUrl.replace('/api', '')
    },
    apiUrl () {
      // https://service.pcibooking.net/api/payments/capturecard?brand=zumatadevelopment_sandbox&language=EN
      const origin = location.protocol + '//' + window.location.host
      const successCallbackUrl = encodeURIComponent(origin + '/pci-success?cardToken={cardToken}&cardType={cardType}&cardNumber={cardNumber}&cvv={cvv}&expiration={expiration}&cardHolderName={cardHolderName}&sendMessage=true')
      const failCallbackUrl = encodeURIComponent(origin + '/pci-fail')
      const postMessageHost = encodeURIComponent(window.location.origin)
      const sessionToken = encodeURIComponent(this.paymentInfo.token)

      // return this.paymentInfo && `${this.paymentInfo.apiUrl}/payments/capturecard?sessionToken=${sessionToken}&ThreeDS=true&autoDetectCardType=true&cvv=true&brand=${this.paymentInfo.brand}&language=EN&failure=${failCallbackUrl}&submitWithPostMessage=false&autoFocus=false&success=${successCallbackUrl}&postMessageHost=${postMessageHost}&creatorReference=${this.creatorReference}`
      return this.paymentInfo && `${this.paymentInfo.apiUrl}/payments/capturecard?sessionToken=${sessionToken}&autoDetectCardType=true&cvv=true&brand=${this.paymentInfo.brand}&language=EN&failure=${failCallbackUrl}&submitWithPostMessage=false&autoFocus=false&success=${successCallbackUrl}&postMessageHost=${postMessageHost}&creatorReference=${this.creatorReference}`
    }
  },
  mounted () {
    window.successCallBack = (data) => {
      this.isSubmitting = false
      console.log('iframe success call back:', data)
      this.showIframe = false
      this.callbackData = {
        success: true,
        data: data
      }
      this.$emit('successCallback', data)
    }
    window.failCallBack = (data) => {
      this.isSubmitting = false
      console.log('iframe fail call back')
      // this.showIframe = false
      this.callbackData = {
        success: false,
        data: data
      }
      window.frames['pciframe'].location = this.apiUrl
      this.$emit('paymentFailed')
    }
    this.$nextTick(() => {
      if (window.addEventListener) {
        window.addEventListener('message', this.handleFrameMessage)
      } else if (window.attachEvent) {
        window.attachEvent('message', this.handleFrameMessage)
      }
    })
  },
  beforeDestroy () {
    window.removeEventListener('message', this.handleFrameMessage)
  },
  methods: {
    handleFrameMessage (e) {
      const originDomain = e.origin.replace('http://', '').replace('https://', '').toLowerCase()
      const postMessageOrigin = this.host.replace('http://', '').replace('https://', '').toLowerCase()
      if (originDomain === postMessageOrigin) {
        if (e.data === 'ready') {
          this.iframeIsReady = true
        } else if (e.data === 'valid') {
          this.valid = true
        } else if (typeof e.data === 'string' && e.data.indexOf('frameDimensionsChanged') === 0) {
          // let objectParts = e.data.split(':')
          this.height = 500
        } else {
          this.valid = false
        }
      }
    },
    handleIframeLoaded () {
      this.receiver = this.$el.querySelector('iframe')
    },
    handleValid () {
      this.receiver.contentWindow.postMessage('validate', this.host)
      return retry(() => {
        return new Promise((resolve, reject) => {
          if (this.valid) {
            resolve(true)
          } else {
            reject(new Error('Credit card provided is invalid'))
          }
        })
      }, 10, 300)
    },
    handlePayment () {
      this.isSubmitting = true
      this.callbackData = undefined
      this.receiver.contentWindow.postMessage('submit', this.host)
      return retry(() => {
        return new Promise((resolve, reject) => {
          if (this.callbackData && this.callbackData.success) {
            this.isSubmitting = false
            const { cardToken } = this.callbackData.data
            const arr = cardToken.split('/')
            this.callbackData.data.cardToken = arr[arr.length - 1]
            resolve(this.callbackData)
          } else {
            this.isSubmitting = false
            reject(new Error(this.callbackData.data.reason))
          }
        })
      }, 999, 300)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
</style>
