<template>
  <section class="section is-mini">
    <div class="is-paddingless">
      <el-button
        :loading="isLoadingForm"
        :disabled="isLoadingList"
        v-show="!creditCardFormVisible && checkPermission(['COMPANY_CREDIT_CARDS_EDIT'])"
        type="success"
        size="small"
        @click="addNewCard"
      >Add Card
      </el-button>
      <PCIBookingCreditCardForm
        v-if="creditCardFormVisible && pciBookingInfo && pciBookingInfo.apiUrl"
        ref="payAtHotelIframe"
        :paymentInfo="pciBookingInfo"
        :creatorReference="appAccessKey"
        @paymentFailed="isSubmiting = false"
        @successCallback="successCallback"
        class="el-form"/>
      <el-table
        v-loading="isLoadingList"
        v-if="!creditCardFormVisible"
        :data="creditCards"
        style="width: 100%">
        <el-table-column
          label="Card (Last 4)">
          <template slot-scope="scope">
            <span>{{ scope.row.LastDigits }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Brand">
          <template slot-scope="scope">
            <span>{{ scope.row.CardType }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Cardholder Name">
          <template slot-scope="scope">
            <span>{{ scope.row.OwnerName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="checkPermission(['COMPANY_CREDIT_CARDS_EDIT'])"
          label="Actions"
          width="120">
          <template slot-scope="scope">
            <el-button type="danger" size="mini" icon="el-icon-delete" circle
              @click="deleteCardConfirmationVisibility = true;selectedRow = scope.row"></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog
        title="Warning"
        :visible.sync="deleteCardConfirmationVisibility"
        width="30%">
        <p>Are you sure want to delete this card? You could not be longer use this card at the checkout page.</p>
        <span slot="footer" class="dialog-footer">
          <el-button :disabled="isLoading" @click="deleteCardConfirmationVisibility = false">Cancel</el-button>
          <el-button type="primary" :loading="isLoading" @click="removeCreditCard">Continue</el-button>
        </span>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import {
  pciBookingSession,
  pciBookingRemoveCard,
  pciBookingCards
} from '../../factories/paymentMethod'
import PCIBookingCreditCardForm from '@/components/PCIBookingCreditCardForm'
import checkPermission from '@/plugins/permission'

export default {
  name: 'PaymentMethodPCIBooking',
  components: {
    PCIBookingCreditCardForm
  },
  props: {
    appCode: {
      type: String,
      required: true
    },
    appAccessKey: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      creditCardFormVisible: false,
      isLoading: false,
      selectedRow: {},
      deleteCardConfirmationVisibility: false,
      isLoadingList: false,
      isLoadingForm: false
    }
  },
  computed: {
    ...mapState({
      creditCards: state => state.pciBookingCreditCards,
      pciBookingInfo: state => state.pciBookingInfo
    })
  },
  created () {
    this.isLoadingList = true
    pciBookingCards({ appCode: this.appCode }).finally(() => {
      this.isLoadingList = false
    })
  },
  methods: {
    checkPermission,
    async addNewCard () {
      this.isLoadingForm = true
      try {
        await pciBookingSession({ appCode: this.appCode })
      } finally {
        this.creditCardFormVisible = true
        this.isLoadingForm = false
      }
    },
    async removeCreditCard () {
      this.isLoading = true
      const URI = this.selectedRow.URI.split('/')
      const cardToken = URI[URI.length - 1]
      try {
        await pciBookingRemoveCard({ appCode: this.appCode, cardToken })
      } finally {
        this.isLoading = false
        this.deleteCardConfirmationVisibility = false
      }
      this.isLoadingList = true
      try {
        await pciBookingCards({ appCode: this.appCode })
      } finally {
        this.isLoadingList = false
      }
    },
    async successCallback () {
      this.creditCardFormVisible = false
      this.isLoadingList = true
      try {
        await pciBookingCards({ appCode: this.appCode })
      } finally {
        this.isLoadingList = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../styles/bulma-variables';
</style>
