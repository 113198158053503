<template>
  <section class="section is-mini">
    <div class="is-paddingless">
      <el-button
        :loading="isLoadingForm"
        :disabled="isLoadingList"
        v-show="!sripeCreditCardFormVisible && checkPermission(['COMPANY_CREDIT_CARDS_EDIT'])"
        type="success"
        size="small"
        @click="addNewCard"
      >Add Card
      </el-button>
      <StripeCreditCardForm
        v-if="sripeCreditCardFormVisible"
        :stripePk="stripePk"
        :clientSecret="stripeClientSecret"
        ref="creditCardForm"
        @submitted="addStripeCreditCard" />
      <el-table
        v-loading="isLoadingList"
        :data="creditCards"
        style="width: 100%">
        <el-table-column
          label="Card (Last 4)">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.last4 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Brand">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.brand }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Country">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.country }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Cardholder Name">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.cardHolderName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="checkPermission(['COMPANY_CREDIT_CARDS_EDIT'])"
          label="Actions"
          width="120">
          <template slot-scope="scope">
            <el-button type="danger" size="mini" icon="el-icon-delete" circle
                       @click="deleteCardConfirmationVisibility = true;selectedRow = scope.row"></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog
        title="Warning"
        :visible.sync="deleteCardConfirmationVisibility"
        width="30%">
        <p>Are you sure want to delete this card? You could not be longer use this card at the checkout page.</p>
        <span slot="footer" class="dialog-footer">
          <el-button :disabled="isLoading" @click="deleteCardConfirmationVisibility = false">Cancel</el-button>
          <el-button type="primary" :loading="isLoading" @click="removeStripeCreditCard">Continue</el-button>
        </span>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import {
  stripeCardIntent,
  stripeAddCard,
  stripeRemoveCard,
  stripeCards
} from '../../factories/paymentMethod'
import StripeCreditCardForm from '@/components/StripeCreditCardForm'
import checkPermission from '@/plugins/permission'

export default {
  name: 'PaymentMethodStripe',
  components: {
    StripeCreditCardForm
  },
  props: {
    appCode: {
      type: String,
      required: true
    },
    stripePk: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      sripeCreditCardFormVisible: false,
      isLoading: false,
      selectedRow: {},
      deleteCardConfirmationVisibility: false,
      isLoadingList: false,
      isLoadingForm: false
    }
  },
  computed: {
    ...mapState({
      stripeClientSecret: state => state.stripeClientSecret,
      creditCards: state => state.stripeCreditCards
    })
  },
  metaInfo: {
    title: 'Payment Method'
  },
  created () {
    this.isLoadingList = true
    stripeCards({ appCode: this.appCode }).finally(() => {
      this.isLoadingList = false
    })
  },
  methods: {
    checkPermission,
    async addNewCard () {
      this.isLoadingForm = true
      try {
        await stripeCardIntent({ appCode: this.appCode })
      } finally {
        this.sripeCreditCardFormVisible = true
        this.isLoadingForm = false
      }
    },
    async addStripeCreditCard (paymentMethod) {
      this.isLoadingList = true
      this.sripeCreditCardFormVisible = false
      try {
        await stripeAddCard({ appCode: this.appCode, paymentMethod })
        await stripeCards({ appCode: this.appCode })
      } finally {
        this.isLoadingList = false
      }
    },
    async removeStripeCreditCard () {
      this.isLoading = true
      try {
        await stripeRemoveCard({ appCode: this.appCode, paymentMethod: this.selectedRow })
      } finally {
        this.isLoading = false
        this.deleteCardConfirmationVisibility = false
      }

      this.isLoadingList = true
      try {
        await stripeCards({ appCode: this.appCode })
      } finally {
        this.isLoadingList = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../styles/bulma-variables';
</style>
