<template>
  <el-form class="form" ref="form" label-position="top">
    <el-form-item label="Application" :gutter="10">
      <template slot="label">
        <span class="is-size-6">Roles</span>
        <span class="is-size-7">
          showing  {{showing}}/{{ rolesTableData.length }}
        </span>
      </template>
      <el-row :gutter="10">
        <el-col :xs="22" :sm="20">
          <el-select
            style="width: 100%;"
            v-model="selectedRoles"
            multiple
            filterable
            placeholder="Any"
            @change="handleChange"
          >
            <el-option
              v-for="role in rolesTableData"
              :key="role.code"
              :label="role.name"
              :value="role.code">
                <span class="is-pulled-left">{{ role.name }} {{mx_user.roleType === 'INTERNAL' && role.clientCode ? '(' + role.clientCode + ')' : ''}}</span>
                <el-tag
                  v-if="role.description"
                  class="is-pulled-right"
                  style="margin: 6px;"
                  size="mini"
                  :type="role.type === 'INTERNAL' ? 'danger' : 'primary'">
                    <span>{{role.description}}</span>
                </el-tag>
            </el-option>
          </el-select>
        </el-col>
        <el-col :xs="22" :sm="2">
          <el-button type="danger" v-show="canClear" @click="handleClear">clear</el-button>
        </el-col>
      </el-row>
    </el-form-item>
  </el-form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PermissionsFilter',
  data () {
    return {
      selectedRoles: []
    }
  },
  computed: {
    showing () {
      return this.selectedRoles.length || this.rolesTableData.length
    },
    canClear () {
      return this.selectedRoles.length > 0
    },
    ...mapGetters(['rolesTableData'])
  },
  watch: {
    rolesTableData: {
      immediate: true,
      deep: true,
      handler (nV) {
        this.handleChange()
      }
    }
  },
  methods: {
    handleChange () {
      let roles = this.selectedRoles.length ? this.rolesTableData.filter((item) => this.selectedRoles.indexOf(item.code) !== -1) : [...this.rolesTableData]
      this.$emit('change', roles)
    },
    handleClear () {
      this.selectedRoles = []
      this.handleChange()
    }
  }

}
</script>

<style lang="scss" scoped>
  .form /deep/ .el-form-item__label {
    margin-bottom: 0;
    line-height: 1;
  }
</style>
