<template>
  <section class="section is-small">
    <div class="box">
      <el-table
        ref="previewTable"
        size="medium"
        empty-text="Click preview"
        class="users--table"
        :data="liveRows"
        stripe
        header-cell-class-name="users--table-th"
        style="width: 100%; min-height: 220px;">
        <el-table-column
          align="center"
          label="Samples">
        <el-table-column
          label="#"
          align="center"
          width="40">
          <template slot-scope="{ row, $index }">
            <el-button
                class="has-text-danger is-size-6"
                type="text"
                icon="el-icon-delete"
                @click="doDeleteSampleData($index)"
                size="mini">
              </el-button>
          </template>
        </el-table-column>
        <el-table-column
          fixed
          label="Cost CCY"
          align="center"
          width="100">
          <template slot-scope="{ $index }">
            <el-select
              filterable
              size="small"
              autocomplete="off"
              placeholder="CCY"
              v-model="rawPackages[$index].pckg.room_rate_currency"
              name="ccy"
              @change="onCostCurrencyChanged($index, rawPackages[$index].pckg.room_rate_currency)"
              >
              <el-option v-for="it in currencies" :key="it.abbreviation" :value="it.abbreviation" :label="it.abbreviation" />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          label="Cost"
          align="center"
          width="110">
          <template slot-scope="{ $index }">
            <el-input
              size="small"
              type="number"
              class="input-with-select"
              name="room_rate"
              autocomplete="off"
              placeholder="Cost"
              @blur="onSampleDataChanged()"
              v-model.number="rawPackages[$index].pckg.room_rate">
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
          label="Market rate"
          align="center"
          width="130">
          <template slot-scope="{ $index }">
            <a class="is-inline-block"
              style="padding: 6px 0 6px 6px;"
              @click.prevent="toggleMarketRate($index, false)"
              v-if="rawPackages[$index].pckg.indicative_market_rates">
              <i class="el-icon-delete is-size-6"/>
            </a>
            <a class="is-size-7"
              @click.prevent="toggleMarketRate($index, true)"
              v-else>ADD</a>
            <div class="is-pulled-left" style="width: 70%;" v-if="rawPackages[$index].pckg.indicative_market_rates">
              <el-input
                size="small"
                type="number"
                name="marketRate"
                autocomplete="off"
                placeholder="Cost"
                @blur="onSampleDataChanged()"
                v-model.number="rawPackages[$index].pckg.indicative_market_rates[0].market_rate">
              </el-input>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column
          label="Taxes & fees"
          align="center"
          width="120">
          <template slot-scope="{ $index }">
              <a class="is-inline-block"
                style="padding: 6px 0 6px 6px;"
                @click.prevent="toggleTaxesAndFees($index, false)"
                v-if="rawPackages[$index].pckg.taxes_and_fees
                  && rawPackages[$index].pckg.taxes_and_fees.total">
                <i class="el-icon-delete is-size-6"/>
              </a>
              <a class="is-size-7"
                @click.prevent="toggleTaxesAndFees($index, true)"
                v-else>ADD</a>
              <div class="is-pulled-left" style="width: 70%;"
                v-if="rawPackages[$index].pckg.taxes_and_fees
                && rawPackages[$index].pckg.taxes_and_fees.total">
                <el-input
                  size="small"
                  type="number"
                  name="totalTaxes"
                  autocomplete="off"
                  placeholder="Cost"
                  v-model.number="rawPackages[$index].pckg.taxes_and_fees.total.value">
                </el-input>
              </div>
          </template>
        </el-table-column> -->
        <el-table-column
          label="Display CCY"
          align="center"
          width="110">
          <template slot="header" slot-scope="{ column }">
            {{ column.label }}
            <el-tooltip
              placement="top"
              content="Your customer friendly CCY">
              <i class="el-icon-question" />
            </el-tooltip>
          </template>
          <template slot-scope="{ $index }">
            <el-select
              filterable
              size="small"
              autocomplete="off"
              placeholder="CCY"
              @change="onSampleDataChanged()"
              v-model="rawPackages[$index].targetCcy"
              name="targetCcy"
              >
              <el-option v-for="it in currencies" :key="it.abbreviation" :value="it.abbreviation" :label="it.abbreviation" />
            </el-select>
          </template>
        </el-table-column>
        </el-table-column>
        <el-table-column
          align="center"
          label="Rates" >
        <el-table-column
          label="Ver"
          align="center"
          width="100">
          <template>
            <div class="live-val">Published</div>
            <div class="draft-val" v-if="draftRows">Draft</div>
          </template>
        </el-table-column>
       <el-table-column
          class-name="has-text-ellipsis"
          prop="initialForex"
          label="Forex"
          align="right">
          <template slot="header" slot-scope="{ column }">
            {{column.label}}
            <el-tooltip
              placement="top"
              content="From openexchangerates.org (Included forex markup)">
              <i class="el-icon-question" />
            </el-tooltip>
          </template>
          <template slot-scope="{ row, $index }">
            <div class="live-val">{{ row.initialForex | number({ maximumFractionDigits: 6 }) }}</div>
            <div class="draft-val" v-if="draftRows">{{ draftRows[$index].initialForex | number({ maximumFractionDigits: 6 }) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="convertedCost"
          label="Cost"
          align="right"
          min-width="110">
          <template slot="header" slot-scope="{ column }">
            {{ column.label }}
            <el-tooltip
              placement="top"
              content="Your cost in display CCY (included forex markup)">
              <i class="el-icon-question" />
            </el-tooltip>
          </template>
          <template slot-scope="{ row, $index}">
            <div class="live-val">{{ row.convertedCost || row.cost | currencyObj }}</div>
            <div class="draft-val" v-if="draftRows">
              {{ draftRows[$index].convertedCost || draftRows[$index].cost | currencyObj }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="unitDisplayRate"
          label="Inital sell rate"
          align="right"
          min-width="110">
          <template slot="header" slot-scope="{ column }">
            {{ column.label }}
            <el-tooltip
              placement="top"
              content="Base selling price">
              <i class="el-icon-question" />
            </el-tooltip>
          </template>
          <template slot-scope="{ row , $index}">
            <PricingPreviewCell class="live-val" :skip="false" :content="row.unitDisplayRate | currencyObj" />
            <PricingPreviewCell class="draft-val" v-if="draftRows" :skip="false" :content="draftRows[$index].unitDisplayRate | currencyObj" />
          </template>
        </el-table-column>
        <el-table-column
          prop="initialAdjustmentPct"
          label="Adjusted %"
          align="right"
          width="100">
          <template slot-scope="{ row, $index }">
            <PricingPreviewCell class="live-val" :skip="row.skip" :content="row.initialAdjustmentPct | pct" />
            <PricingPreviewCell class="draft-val"  v-if="draftRows" :skip="draftRows[$index].skip" :content="draftRows[$index].initialAdjustmentPct | pct" />
          </template>
        </el-table-column>
        <el-table-column
          prop="unitAdjustedDisplayRate"
          label="Adjusted sell rate"
          align="right"
          min-width="110">
          <template slot="header" slot-scope="{ column }">
            {{ column.label }}
            <el-tooltip
              placement="top"
              content="Usually display as discounted rate. (Included transaction fees)">
              <i class="el-icon-question" />
            </el-tooltip>
          </template>
          <template slot-scope="{ row, $index }">
            <PricingPreviewCell class="live-val" :skip="row.skip" :content="row.unitAdjustedDisplayRate | currencyObj" />
            <PricingPreviewCell class="draft-val" v-if="draftRows" :skip="draftRows[$index].skip" :content="draftRows[$index].unitAdjustedDisplayRate | currencyObj" />
          </template>
        </el-table-column>
        <el-table-column
          prop="finalAdjustment"
          label="Final Adjustment %"
          align="right"
          width="100">
          <template slot-scope="{ row, $index }">
            <PricingPreviewCell class="live-val" :skip="row.skip" :content="getFinalAdjustmentPct(row.finalAdjustments) | pct" />
            <PricingPreviewCell class="draft-val"  v-if="draftRows" :skip="draftRows[$index].skip" :content="getFinalAdjustmentPct(draftRows[$index].finalAdjustments) | pct" />
          </template>
        </el-table-column>
        <el-table-column
          prop="finalAdjustmentAmount"
          label="Final Adjustment Amount"
          align="right"
          min-width="110">
          <template slot="header" slot-scope="{ column }">
            {{ column.label }}
            <el-tooltip
              placement="top"
              content="Final Adjustment Amount">
              <i class="el-icon-question" />
            </el-tooltip>
          </template>
          <template slot-scope="{ row, $index }">
            <PricingPreviewCell class="live-val" :skip="row.skip" :content="row.finalAdjustmentAmount | currencyObj" />
            <PricingPreviewCell class="draft-val" v-if="draftRows" :skip="draftRows[$index].skip" :content="draftRows[$index].finalAdjustmentAmount | currencyObj" />
          </template>
        </el-table-column>
        <el-table-column
          class-name="has-text-ellipsis"
          prop="totalAmount"
          label="Final Rate"
          align="right"
          min-width="110">
          <template slot="header" slot-scope="{ column }">
            {{ column.label }}
            <el-tooltip
              placement="top"
              content="Usually display as Total Amount. (Included taxes)">
              <i class="el-icon-question" />
            </el-tooltip>
          </template>
          <template slot-scope="{ row, $index }">
            <PricingPreviewCell class="live-val" :skip="row.skip" :content="row.totalAmount | currencyObj" />
            <PricingPreviewCell class="draft-val" v-if="draftRows" :skip="draftRows[$index].skip" :content="draftRows[$index].totalAmount | currencyObj" />
          </template>
        </el-table-column>
        <el-table-column
          prop="score"
          label="Net profit %"
          align="right"
          width="110">
          <template slot="header" slot-scope="{ column }">
            {{ column.label }}
            <el-tooltip
              placement="top"
              content="Your protential earning after transaction fees">
              <i class="el-icon-question" />
            </el-tooltip>
          </template>
          <template slot-scope="{ row, $index }">
            <PricingPreviewCell class="live-val" :skip="row.skip" :content="row.score  | pct({ minimumFractionDigits: 2 })" />
            <PricingPreviewCell class="draft-val"  v-if="draftRows" :skip="draftRows[$index].skip" :content="draftRows[$index].score | pct({ minimumFractionDigits: 2 })" />
          </template>
        </el-table-column>
        <el-table-column
          prop="raw"
          label="Audit logs"
          width="120">
          <template slot="header" slot-scope="{ column }">
            {{ column.label }}
            <el-tooltip
              placement="top"
              content="Pricing calculation technical details">
              <i class="el-icon-question" />
            </el-tooltip>
          </template>
          <template slot-scope="{ row, $index }">
            <div class="live-val"><PriceAudit :raw="row.raw" content="Published Audit"/></div>
            <div class="draft-val" v-if="draftRows"><PriceAudit :raw="draftRows[$index].raw" content="Draft Audit" /></div>
          </template>
        </el-table-column>
        </el-table-column>
        <div class="list" slot="append">
          <div class="list-content">
            <el-button
              type="primary"
              size="small"
              @click="doAddNewSampleData"
              >ADD SAMPLE</el-button>
          </div>
        </div>
      </el-table>
    </div>
    <!-- Content -->
    <div class="box is-stick">
      <div class="list is-comfort">
        <div class="list-action">
          <div class="list-label">Published ver</div>
          <div class="list-value">{{ details.pricingId }}</div>
        </div>
        <div class="list-action">
          <div class="list-label">Published at</div>
          <div class="list-value">{{ details.pricing.createdAt | date }}</div>
        </div>
        <div class="list-action">
          <div class="list-label">Published by</div>
          <div class="list-value">{{ details.pricing.editor.displayName }}</div>
        </div>
        <div class="list-content">
        </div>
        <div class="list-action"
          style="padding-right: 0;"
          v-if="details.draftPricingId && !isShowingDraft">
          <el-popover
            placement="top"
            trigger="hover"
            width="250">
            <div class="list">
              <div class="list-title">Draft info</div>
            </div>
            <div class="list is-small is-narrow"
              v-if="details.draftPricingId">
              <div class="list-content">
                <div class="list-label">Last saved at</div>
                <div class="list-value">{{ details.draft.updatedAt | date }}</div>
              </div>
            </div>
            <div class="list is-small is-narrow"
              v-if="details.draftPricingId">
              <div class="list-content">
                <div class="list-label">Modified by</div>
                <div class="list-value">{{ details.draft.editor.displayName || '–' }}</div>
              </div>
            </div>
          <el-button
            slot="reference"
            size="medium"
            @click="doLoadDraft()"
            type="info"
            >LOAD SAVED DRAFT</el-button>
          </el-popover>
        </div>
        <div class="list-action"
          style="padding-right: 0;"
          v-else-if="details.draftPricingId">
          <el-button
            slot="reference"
            size="medium"
            @click="doLoadLive"
            >UNLOAD DRAFT</el-button>
        </div>
        <div class="list-action">
          <el-button
            class="pricing--save-and-preview-button"
            :loading="isSaving"
            :disabled="isSaving || formClean"
            size="medium"
            type="primary"
            icon="el-icon-view"
            @click="hanldeSaveAndPreviewClicked(true)"
            >SAVE AND PREVIEW</el-button>
          <el-button type="danger"
            :loading="isPublishing"
            :disabled="isPublishing || !isShowingDraft"
            @click="publishConfirmationModalVisibility = true"
            icon="el-icon-upload2"
            size="medium">PUBLISH</el-button>
        </div>
      </div>
    </div>
    <div class="columns">
      <!-- Configurations -->
      <div class="column is-4">
        <div class="list">
          <div class="list-content">
            <div class="list-title has-text-primary">Configurations</div>
          </div>
        </div>
        <div class="box is-stick">
          <div class="">
            <el-form
              ref="localForm"
              label-position="left"
              @submit.native.prevent=""
              label-width="130"
              :model="localForm">
              <div class="list is-narrow is-small"></div>
              <div class="list is-comfort is-small">
                <div class="list-content">
                  <div class="list-value has-text-weight-semibold">Forex markup</div>
                  <div class="list-label">Idealy should be 2 ~ 4%</div>
                </div>
                <div class="list-action">
                <el-input-number
                  :value="localForm.forexMarkupPct * 100"
                  @input="(val) => localForm.forexMarkupPct = val / 100"
                  :controls="false"
                  placeholder="0"
                  style="width: 100px;"
                  size="medium"
                  class="percentage-input-number"
                  :precision="2"/>
                </div>
              </div>
              <div class="list is-comfort is-small">
                <div class="list-content">
                  <div class="list-value has-text-weight-semibold">Min-markup</div>
                  <div class="list-label">Min profit you want to get</div>
                </div>
                <div class="list-action">
                  <el-input-number
                    :value="localForm.minMarkupInPct * 100"
                    @input="(val) => localForm.minMarkupInPct = val / 100"
                    :controls="false"
                    placeholder="0"
                    style="width: 100px;"
                    size="medium"
                    class="percentage-input-number"
                    :precision="2"/>
                </div>
              </div>
              <div class="list is-narrow is-small"></div>
            </el-form>
          </div>
        </div>
        <!-- Initial sell rate -->
        <div class="list">
          <div class="list-content">
            <div class="list-title has-text-primary">
              Initial sell rate
            </div>
          </div>
        </div>
        <div class="box is-stick">
          <div class="">
            <div class="list is-narrow is-small"></div>
            <div class="list is-comfort is-small">
              <div class="list-content">
                <div class="list-value has-text-weight-semibold">How will you like to list the properties price?</div>
                <div class="list-label">Output as base price. Futher adjustment can be done in (<i>Initial adjustment amount</i>) located at the right panel.</div>
              </div>
              <div class="list-action">
                <el-select
                  style="width: 150px;"
                  name="initialSellRateConfigType"
                  v-model="localForm.initialSellRateConfig.type"
                  size="medium">
                  <el-option value="SAME_AS_MARKET_RATE" label="Same as market-rate"></el-option>
                  <el-option value="FIX_MARKUP" label="Fixed markup"></el-option>
                </el-select>
              </div>
            </div>
            <div class="list is-comfort is-small"
              v-if="localForm.initialSellRateConfig.type === 'SAME_AS_MARKET_RATE'">
              <div class="list-content">
                <div class="list-value has-text-weight-semibold">When market-rates are not available?</div>
                <div class="list-label">
                  Sometimes we are offering sepcial packages
                </div>
              </div>
              <div class="list-action">
                <el-select
                  name="initialSellRateConfigType"
                  v-model="localForm.initialSellRateConfig.SAME_AS_MARKET_RATE.IF_MARKET_RATE_NOT_AVAILALE.type"
                  size="medium">
                  <el-option value="HIDE" label="Hide the package"></el-option>
                  <el-option value="USE_MIN_MARKUP" :label="'Use min-markup (' + minMarkupInPctStr + ')'">
                  </el-option>
                </el-select>
              </div>
            </div>
            <!-- TODO Backed not supported yet -->
            <div class="list is-comfort is-small"
              v-if="localForm.initialSellRateConfig.type === 'SAME_AS_MARKET_RATE'">
              <div class="list-content">
                <div class="list-value has-text-weight-semibold">
                  When min-sell rate is higher than market rate?
                </div>
                <div class="list-label">
                  In some rare ocasions the market-rates might be lower than your min-sell rate.
                </div>
              </div>
              <div class="list-action">
                <el-select
                  name="initialSellRateConfigType"
                  v-model="localForm.initialSellRateConfig.SAME_AS_MARKET_RATE.IF_MIN_SELLRATE_HIGHER_MARKET_RATE.type"
                  size="medium">
                  <el-option value="HIDE_PACKAGE" label="Hide the package"></el-option>
                  <el-option value="USE_MIN_MARKUP" :label="'Use min-markup (' + minMarkupInPctStr + ')'"></el-option>
                </el-select>
              </div>
            </div>
            <div class="list is-comfort is-small"
              v-if="localForm.initialSellRateConfig.type === 'FIX_MARKUP'">
              <div class="list-content">
                <div class="list-value">
                  <el-input-number
                    :controls="false"
                    placeholder="0"
                    style="width: 100px;"
                    size="medium"
                    class="percentage-input-number"
                    :value="localForm.minMarkupInPct * 100"
                    @input="(val) => localForm.minMarkupInPct = val / 100"
                    :precision="2"/>
                    mark-up will be applied to all properties.
                  </div>
                </div>
            </div>
            <div class="list">
              <div class="message is-small is-marginless">
                <div class="message-header"><span><i class="el-icon-question" /> What is market-rates?</span></div>
                <div class="message-body">
                  <p class="is-size-7 has-line-height-1-25 has-text-dark">
                    Public accessible prices listed on online travel agency sites. E.g: Expdia price, Priceline price.
                  </p>
                </div>
              </div>
            </div>
            <div class="list is-narrow is-small"></div>
          </div>
        </div>
      </div>
      <!-- Initial adjjustment amount -->
      <div class="column is-4">
        <div class="list">
          <div class="list-content">
            <div class="list-title has-text-primary">
              Initial adjustment amount
            </div>
          </div>
        </div>
        <div class="box is-stick">
          <div class="">
            <div class="list is-narrow is-small"></div>
            <div class="list is-comfort is-small">
              <div class="list-content">
                <div class="list-value has-text-weight-semibold">
                  Any adjustment before listing the prices?
                </div>
                <div class="list-label">
                  E.g: Discounts or rebates.
                </div>
                <!-- <div class="list-label">Min profit you want to get</div> -->
              </div>
              <div class="list-action list-is-3">
                <el-select
                  style="width: 80px;"
                  name="initialSellRateConfigType"
                  v-model="localForm.initialAdjustmentAmountConfig.type"
                  size="medium">
                  <el-option value="NO" label="No"></el-option>
                  <el-option value="YES" label="Yes"></el-option>
                </el-select>
              </div>
            </div>
            <div class="list is-comfort is-small has-border-top has-border-bottom"
              v-if="localForm.initialAdjustmentAmountConfig.type === 'YES'">
              <div class="list-content">
                <div class="list-value has-text-weight-semibold">Select one of the adjustment scheme:</div>
                <div class="list-value">
                  <PpGap size="small" />
                  <el-radio-group
                    size="small"
                    v-model="localForm.initialAdjustmentAmountConfig.YES.type">
                    <el-radio-button label="DYNAMIC_DISOUNT">Dynamic</el-radio-button>
                    <el-radio-button label="FIXED_ADJUSTMENT">Fixed</el-radio-button>
                  </el-radio-group>
                  <PpGap size="small"/>
                  <div class="list is-gapless"
                    v-if="localForm.initialAdjustmentAmountConfig.YES.type === 'DYNAMIC_DISOUNT'">
                    <div class="list-content">
                      <!-- <div class="list-value">Dynamic adjustment</div> -->
                      <div class="list-value">The adjustment percetage from your protential profit.</div>
                    </div>
                    <div class="list-action">
                      <el-input-number
                        style="width: 120px;"
                        size="medium"
                        name="forexMarkupPct"
                        autocomplete="off"
                        placeholder="0"
                        :value="localForm.initialAdjustmentAmountConfig.YES.DYNAMIC_DISOUNT.adjustmentPct * 100"
                        @input="(val) => localForm.initialAdjustmentAmountConfig.YES.DYNAMIC_DISOUNT.adjustmentPct = val / 100"
                        :controls="false"
                        class="percentage-input-number"
                        :precision="2"/>
                      <!-- <el-input
                        class="has-text-right"
                        style="width: 90px;"
                        type="number"
                        size="medium"
                        name="forexMarkupPct"
                        autocomplete="off"
                        placeholder="3"
                        :value="Math.floor(localForm.initialAdjustmentAmountConfig.YES.DYNAMIC_DISOUNT.adjustmentPct * 100)"
                        @input="(val) => localForm.initialAdjustmentAmountConfig.YES.DYNAMIC_DISOUNT.adjustmentPct = val / 100">
                        <span class="el-input__icon is-size-6" slot="suffix">%&nbsp;</span>
                      </el-input> -->
                    </div>
                  </div>
                  <div class="list is-gapless"
                    v-if="localForm.initialAdjustmentAmountConfig.YES.type === 'FIXED_ADJUSTMENT'">
                    <div class="list-content">
                      <!-- <div class="list-value">Fixed adjustment</div> -->
                      <div class="list-value">Static adjustment applied to all packages in percentage. <br> <span class="has-text-danger has-text-weight-semibold">(YOU MIGHT SELL IN LOSS)</span></div>
                    </div>
                    <div class="list-action">
                      <el-input-number
                        style="width: 120px;"
                        size="medium"
                        name="forexMarkupPct"
                        autocomplete="off"
                        placeholder="0"
                        :value="localForm.initialAdjustmentAmountConfig.YES.FIXED_ADJUSTMENT.adjustmentPct * 100"
                        @input="(val) => localForm.initialAdjustmentAmountConfig.YES.FIXED_ADJUSTMENT.adjustmentPct = val / 100"
                        :controls="false"
                        class="percentage-input-number"
                        :precision="2"/>
                      <!-- <el-input
                        class="has-text-right"
                        style="width: 90px;"
                        type="number"
                        size="medium"
                        name="forexMarkupPct"
                        autocomplete="off"
                        placeholder="3"
                        :value="Math.floor(localForm.initialAdjustmentAmountConfig.YES.FIXED_ADJUSTMENT.adjustmentPct * 100)"
                        @input="(val) => localForm.initialAdjustmentAmountConfig.YES.FIXED_ADJUSTMENT.adjustmentPct = val / 100">
                        <span class="el-input__icon is-size-6" slot="suffix">%&nbsp;</span>
                      </el-input> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="list is-narrow is-small"></div>
            <div class="list">
              <div class="message is-primary is-small is-marginless">
                <div class="message-header"><span><i class="el-icon-info" /> Tips!</span></div>
                <div class="message-body">
                  <p class="is-size-7 has-line-height-1-25 has-text-dark">
                    You may want to markup your prices at "Initial Sell Rate", and make adjustment here to let your customer enjoy DISCOUNT RATE!
                  </p>
                </div>
              </div>
            </div>
            <div class="list is-narrow is-small"></div>
          </div>
        </div>
      </div>
      <!-- Final adjustment amount -->
      <div class="column is-4">
        <div class="list">
          <div class="list-content">
            <div class="list-title has-text-primary">
              Final adjustment amount
            </div>
          </div>
        </div>
        <div class="box is-stick">
          <div class="">
            <div class="list is-narrow is-small"></div>
            <div class="list is-comfort is-small">
              <div class="list-content">
                <div class="list-value has-text-weight-semibold">
                  Any adjustment before totaling the prices at the checkout page?
                </div>
                <div class="list-label">
                  E.g: taxes, service charges, discounts etc.
                </div>
                <!-- <div class="list-label">Min profit you want to get</div> -->
              </div>
              <div class="list-action list-is-3">
                <el-select
                  style="width: 80px;"
                  name="finalAdjustmentAmountConfigType"
                  v-model="localForm.finalAdjustmentAmountConfig.type"
                  size="medium">
                  <el-option value="NO" label="No"></el-option>
                  <el-option value="YES" label="Yes"></el-option>
                </el-select>
              </div>
            </div>
            <div class="list is-comfort is-small has-border-top has-border-bottom"
              v-if="localForm.finalAdjustmentAmountConfig.type === 'YES'">
              <div class="list-content">
                <div class="list-value">
                  <!-- <div class="list is-gapless">
                    <div class="list-content">
                      <div class="list-value">Show adjustments on checkout page</div>
                    </div>
                    <div class="list-action">
                      <el-select
                        style="width: 100px;"
                        name="showAdjustments"
                        v-model="localForm.finalAdjustmentAmountConfig.showAdjustments"
                        size="medium">
                        <el-option value="NO" label="No"></el-option>
                        <el-option value="YES" label="Yes"></el-option>
                      </el-select>
                    </div>
                  </div> -->
                  <div class="list is-gapless">
                    <div class="list-content">
                      <div class="list-value">Adjustment Type</div>
                    </div>
                    <div class="list-action">
                      <el-select
                        style="width: 200px;"
                        name="fixAdjustmentType"
                        v-model="localForm.finalAdjustmentAmountConfig.YES.FIXED_ADJUSTMENT.type"
                        size="medium">
                        <el-option value="COST" label="Cost with Percentage"></el-option>
                        <el-option value="COST_WITH_VALUE" label="Cost with value"></el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="list is-gapless"
                    v-if="localForm.finalAdjustmentAmountConfig.YES.FIXED_ADJUSTMENT.type === 'COST_WITH_VALUE'">
                    <div class="list-content">
                      <div class="list-value">Currency</div>
                    </div>
                    <div class="list-action">
                      <el-select
                        size="medium"
                        style="width: 200px;"
                        filterable
                        v-model="localForm.finalAdjustmentAmountConfig.YES.FIXED_ADJUSTMENT.COST_WITH_VALUE.ccy">
                        <el-option
                          v-for="item in details.config.more.supportedCurrencies"
                          :key="item.abbreviation"
                          :label="`(${item.abbreviation}) ${item.currency}`"
                          :value="item.abbreviation"
                        ></el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="list-label"
                    v-if="localForm.finalAdjustmentAmountConfig.YES.FIXED_ADJUSTMENT.type === 'COST_WITH_VALUE'">
                    Currencies are supported currency
                  </div>
                  <div class="list is-gapless">
                    <div class="list-content">
                      <div class="list-value has-text-weight-semibold">Any adjustments wants to add:</div>
                    </div>
                  </div>
                  <!-- COST -->
                  <div class="list is-gapless"
                    v-if="localForm.finalAdjustmentAmountConfig.YES.FIXED_ADJUSTMENT.type === 'COST'"
                    v-for="(adjustment, index) in localForm.finalAdjustmentAmountConfig.YES.FIXED_ADJUSTMENT.COST.list" :key="index">
                    <div class="list-content">
                      <div class="list-value">
                        <el-input placeholder="Booking Fee" v-model="adjustment.type"></el-input>
                      </div>
                    </div>
                    <div class="list-action">
                      <el-checkbox v-model="adjustment.isDisplayed" style="margin-right:10px">Show in Checkout page</el-checkbox>
                      <el-input-number
                        style="width: 90px;"
                        size="medium"
                        name="adjustmentPct"
                        autocomplete="off"
                        placeholder="0"
                        :value="adjustment.adjustmentPct * 100"
                        @input="(val) => adjustment.adjustmentPct = val / 100"
                        :controls="false"
                        class="percentage-input-number"
                        :precision="2"/>
                      <el-button
                        style="margin-left: 5px"
                        class="has-text-danger is-size-6"
                        type="text"
                        icon="el-icon-delete"
                        @click="removeAdjustment(index)"
                        size="mini">
                      </el-button>
                    </div>
                  </div>
                  <!-- COST WITH VALUE -->
                  <div class="list is-gapless"
                      v-if="localForm.finalAdjustmentAmountConfig.YES.FIXED_ADJUSTMENT.type === 'COST_WITH_VALUE'"
                      v-for="(adjustment, index) in localForm.finalAdjustmentAmountConfig.YES.FIXED_ADJUSTMENT.COST_WITH_VALUE.list" :key="index">
                    <div class="list-content">
                      <div class="list-value">
                        <el-input placeholder="Booking Fee" v-model="adjustment.type"></el-input>
                      </div>
                    </div>
                    <div class="list-action">
                      <el-checkbox v-model="adjustment.isDisplayed" style="margin-right:10px">Show in Checkout page</el-checkbox>
                      <el-input
                        class="has-text-right"
                        style="width: 130px;"
                        type="number"
                        size="medium"
                        name="adjustment"
                        autocomplete="off"
                        placeholder="3"
                        :value="adjustment.adjustment"
                        @input="(val) => adjustment.adjustment = val">
                        <span class="el-input__icon is-size-7" slot="prefix">
                          {{localForm.finalAdjustmentAmountConfig.YES.FIXED_ADJUSTMENT.COST_WITH_VALUE.ccy}} &nbsp;
                        </span>
                      </el-input>
                      <el-button
                        style="margin-left: 5px"
                        class="has-text-danger is-size-6"
                        type="text"
                        icon="el-icon-delete"
                        @click="removeAdjustment(index)"
                        size="mini">
                      </el-button>
                    </div>
                  </div>
                  <el-button type="text"
                    @click="addMoreAdjustment()">
                    + Add more
                  </el-button>
                </div>
              </div>
            </div>
            <div class="list is-narrow is-small"></div>
            <div class="list">
              <div class="message is-primary is-small is-marginless">
                <div class="message-header"><span><i class="el-icon-info" /> Tips!</span></div>
                <div class="message-body">
                  <p class="is-size-7 has-line-height-1-25 has-text-dark">
                    You may want to add more adjustments before calculating total price.
                    Adjusments can be displayed at checkout page
                  </p>
                </div>
              </div>
            </div>
            <div class="list is-narrow is-small"></div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="Are you sure you want to overwrite exiting draft and continue?"
      :visible.sync="draftOverwriteModalVisibility"
      width="30%">
      <p>You will lost your exiting draft record permanently after continue. Alternatively, you might want to <span class="has-text-weight-semibold">cancel</span> and continue after "load saved draft".</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="draftOverwriteModalVisibility = false">Cancel</el-button>
        <el-button type="primary" @click="doOverwriteAndContinue">Continue</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="Are you sure you want to publish current draft?"
      :visible.sync="publishConfirmationModalVisibility"
      width="30%">
      <p>After published, new prices will be reflect in booking site with immediate effect.</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="publishConfirmationModalVisibility = false">No</el-button>
        <el-button type="primary" @click="publishConfirmationModalVisibility = false; doPublishPricing()">Continue</el-button>
      </span>
    </el-dialog>
    <PpModal
      v-model="publishingModalVisibility"
      :isLoading="isPublishing"
      loadingMessage="Publishing...">
      <div class="box" style="padding: 0;">
        <div class="box-content"
          v-if="publishError">
          <h5 class="is-size-5 has-text-weight-semibold has-text-black-ter">
            <i class="el-icon-warning has-text-danger"/> Publish error
          </h5>
          <div class="list is-gapless">
            <div class="list-content">
              {{ publishError.message }}
            </div>
          </div>
          <div class="gap-x2"></div>
          <div class="list is-gapless is-bottomless">
            <div class="list-content"></div>
            <div
              class="list-action">
              <el-button
                type="primary"
                @click="publishingModalVisibility = false">Ok
              </el-button>
            </div>
          </div>
        </div>
        <div class="box-content has-text-centered"
          v-else>
          <i class="el-icon-success has-text-success is-size-1" />
          <div class="gap-x2"></div>
          <h5 class="is-size-5 has-text-weight-semibold has-text-black-ter">Publish successfully</h5>
          <div class="gap-x2"></div>
          <div class="list is-gapless is-bottomless">
            <div class="list-content"></div>
            <div
              class="list-action">
              <el-button
                type="primary"
                @click="publishingModalVisibility = false">Ok
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </PpModal>
  </section>
</template>

<script>
import currencies from '../../plugins/currencies'
// import { mapGetters } from 'vuex'
import { applicationsPublishPricings, applicationsOne, pricingsPreview, pricingsSave } from '../../factories/manage'

import PriceAudit from '../../components/PriceAudit'
import PricingPreviewCell from '../../components/PricingPreviewCell'

const SMAPLE_DATA_KEY = 'lv2prt_smdk'

const rawPackages = [{
  targetCcy: 'USD',
  pckg: {
    room_rate: 100,
    room_rate_currency: 'USD',
    taxes_and_fees: {
      total: {
        currency: 'USD',
        value: 17
      }
    },
    indicative_market_rates: [{
      market_rate: 120,
      market_rate_currency: 'USD',
      market_rate_supplier: 'draft'
    }]
  },
  deleteConfirmation: false
}]

const clonedForm = (data) => {
  const cloned = JSON.parse(JSON.stringify(data))
  /**
   * Prefill missing properties
   */

  cloned.initialSellRateConfig.SAME_AS_MARKET_RATE = {
    IF_MARKET_RATE_NOT_AVAILALE: { type: 'USE_MIN_MARKUP' },
    IF_MIN_SELLRATE_HIGHER_MARKET_RATE: { type: 'USE_MIN_MARKUP' },
    ...cloned.initialSellRateConfig.SAME_AS_MARKET_RATE
  }
  return cloned
}

const lll = {
  loadSamples () {
    const k = JSON.parse(localStorage.getItem(SMAPLE_DATA_KEY))
    if (k == null || (Array.isArray(k) && k.length === 0)) {
      localStorage.setItem(SMAPLE_DATA_KEY, JSON.stringify(rawPackages))
      return rawPackages
    }
    return k
  },
  setSamples (samples) {
    localStorage.setItem(SMAPLE_DATA_KEY, JSON.stringify(samples))
    return samples
  }
}

export default {
  name: 'ManageApplicationsDetailsPricing',
  components: {
    PriceAudit,
    PricingPreviewCell
  },
  props: {
    appCode: {
      type: String,
      required: true
    },
    query: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      formClean: true,
      publishingModalVisibility: false,
      publishConfirmationModalVisibility: false,
      draftOverwriteModalVisibility: false,
      publishError: undefined,
      isSaving: false,
      isPublishing: false,
      currencies,
      previewRows: {},
      debounceTimer: undefined,
      localForm: {},
      loadingNewApp: false,
      rawPackages: [],
      isShowingDraft: false, // Indecated current record in form
      draft: undefined
    }
  },
  computed: {
    details () {
      return this.$parent.details
    },
    liveId () {
      return this.$parent.details.pricingId
    },
    draftId () {
      return this.$parent.details.draftPricingId === null ? undefined : this.$parent.details.draftPricingId
    },
    minMarkupInPctStr () {
      return this.$root.$options.filters.pct(this.localForm.minMarkupInPct, {
        factionDigits: 3, minimumFractionDigits: 0
      })
    },
    liveRows () {
      return this.previewRows[this.liveId]
    },
    draftRows () {
      return this.previewRows[this.draftId]
    }
  },
  watch: {
    'localForm': {
      deep: true,
      handler (nV, oV) {
        if (oV.txFees != null) {
          this.formClean = false
        }
      }
    }
  },
  created () {
    this.rawPackages = lll.loadSamples()
    this.doLoadLive()
  },
  methods: {
    doLoadLive () {
      this.localForm = clonedForm(this.details.pricing)
      this.isShowingDraft = false
      this.doSaveAndPreview(false)
        .then(() => {
          this.formClean = true
        })
    },
    doLoadDraft (record = this.details.draft) {
      this.localForm = clonedForm(record)
      this.isShowingDraft = true
      this.onSampleDataChanged()
        .then(() => {
          this.formClean = true
        })
    },
    doPublishPricing () {
      this.publishError = undefined
      this.publishingModalVisibility = true
      this.isPublishing = true
      applicationsPublishPricings({
        ...this.localForm
      }).then(() => applicationsOne({ appCode: this.appCode }))
        .then(() => this.doLoadDraft())
        .then(() => {
          this.isPublishing = false
          this.formClean = true
        })
        .catch(err => {
          this.publishError = err
          this.isPublishing = false
          console.error(err)
        })
    },
    hanldeSaveAndPreviewClicked () {
      /**
       * Warn the user the subsecet action gonna overwrite the current draft.
       * If user acpeted we will populated the draft and called again this method
       */
      if (!this.isShowingDraft && this.details.draftPricingId) {
        this.draftOverwriteModalVisibility = true
        return
      }
      this.formClean = true
      this.doSaveAndPreview(true)
      this.isShowingDraft = true
    },
    doOverwriteAndContinue () {
      this.doLoadDraft(this.localForm)
      this.doSaveAndPreview(true)
      this.draftOverwriteModalVisibility = false
    },
    onSampleDataChanged (rawPackages = this.rawPackages) {
      const body = {
        appCode: this.appCode,
        pricingIds: [this.isShowingDraft && this.draftId, this.liveId].filter(e => e),
        rawPackages
      }
      return pricingsPreview(body)
        .then(({ rows }) => {
          lll.setSamples(rawPackages)
          this.previewRows = rows
        })
    },
    doSaveAndPreview (withDraft = false, rawPackages = this.rawPackages) {
      this.isSaving = true
      return Promise.resolve()
        .then(() => {
          if (withDraft) {
            return pricingsSave({
              appCode: this.appCode,
              ...this.localForm
            })
          }
        })
        .then(() => applicationsOne({ appCode: this.appCode }))
        .then(() => {
          const body = {
            appCode: this.appCode,
            pricingIds: [withDraft && this.draftId, this.liveId].filter(e => e),
            rawPackages
          }
          return pricingsPreview(body)
        })
        .then(({ rows }) => {
          this.previewRows = rows
          this.isSaving = false
        })
        .catch(err => {
          this.isSaving = false
          console.error(err)
        })
    },
    toggleMarketRate ($index, isAdd) {
      if (isAdd) {
        // !!! MUTATIONS
        this.rawPackages[$index].pckg = {
          ...this.rawPackages[$index].pckg,
          indicative_market_rates: [{
            market_rate: this.rawPackages[$index].pckg.room_rate * 1.05,
            market_rate_currency: this.rawPackages[$index].pckg.room_rate_currency,
            market_rate_supplier: 'daft'
          }]
        }
      } else {
        this.rawPackages[$index].pckg.indicative_market_rates = undefined
      }
      this.onSampleDataChanged()
    },
    toggleTaxesAndFees ($index, isAdd) {
      if (isAdd) {
        // !!! MUTATIONS
        this.rawPackages[$index].pckg = {
          ...this.rawPackages[$index].pckg,
          taxes_and_fees: {
            total: {
              currency: this.rawPackages[$index].pckg.room_rate_currency,
              value: this.rawPackages[$index].pckg.room_rate * 0.05
            }
          }
        }
      } else {
        this.rawPackages[$index].pckg.taxes_and_fees = undefined
      }
      this.onSampleDataChanged()
    },
    /**
     * sync taxes and fees currency and market rate currency when user changed the cost currency. (ignore when the field is missing)
     */
    onCostCurrencyChanged ($index, ccy) {
      const k = this.rawPackages[$index].pckg
      if (k.taxes_and_fees && k.taxes_and_fees.total) {
        // !!! MUTATIONS
        this.rawPackages[$index].pckg.taxes_and_fees.total.currency = ccy
      }
      if (k.indicative_market_rates && k.indicative_market_rates[0]) {
        // !!! MUTATIONS
        this.rawPackages[$index].pckg.indicative_market_rates[0].market_rate_currency = ccy
      }
      this.onSampleDataChanged()
    },
    doDeleteSampleData ($index) {
      const cloned = JSON.parse(JSON.stringify(this.rawPackages))
      cloned.splice($index, 1)
      this.onSampleDataChanged(cloned)
        .then(() => {
          this.rawPackages = cloned
        })
    },
    doAddNewSampleData () {
      const cloned = JSON.parse(JSON.stringify(this.rawPackages))
      const standardPckg = JSON.parse(JSON.stringify(rawPackages[0]))
      cloned.push(standardPckg)
      this.rawPackages = cloned
      this.onSampleDataChanged(cloned)
    },
    addMoreAdjustment () {
      if (this.localForm.finalAdjustmentAmountConfig.YES.FIXED_ADJUSTMENT.type === 'COST') {
        this.localForm.finalAdjustmentAmountConfig.YES.FIXED_ADJUSTMENT.COST.list.push({
          type: '',
          adjustmentPct: 0,
          isDisplayed: false
        })
      } else {
        this.localForm.finalAdjustmentAmountConfig.YES.FIXED_ADJUSTMENT.COST_WITH_VALUE.list.push({
          type: '',
          adjustment: 0,
          isDisplayed: false
        })
      }
    },
    removeAdjustment (index) {
      if (this.localForm.finalAdjustmentAmountConfig.YES.FIXED_ADJUSTMENT.type === 'COST') {
        this.localForm.finalAdjustmentAmountConfig.YES.FIXED_ADJUSTMENT.COST.list.splice(index, 1)
      } else {
        this.localForm.finalAdjustmentAmountConfig.YES.FIXED_ADJUSTMENT.COST_WITH_VALUE.list.splice(index, 1)
      }
    },
    getFinalAdjustmentPct (listAdjustments) {
      let totalAdjustmentPct = 0
      if (this.localForm.finalAdjustmentAmountConfig.YES.FIXED_ADJUSTMENT.type === 'COST') {
        if (listAdjustments.length === 0) {
          return 0
        }
        listAdjustments.forEach((adjustment) => {
          totalAdjustmentPct += adjustment.value
        })
      }

      return totalAdjustmentPct
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/bulma-variables';
  .pricing--save-and-preview-button {
    transition: transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.75);
  }
  .pricing--save-and-preview-button:not(.is-disabled) {
    transform: scale(1.3);
  }
  .live-val {
    color: $grey;
    line-height: 1.3;
  }
  .draft-val {
    font-weight: 600;
    line-height: 1.3;
  }
  .nested--table {
    margin: $bleed * 2;
    border: 1px solid $grey-lighter;
  }
</style>
