<template>
  <div>
    <div class="list nav-sub has-background-white has-border-top has-shadow-bottom">
      <div class="list-content">
        <h4 class="title is-4">
          <router-link :to="{name:'manageFeatures'}"><i class="el-icon-back is-size-4" style="margin-right: 0.5rem;"></i></router-link>
          Features Management History
        </h4>
      </div>
    </div>
    <PpGap />
    <div class="section is-small">
      <div class="container is-tablet">
        <div class="box">
          <div class="list">
            <div class="list-content">
              <div class="list-label has-text-primary has-text-weight-semibold">
                <i class="el-icon-time" /> History
              </div>
            </div>
          </div>
          <div class="box">
            <div class="list is-small has-shadow-bottom"
                 v-for="it in featuresHistory.list"
                 :key="it.createdAt">
              <div class="list-content">
                <div class="list-label">{{ it.createdAt | date }}</div>
                <div class="list-value">
                  {{ it.type }} &nbsp;
                </div>
              </div>
              <div class="list-action">
                <el-popover
                  v-if="it.req"
                  placement="top-start"
                  trigger="click"
                >
                  <pre class="is-size-7">{{ it.req }}</pre>
                  <a class="" slot="reference">Payload</a>
                </el-popover>
              </div>
              <div class="list-action has-text-right" style="width: 100px;">
                <div class="list-label">By</div>
                <div class="list-value has-text-ellipsis">{{ it.by || '–' }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { featuresHistoryList } from '../../factories/manage'

export default {
  name: 'ManageFeaturesHistory',
  props: {
    query: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  created () {
    featuresHistoryList()
  },
  computed: {
    ...mapState(['featuresHistory'])
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
  @import '../../styles/bulma-variables';

</style>
