<template>
  <section class="section is-small">
    <div class="columns">
      <div class="column is-6 is-offset-3">
      <div class="list">
        <div class="list-content">
          <div class="list-label has-text-primary has-text-weight-semibold">
            <i class="el-icon-time"/> History
          </div>
        </div>
      </div>
      <div class="box">
        <div class="list is-small has-shadow-bottom"
          v-for="it in sort(history)"
          :key="it.createdAt">
          <div class="list-content">
            <div class="list-label">{{ it.createdAt | date }}</div>
            <div class="list-value">
              {{ mapCodeToText(it.type) }} &nbsp;
            </div>
          </div>
          <div class="list-action">
            <el-popover
              v-if="it.req"
              placement="top-start"
              trigger="click"
             >
             <pre class="is-size-7">{{ it.req }}</pre>
              <a class="" slot="reference">Details</a>
            </el-popover>
          </div>
          <div class="list-action has-text-right" style="width: 100px;" v-if="it.by">
            <div class="list-label">By</div>
            <div class="list-value has-text-ellipsis">{{ it.by || '–' }}</div>
          </div>
        </div>
      </div>
      </div>
    </div>
    <PpGap size="large" />
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ManageApplicationsDetailsLogs',
  props: {
    appCode: {
      type: String,
      required: true
    },
    query: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      history: state => {
        return state.appsDetails.details.history
      }
    })
  },
  methods: {
    sort (val) {
      if (!val) return
      const cloned = JSON.parse(JSON.stringify(val))
      return cloned.sort((a, b) => b.createdAt - a.createdAt)
    },
    mapCodeToText (code) {
      let result
      switch (code) {
        case 'CREATED':
          result = 'Application has been created'
          break
        case 'EDITED':
          result = 'Application has been edited'
          break
        case 'EDITED_CONFIG':
          result = 'Application config has been edited'
          break
        case 'EDITED_PRICING':
          result = 'Pricing has been edited'
          break
        case 'PUBLISHED_PRICING':
          result = 'New pricing config has been published'
          break
        case 'SAVED_PRICING':
          result = 'New pricing settings has been added to draft'
          break
        case 'ADDED_USER':
          result = 'A new user has been added'
          break
        case 'EDITED_USER':
          result = 'Application\'s user has been edited'
          break
        case 'DELETED_USER':
          result = 'Application\'s user has been removed'
          break
        default:
          result = code
          break
      }

      return result
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/bulma-variables';
  .autouser .el-select-dropdown__item {
    padding: $bleed / 2 $bleed;
    height: auto;
  }
</style>
