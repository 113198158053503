<template>
  <section class="section is-small">
    <div class="columns">
      <div class="column is-9 is-offset-1">
        <el-tabs tab-position="left">
          <el-tab-pane label="Env Params" v-if="checkPermission(['VIEW_APPLICATION_PARAMS'])">
            <div class="list">
              <div class="list-content">
                <div class="list-value has-text-primary has-text-weight-semibold">
                  Application Details
                </div>
              </div>
            </div>
            <el-form
              ref="localForm"
              @submit.native.prevent=""
              :model="localDetails">
              <div class="box" v-if="checkPermission(['VIEW_APPLICATION_PARAMS'])">
                <div class="list has-shadow-bottom  is-comfort">
                  <div class="list-content">
                    <div class="list-value has-text-weight-semibold">Application status</div>
                    <div class="list-label has-text-danger"
                      v-if="details.inactive">
                      All activities has been suppeneded for external users/customers. e.g. availabilty searching and make reservations at booking site, no access right for all associated external users; however others associated applications are not effected.
                  </div>
                  </div>
                  <div :class="['list-action is-uppercase is-flex']">
                    <InactiveFlag :inactive="localDetails.inactive" />
                    &nbsp;
                    <el-switch
                      v-model="localDetails.inactive"
                      @change="doDetailsSubmit({ inactive: localDetails.inactive })"
                      :active-value="false"
                      :inactive-value="true"
                      active-color="#15b77c"
                      inactive-color="#909399"/>
                  </div>
                </div>
              </div>
              <div class="box" v-if="checkPermission(['VIEW_APPLICATION_PARAMS'])">
                <div class="list is-comfort has-shadow-bottom">
                  <div class="list-content">
                    <div class="list-label">Label (Internal use)</div>
                      <el-form-item
                        prop="label"
                        :rules="[
                        { required: true, message: 'This is required.', trigger: ['blur', 'change', 'input']},
                        { min: 3, message: 'Min 3 characters', trigger: ['blur', 'change', 'input']}
                        ]">
                        <el-input
                          name="formAppLabel"
                          autocomplete="off"
                          size="medium"
                          placeholder="E.g: My App"
                          v-model.trim="localDetails.label">
                        </el-input>
                        <p class="form-help-text">How do we call this app?</p>
                      </el-form-item>
                  </div>
                  <div class="list-action">
                    <el-button type="primary"
                      size="medium"
                      :disabled="localDetails.label === details.label"
                      @click="doDetailsSubmit({ label: localDetails.label }, 'label')">SAVE</el-button>
                  </div>
                </div>
              </div>
              <div class="box is-stick">
                <div class="list is-comfort" v-if="checkPermission(['APPLICATION_STATUS'])">
                  <div class="list-content">
                    <div class="list-value has-text-weight-semibold">Availability search</div>
                    <div class="list-label">
                      Allow to check properites availability on booking site. (city/map search, property search and retrieve cancellation policy)
                  </div>
                  </div>
                  <div :class="['list-action is-uppercase is-flex']">
                    <BlockFlag :boo="localDetails.availabilitySearch" />
                    &nbsp;
                    <el-switch
                      v-model="localDetails.availabilitySearch"
                      @change="doDetailsSubmit({ availabilitySearch: localDetails.availabilitySearch })"
                      active-color="#15b77c"
                      inactive-color="#909399"/>
                  </div>
                </div>
                <div class="list is-comfort" v-if="checkPermission(['APPLICATION_STATUS'])">
                  <div class="list-content">
                    <div class="list-value has-text-weight-semibold">Room reservation</div>
                    <div class="list-label">
                      Allow to make bookings on booking site. <i>*Payment setup is required</i>
                  </div>
                  </div>
                  <div :class="['list-action is-uppercase is-flex']">
                    <BlockFlag :boo="localDetails.reservation" />
                    &nbsp;
                    <el-switch
                      v-model="localDetails.reservation"
                      @change="doDetailsSubmit({ reservation: localDetails.reservation })"
                      active-color="#15b77c"
                      inactive-color="#909399"/>
                  </div>
                </div>
                <div class="list has-shadow-bottom is-comfort" v-if="checkPermission(['VIEW_APPLICATION_PARAMS'])">
                  <div class="list-content">
                    <div class="list-value has-text-weight-semibold">Global preview mode</div>
                    <div class="list-label">
                      Use test credentials (hotel/payment) at all vistor to the booking site; This is useful for multiple parties testing however your customer will be effected. Otherwise we recomanded single preview mode.
                  </div>
                  </div>
                  <div :class="['list-action is-uppercase is-flex']">
                    <BlockFlag :boo="localDetails.globalPreview"
                      trueText="ON" falseText="DISABLED" />
                    &nbsp;
                    <el-switch
                      v-model="localDetails.globalPreview"
                      @change="doDetailsSubmit({ globalPreview: localDetails.globalPreview })"
                      active-color="#15b77c"
                      inactive-color="#909399"/>
                  </div>
                </div>
              </div>
            </el-form>
            <div class="list">
              <div class="list-content">
                <div class="list-value has-text-primary has-text-weight-semibold">
                  Application Params
                </div>
              </div>
              <div class="list-action">
                <el-radio-group v-model="env" size="small"
                  @change="getEnvConfig(env)">
                  <el-radio-button label="production"></el-radio-button>
                  <el-radio-button label="testing"></el-radio-button>
                </el-radio-group>
              </div>
            </div>
            <article class="box">
              <div class="list is-comfort">
                <div class="list-content">
                  <div class="list-label">Following config should only use in front-end only.</div>
                  <div class="list-value">
                    {{ testHost }}
                  </div>
                </div>
                <div class="list-action">
                  <i class="el-icon-loading" v-if="isLoading"/>
                  <div class="list-value" v-else>
                    <span v-if="synced"
                      class="has-text-success has-text-weight-semibold">
                      SYNCED
                    </span>
                    <span v-else
                      class="has-text-danger has-text-centered has-line-height-1-25 is-inline-block">
                      OUTDATED<br>
                      <span class="is-size-7">(deployment required)</span>
                    </span>
                  </div>
                  <el-button
                    type="primary"
                    size="small"
                    style="float:right"
                    slot="prepend"
                    icon="el-icon-document"
                    @click="handleCopy(envConfig,$event)">Copy</el-button>
                </div>
              </div>
              <div class="list">
                <div class="list-content">
                  <el-input
                    id="env-config"
                    readonly
                    size="mini"
                    type="textarea"
                    :autosize="{ minRows: 10 }"
                    placeholder="Please input"
                    v-model="envConfig">
                  </el-input>
                </div>
              </div>
              <div class="list is-narrow"></div>
            </article>
          </el-tab-pane>
          <el-tab-pane label="Email Templates" v-if="checkPermission(['MANAGE_TEMPLATES'])">
            <el-table
              :data="templates.mail"
              style="width: 100%">
              <el-table-column
                label="Name">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.name }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Type">
                <template slot-scope="scope">
                  <el-tag
                    :type="scope.row.type === 'mail' ? 'primary' : 'success'"
                    size="medium">{{ scope.row.type }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column
                label="Locale">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.language }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Update Date">
                <template slot-scope="scope">
                  <i class="el-icon-time"></i>
                  <span style="margin-left: 10px">{{ $root.$options.filters.date(scope.row.updatedAt) }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Actions"
                width="120">
                <template slot-scope="scope">
                  <el-button type="primary" size="mini" icon="el-icon-edit" circle @click="handleEdit(scope.$index, scope.row)"></el-button>
                  <el-button type="primary" size="mini" icon="el-icon-view" circle @click="handlePreview(scope.row.fileTemplate)"></el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="PDF Templates" v-if="checkPermission(['MANAGE_TEMPLATES'])">
            <el-table
              :data="templates.pdf"
              style="width: 100%">
              <el-table-column
                label="Name">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.name }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Type">
                <template slot-scope="scope">
                  <el-tag
                    :type="scope.row.type === 'mail' ? 'primary' : 'success'"
                    size="medium">{{ scope.row.type }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column
                label="Locale">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.language }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Update Date">
                <template slot-scope="scope">
                  <i class="el-icon-time"></i>
                  <span style="margin-left: 10px">{{ $root.$options.filters.date(scope.row.updatedAt) }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Actions"
                width="120">
                <template slot-scope="scope">
                  <el-button type="primary" size="mini" icon="el-icon-edit" circle @click="handleEdit(scope.$index, scope.row)"></el-button>
                  <el-button type="primary" size="mini" icon="el-icon-view" circle @click="handlePreview(scope.row.fileTemplate)"></el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="Fax Templates" v-if="checkPermission(['MANAGE_TEMPLATES'])">
            <el-table
              :data="templates.fax"
              style="width: 100%">
              <el-table-column
                label="Name">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.name }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Type">
                <template slot-scope="scope">
                  <el-tag
                    :type="scope.row.type === 'mail' ? 'primary' : 'success'"
                    size="medium">{{ scope.row.type }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column
                label="Locale">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{ scope.row.language }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Update Date">
                <template slot-scope="scope">
                  <i class="el-icon-time"></i>
                  <span style="margin-left: 10px">{{ $root.$options.filters.date(scope.row.updatedAt) }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Actions"
                width="120">
                <template slot-scope="scope">
                  <el-button type="primary" size="mini" icon="el-icon-edit" circle @click="handleEdit(scope.$index, scope.row)"></el-button>
                  <el-button type="primary" size="mini" icon="el-icon-view" circle @click="handlePreview(scope.row.fileTemplate)"></el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogHtmlPreviewVisible"
      fullscreen>
      <template slot="title" style="position:fixed">
        <h4>Copy & Paste your HTML template</h4>
      </template>
      <div>
        <el-row>
          <el-col :xs="24" :sm="24" :md="24" :lg="24">
            <codemirror
              v-model="currentTemplate.fileTemplate" :options="cmOptions"></codemirror>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24">
            <el-button
              style="margin-top:20px;float: right"
              type="primary"
              icon="el-icon-check"
              :loading="isLoading"
              @click="handleSave()"
              >Save</el-button>
            <el-button
              style="margin-top:20px;margin-right:10px;float: right"
              type="success"
              icon="el-icon-link"
              @click="handlePreview(currentTemplate.fileTemplate)"
              >Preview</el-button>
            <el-button
              style="margin-top:20px;float: right"
              type="warning"
              icon="el-icon-close"
              @click="dialogHtmlPreviewVisible = false;"
              >Close</el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
    <PpGap size="large" />
  </section>
</template>

<script>
import { applicationsDev, editTemplate, previewTemplate } from '../../factories/manage'
// require component
import { codemirror } from 'vue-codemirror'
// require styles
import 'codemirror/lib/codemirror.css'
// theme css
import 'codemirror/theme/base16-dark.css'
// language
import 'codemirror/mode/xml/xml.js'
// require active-line.js
import 'codemirror/addon/selection/active-line.js'
// autoCloseTags
import 'codemirror/addon/edit/closetag.js'
import checkPermission from '@/plugins/permission'

export default {
  components: {
    codemirror
  },
  name: 'ManageApplicationsDetailsDev',
  props: {
    appCode: {
      type: String,
      required: true
    },
    query: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isLoading: false,
      localDetails: {},
      env: 'production',
      envConfig: '',
      digest: '',
      synced: false,
      testHost: '',
      templates: {
        mail: [],
        pdf: []
      },
      currentTemplate: {},
      dialogHtmlPreviewVisible: false,
      cmOptions: {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        autoCloseTags: true,
        line: true,
        mode: 'text/html',
        theme: 'base16-dark',
        viewPortMargin: Infinity
      }
    }
  },
  computed: {
    details () {
      return this.$parent.details
    }
  },
  created () {
    this.getEnvConfig(this.env)
  },
  watch: {
    'details': {
      immediate: true,
      handler (nV) {
        this.localDetails = JSON.parse(JSON.stringify(nV))
      }
    }
  },
  methods: {
    checkPermission,
    doDetailsSubmit (val, field) {
      const body = {
        ...val,
        appCode: this.appCode
      }
      if (field == null) {
        this.$parent.postApplicationEdit(body)
          .then(() => this.$refs.localForm.clearValidate())
      } else {
        this.$refs.localForm.validateField(field, (error) => {
          if (!error) {
            this.$parent.postApplicationEdit(body)
              .then(() => this.$refs.localForm.clearValidate())
          }
        })
      }
    },
    getEnvConfig (env = 'production') {
      this.isLoading = true
      applicationsDev({ appCode: this.appCode, env })
        .then(({ envConfig, digest, synced, testHost, templates }) => {
          this.isLoading = false
          this.envConfig = envConfig
          this.digest = digest
          this.synced = synced
          this.testHost = testHost
          this.templates = templates
        }).catch((err) => {
          this.isLoading = false
          this.envConfig = ''
          this.digest = ''
          this.synced = false
          this.testHost = ''
          console.log(err)
        })
    },
    handleCopy (text, event) {
      let testingCodeToCopy = document.querySelector('#env-config')
      testingCodeToCopy.select()
      try {
        const successful = document.execCommand('copy')
        const msg = successful ? 'successful' : 'unsuccessful'
        this.$message({
          type: 'success',
          message: `Env was ${msg} copied to clipboard`
        })
      } catch (err) {
        alert('Oops, unable to copy')
      }
      /* unselect the range */
      window.getSelection().removeAllRanges()
    },
    handleEdit (index, row) {
      this.currentTemplate = row
      this.dialogHtmlPreviewVisible = true
    },
    handleSave () {
      this.isLoading = true
      editTemplate({
        appCode: this.currentTemplate.appCode,
        name: this.currentTemplate.name,
        fileTemplate: this.currentTemplate.fileTemplate,
        locale: this.currentTemplate.language,
        type: this.currentTemplate.type
      }).then(res => {
        this.isLoading = false
        this.dialogHtmlPreviewVisible = false
      })
    },
    handlePreview (fileTemplate) {
      previewTemplate({
        fileTemplate: fileTemplate
      }).then(res => {
        const newWindow = window.open()
        newWindow.document.write(res)
      })
    }
  }
}
</script>

<style lang="scss">
  @import '../../styles/bulma-variables';
  .CodeMirror {
    height: 550px !important;
  }
</style>
