<template>
  <div>
    <el-form :model="localForm" ref="localForm" style="border: 1px solid #dfdfdf; padding: 10px;">
      <div class="list is-narrow is-gapless">
        <div class="list-content">
          <div class="list-title has-text-weight-semibold">Live credential</div>
        </div>
      </div>
      <el-form-item style="width: 60%;" label="API key" prop="liveApiKey">
        <el-input
          name="liveApiKey"
          autocomplete="off"
          size="medium"
          placeholder="E.g: xxxxx-xxxxxx-xxxxx-xxxx-xx"
          v-model="localForm.live.apiKey"
        ></el-input>
      </el-form-item>
      <el-form-item style="width: 33%;" label="App ID" prop="liveAppId">
        <el-input
          style="width: 90px;"
          name="liveAppId"
          size="medium"
          autocomplete="off"
          placeholder="E.g: id"
          v-model="localForm.live.appId"
        ></el-input>
        <p class="form-help-text">For reference purpose.</p>
      </el-form-item>
      <div class="list is-narrow is-gapless">
        <div class="list-content">
          <div class="list-title has-text-weight-semibold">Test credential</div>
        </div>
      </div>
      <el-form-item style="width: 60%;" label="Test API key" prop="testApiKey">
        <el-input
          name="testApiKey"
          autocomplete="off"
          size="medium"
          placeholder="E.g: xxxxx-xxxxxx-xxxxx-xxxx-xx"
          v-model="localForm.test.apiKey"
        ></el-input>
      </el-form-item>
      <el-form-item style="width: 33%;" label="Test App ID" prop="testAppId">
        <el-input
          style="width: 90px;"
          name="testAppId"
          size="medium"
          autocomplete="off"
          placeholder="E.g: id"
          v-model="localForm.test.appId"
        ></el-input>
        <p class="form-help-text">For reference purpose.</p>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'V3CredentialForm',
  props: {
    value: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data () {
    return {
      localForm: {
        live: {},
        test: {}
      }
    }
  },
  mounted () {
    this.localForm = this.value
  },
  methods: {
    handleBlur () {
      this.$emit('input', { ...this.localForm })
    },
    validateForm () {
      return new Promise((resolve, reject) => {
        this.$refs.localForm.validate((valid, fields) => {
          resolve({
            valid,
            fields
          })
        })
      })
    }
  }
}
</script>
