<template>
  <section class="section is-small">
    <div class="">
      <div class="list is-gapless">
        <div class="list-content">
          <h4 class="title is-4">Users Management</h4>
        </div>
        <div class="list-action">
          <el-button
            v-if="checkPermission(['USERS_EDIT'])"
            type="success"
            icon="el-icon-plus"
            size="small"
            @click.prevent="showInviteNewUserForm()">
            INVITE NEW USER
          </el-button>
        </div>
      </div>
      <PpGap />
      <div class="box">
      <el-table
        class="users--table"
        :data="rows"
        stripe
        header-cell-class-name="users--table-th"
        style="width: 100%">
        <el-table-column
          label="#"
          type="index"
          width="40"
          align="center">
        </el-table-column>
        <el-table-column
          label="User"
          width="250">
          <template slot-scope="{ row }">
            <UserLabel v-bind="row" />
          </template>
        </el-table-column>
        <!-- <el-table-column
          class-name="has-text-ellipsis"
          prop="email"
          label="Email"
          width="250">
          <template slot-scope="{ row }">
            <router-link
              :to="{ name: 'manageUsersDetails', params: { uid: row.uid } }">
              {{ row.email || '–' }}
            </router-link>
          </template>
        </el-table-column> -->
        <el-table-column
          label="Role"
          width="150">
          <template slot-scope="scope">
            {{ scope.row['role.name'] }}
          </template>
        </el-table-column>
        <el-table-column
          label="Apps"
          min-width="200">
          <template slot-scope="scope">
            <div>
              <router-link
                v-for="it in removeNull(scope.row.apps)"
                :key="it && it.appCode"
                :to="{ name: 'manageApplicationsDetails', params: { appCode: it.appCode } }">
              <el-tag
                class="users--tag"
                :type="it.inactive ? 'info' : 'success'"
                size="mini">
                {{ it && it.label }}
              </el-tag>
              </router-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="inactive"
          label="Status"
          width="110"
          align="center">
          <template slot-scope="scope">
            <InactiveFlag :inactive="scope.row.inactive" />
          </template>
        </el-table-column>
        <el-table-column
          label="Invited at"
          width="170">
          <template slot-scope="scope">
            {{ scope.row.invitedAt | date }}
          </template>
        </el-table-column>
        <el-table-column
          label="Updated at"
          width="170">
          <template slot-scope="scope">
            {{ scope.row.updatedAt | date }}
          </template>
        </el-table-column>
        <el-table-column
          label="Actions"
          align="center"
          width="250"
          >
          <template slot-scope="{ row }">
            <div>
              <router-link
              :to="{ name: 'manageUsersDetails', params: { uid: row.uid } }">
              <el-button
                v-if="checkPermission(['USERS_EDIT'])"
                size="small"
                type="primary"
                style="margin-right: 10px"
                >Edit</el-button>
              </router-link>
              <el-button
                v-if="row.inactive"
                size="small"
                type="success"
                @click="() => resendInvitationEmail(row.email)"
                >Resend Confirmation</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      </div>
    </div>
    <el-dialog
      title="Invite new user" :visible.sync="inviteNewUserFormVisibility">
      <UserInvitationForm
        @close="closeInviteNewUserForm"
        v-if="inviteNewUserFormVisibility"
        v-bind="inviteNewUserForm" />
    </el-dialog>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { usersList, sendInvitationEmail } from '../../factories/manage'
import UserInvitationForm from '@/components/UserInvitationForm'
import UserLabel from '../../components/UserLabel'
import checkPermission from '@/plugins/permission'

export default {
  name: 'ManageUsers',
  components: {
    UserLabel,
    UserInvitationForm
  },
  props: {
    query: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      inviteNewUserFormVisibility: false,
      inviteNewUserForm: {
        appCode: null,
        email: ''
      }
    }
  },
  computed: {
    ...mapState({
      rows: state => state.users.rows,
      count: state => state.users.count
    })
  },
  created () {
    usersList()
  },
  methods: {
    checkPermission,
    removeNull (val) {
      if (val == null) return val
      return val.filter(ele => ele != null)
    },
    closeInviteNewUserForm () {
      this.inviteNewUserFormVisibility = false
      usersList()
    },
    showInviteNewUserForm () {
      this.inviteNewUserFormVisibility = true
    },
    handleRowClick (row, e, col) {
      this.$router.push({
        name: 'manageUsersDetails',
        params: { uid: row.uid }
      })
    },
    resendInvitationEmail (email) {
      this.$confirm('Are you sure want to resend invitation email?')
        .then(async () => {
          await sendInvitationEmail({ email })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/bulma-variables';
  .users--tag {
    margin: 0 $bleed;
  }
  .users--table /deep/ .is-hidden {
    display: table-cell !important;
  }
  // .users--table,
  // .users--table /deep/ tr {
  //   background-color: transparent;
  // }
  .users--table /deep/ .has-text-ellipsis .cell {
    white-space: nowrap;
  }
  .users--table /deep/ .users--table-th {
    border-color: $grey-light;
    background-color: $white-bis;
  }
</style>
