<template>
  <section class="">
    <div class="list has-background-white has-border-top has-shadow-bottom">
      <div class="list-pre">
        <HistoryLink
          :historyBack="true"
          label="back"
          :route="{ name: 'manageApplications' }">
          <i class="el-icon-back is-size-4" />
        </HistoryLink>
      </div>
      <div class="list-content">
        <h4 class="list-value is-size-5 has-text-weight-semibold">{{details.label}}</h4>
        <h4 class="list-label">
          App code: <span class="has-text-weight-semibold">{{details.appCode}}</span>
        </h4>
      </div>
      <div class="list-action">
        <div class="tabs is-right ">
          <ul>
            <router-link
              v-if="checkPermission(['APPLICATION_STATUS', 'APPLICATION_USERS_VIEW', 'APPLICATION_USERS_EDIT'])"
              replace
              tag="li" :to="{ name: 'manageApplicationsDetailsOverview', params: { appCode: this.appCode } }">
              <a>Overview</a>
            </router-link>
            <router-link
              v-if="checkPermission(['GENERAL_INFO', 'MAIL_SETTING', 'MARKETPLACE_SETTING', 'SITE_LOGO'])"
              replace
              tag="li" :to="{ name: 'manageApplicationsDetailsPreferences', params: { appCode: this.appCode } }">
              <a>Preferences</a>
            </router-link>
            <router-link
              v-if="mx_user.roleType === 'INTERNAL' && checkPermission(['STATIC_DATA_API', 'THIRD_PARTY', 'FAX_SETTING', 'MAIL_SETTING'])"
              replace
              tag="li" :to="{ name: 'manageApplicationsDetailsCredentials', params: { appCode: this.appCode } }">
              <a>Credentials</a>
            </router-link>
            <router-link
              v-if="checkPermission(['STRIPE_PAYMENT', 'TXROUTER_PAYMENT', 'ADYEN_PAYMENT', 'PCI_BOOKING_PAYMENT'])
                || (hasFeatures(['ENABLE_RECURRING_PAYMENT']) && checkPermission(['COMPANY_CREDIT_CARDS_VIEW', 'COMPANY_CREDIT_CARDS_EDIT']))"
              replace
              tag="li" :to="{ name: 'manageApplicationsDetailsPayments', params: { appCode: this.appCode } }">
              <a>Payments</a>
            </router-link>
            <router-link
              v-if="checkPermission(['PRICING_SETTING'])"
              replace
              tag="li" :to="{ name: 'manageApplicationsDetailsPricing', params: { appCode: this.appCode } }">
              <a>Pricing</a>
            </router-link>
            <router-link
              v-if="checkPermission(['APPLICATION_HISTORY'])"
              replace
              tag="li" :to="{ name: 'manageApplicationsDetailsLogs', params: { appCode: this.appCode } }">
              <a>History</a>
            </router-link>
            <router-link
              v-if="mx_user.roleType === 'INTERNAL' && checkPermission(['VIEW_APPLICATION_PARAMS', 'MANAGE_TEMPLATES'])"
              replace
              tag="li" :to="{ name: 'manageApplicationsDetailsDev', params: { appCode: this.appCode } }">
              <a>Dev</a>
            </router-link>
          </ul>
        </div>
      </div>
    </div>
    <!-- Child route -->
    <router-view :appCode="appCode" :query="query"></router-view>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import {
  applicationsOne,
  rolesList,
  applicationsEditUsers,
  applicationsEdit,
  applicationsEditConfigurations,
  usersList
} from '../../factories/manage'
import checkPermission from '@/plugins/permission'
import hasFeatures from '@/plugins/feature'

export default {
  name: 'ManageApplicationsDetails',
  props: {
    appCode: {
      type: String,
      required: true
    },
    query: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      details: state => state.appsDetails.details,
      users: state => state.users.rows
    }),
    ...mapGetters(['appRoles'])
  },
  watch: {
  },
  beforeRouteEnter (to, from, next) {
    if (checkPermission(['APPLICATION_USERS_VIEW', 'APPLICATION_USERS_EDIT'])) {
      rolesList()
    }
    if (checkPermission(['APPLICATION_USERS_VIEW'])) {
      usersList({ appCode: to.params.appCode })
    }
    applicationsOne({ appCode: to.params.appCode })
      .then(() => next())
      .catch((err) => {
        console.error(err)
        next()
      })
  },
  methods: {
    checkPermission,
    hasFeatures,
    removeNull (val) {
      if (val == null) return val
      return val.filter(ele => ele != null)
    },
    postApplicationEdit (val) {
      const body = {
        ...val,
        appCode: this.appCode
      }
      return applicationsEdit(body)
        .then(() => {
          return applicationsOne({ appCode: this.appCode })
        })
        .catch((err) => {
          console.error(err)
          throw err
        })
    },
    postApplicationEditConfigurations (val) {
      const body = {
        ...val,
        appCode: this.appCode
      }
      return applicationsEditConfigurations(body)
        .then(() => {
          return applicationsOne({ appCode: this.appCode })
        })
        .catch((err) => {
          console.error(err)
          throw err
        })
    },
    handleNewUser (body) {
      return applicationsEditUsers({
        inactive: false,
        appCode: this.appCode,
        ...body
      }).then(() => {
        return applicationsOne({ appCode: this.appCode })
      }).catch((err) => {
        console.error(err)
      })
    },
    handleUserRow (val, isDelete) {
      let body = isDelete
        ? { delete: true }
        : {
          inactive: val.apps[0].inactive,
          roleCode: val.apps[0].roleCode
        }
      applicationsEditUsers({
        ...body,
        appCode: this.appCode,
        uid: val.uid
      }).then(() => {
        return applicationsOne({ appCode: this.appCode })
      }).catch((err) => {
        console.error(err)
      })
    },
    sort (val) {
      const cloned = JSON.parse(JSON.stringify(val))
      return cloned.sort((a, b) => b.createdAt - a.createdAt)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/bulma-variables';
  .autouser .el-select-dropdown__item {
    padding: $bleed / 2 $bleed;
    height: auto;
  }
  .nested--table {
    margin: $bleed * 2;
    border: 1px solid $grey-lighter;
  }
</style>
