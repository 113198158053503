<template>
  <section class="section is-small">
    <div class="">
      <div class="list is-gapless">
        <div class="list-content">
          <h4 class="title is-4">Applications Management</h4>
        </div>
        <div class="list-action">
          <el-button
            v-if="mx_user.roleType === 'INTERNAL'"
            type="success"
            icon="el-icon-plus"
            size="small"
            @click.prevent="doShowNewAppForm">
            CREATE NEW APP
          </el-button>
        </div>
      </div>
      <PpGap />
      <div class="box">
      <el-table
        class="users--table"
        :data="rows"
        stripe
        header-cell-class-name="users--table-th"
        style="width: 100%">
        <el-table-column
          type="index"
          label="#"
          width="40"
          align="center">
        </el-table-column>
        <el-table-column
          label="label"
          prop="label"
          width="200">
          <template slot-scope="{ row }">
            <router-link class="has-text-weight-semibold" :to="{ name: 'manageApplicationsDetails', params: { appCode: row.appCode} }">
              {{ row.label }}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="appCode"
          label="App code"
          width="90"
          align="center">
        </el-table-column>
        <el-table-column
          prop="activeUsersCount"
          label="Users count"
          align="right"
          width="110">
        </el-table-column>
        <el-table-column
          prop="domainName"
          label="URL Site"
          width="300">
            <template slot-scope="{ row }">
              <el-link
                :href="row.domainName"
                :underline="false"
                target="_blank"
                >
                {{ row.domainName }}
              </el-link>
            </template>
        </el-table-column>
        <el-table-column
          prop="inactive"
          label="Status"
          width="110"
          align="center">
          <template slot-scope="{ row }">
            <InactiveFlag :inactive="row.inactive" />
          </template>
        </el-table-column>
        <el-table-column
          prop="availabilitySearch"
          label="Searchable"
          width="100"
          align="center">
          <template slot-scope="{ row }">
            <BlockFlag :boo="row.availabilitySearch" />
          </template>
        </el-table-column>
        <el-table-column
          prop="reservation"
          label="Make booking"
          width="110"
          align="center">
          <template slot-scope="{ row }">
            <BlockFlag :boo="row.reservation" />
          </template>
        </el-table-column>
        <el-table-column
          prop="globalPreview"
          label="Global preview"
          width="110"
          align="center">
          <template slot-scope="{ row }">
            <BlockFlag :boo="row.globalPreview"
                trueText="ON" falseText="DISABLED" />
          </template>
        </el-table-column>
        <el-table-column
          label="Updated at"
          width="170">
          <template slot-scope="scope">
            {{ scope.row.updatedAt | date }}
          </template>
        </el-table-column>
        <el-table-column
          label="Created at"
          width="170">
          <template slot-scope="scope">
            {{ scope.row.createdAt | date }}
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="Actions"
          align="center"
          width="100">
          <template slot-scope="{ row }">
            <div>
              <router-link
              :to="{ name: 'manageApplicationsDetails', params: { appCode: row.appCode } }">
              <el-button
                size="small"
                icon="el-icon-edit"
                type="text"
                >EDIT</el-button>
              </router-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
      </div>
    </div>
    <el-drawer
      title="New Application"
      :visible.sync="newAppFormVisibility"
      direction="rtl"
      size="50%"
      :wrapperClosable="false">
        <NewApplicationForm
          @close="handleNewAppFormClosed"
          v-if="newAppFormVisibility"
          :clients="clients"
          v-bind="newAppForm" />
    </el-drawer>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { applicationsList, clientList } from '../../factories/manage'
import NewApplicationForm from '../../components/NewApplicationForm'

export default {
  name: 'ManageApplications',
  components: {
    NewApplicationForm
  },
  props: {
    query: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      newAppFormVisibility: false,
      newAppForm: {}
    }
  },
  computed: {
    ...mapState({
      rows: state => state.apps.list,
      clients: state => state.clients.list
    })
  },
  created () {
    applicationsList()
    clientList()
  },
  methods: {
    removeNull (val) {
      if (val == null) return val
      return val.filter(ele => ele != null)
    },
    handleNewAppFormClosed () {
      this.newAppFormVisibility = false
      applicationsList()
    },
    doShowNewAppForm () {
      this.newAppFormVisibility = true
    },
    handleRowClick (row, e, col) {
      this.$router.push({
        name: 'manageUsersDetails',
        params: { uid: row.uid }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/bulma-variables';
  .users--tag {
    margin: 0 $bleed;
  }
  .users--table /deep/ .users--table-th {
    padding-bottom: $bleed;
    color: $grey;
    border-color: $grey-lighter;
    border-width: 2px;
    background-color: $white-bis;
  }
  .users--table /deep/ .is-hidden {
    display: table-cell !important;
  }
</style>
