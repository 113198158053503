<template>
  <section class="section is-small">
    <!-- Content -->
    <div class="columns">
      <div class="column is-8 is-offset-2">
        <el-tabs tab-position="left">
          <el-tab-pane label="Prepaid" v-if="checkPermission(['STRIPE_PAYMENT'])">
            <div class="list">
              <div class="list-content">
                <div class="list-title has-text-primary has-text-weight-semibold">Prepaid Payment Details</div>
              </div>
            </div>
            <div class="box">
              <div class="form-content">
                <el-form
                  ref="paymentForm"
                  inline
                  label-position="top"
                  @submit.native.prevent
                  :model="localDetails.config.more"
                >
                  <el-form-item label="Payment Type" prop="paymentType"
                    :rules="[
                      { required: true, message: 'Payment Type is required', trigger: [ 'change', 'blur' ] }
                    ]">
                    <el-select
                      style="width: 100%"
                      v-model="localDetails.config.more.paymentType"
                      placeholder="Please Select">
                      <el-option label="Stripe" value="stripe"></el-option>
                      <el-option label="Adyen" value="adyen"></el-option>
                      <el-option label="TxRouter" value="txrouter"></el-option>
                    </el-select>
                  </el-form-item>
                </el-form>

                <el-form
                  v-if="localDetails.config.more.paymentType === 'stripe' && checkPermission(['STRIPE_PAYMENT'])"
                  ref="stripeForm"
                  inline
                  label-position="top"
                  @submit.native.prevent
                  :model="localDetails.config.more.stripe"
                >
                  <el-form-item
                    style="width: 65%;"
                    label="Statement descriptor"
                    :rules="[
                      { required: true, message: 'This is required', trigger: ['blur', 'change', 'input'] },
                      { min: 3, max: 18, message: 'Length should be 3 to 18', trigger: ['blur', 'change', 'input'] }
                    ]"
                    prop="stripeStatementDescriptor"
                  >
                    <el-input
                      type="text"
                      size="medium"
                      name="stripeStatementDescriptor"
                      autocomplete="off"
                      placeholder="E.g: pk_xxxxxxxxxx"
                      v-model="localDetails.config.more.stripe.stripeStatementDescriptor"
                    ></el-input>
                    <p
                      class="form-help-text"
                    >How your customer identify your charges in their billing statement?</p>
                  </el-form-item>
                  <el-form-item
                    class="has-text-right"
                    style="width: 30%;"
                    label="Live mode"
                    prop="liveMode"
                  >
                    <BlockFlag
                      class="has-text-weight-semibold"
                      :boo="localDetails.config.more.stripe.liveMode"
                      falseText="OFF"
                    />&nbsp;
                    <el-switch
                      v-model="localDetails.config.more.stripe.liveMode"
                      active-color="#15b77c"
                      inactive-color="#909399"
                    />
                  </el-form-item>
                  <el-alert
                    v-if="localDetails.config.more.stripe.liveMode"
                    style="width: 90%"
                    title="You are in live mode, please make sure all credentials are properly configured"
                    :closable="false"
                    type="warning"
                    show-icon>
                  </el-alert>
                  <div class="list is-narrow is-gapless" v-if="localDetails.config.more.stripe.liveMode">
                    <div class="list-content">
                      <div class="list-title has-text-weight-semibold">Live credential</div>
                    </div>
                  </div>
                  <el-form-item style="width: 46%;" label="Public key" prop="stripeProdPk"
                    v-if="localDetails.config.more.stripe.liveMode">
                    <template slot="label">
                      Public key
                    </template>
                    <el-input
                      type="text"
                      size="medium"
                      name="stripeProdPk"
                      autocomplete="off"
                      placeholder="E.g: pk_xxxxxxxxxx"
                      v-model="localDetails.config.more.stripe.live.accessKey"
                    ></el-input>
                  </el-form-item>
                  <el-form-item style="width: 46%;" label="Secret key" prop="stripeProdSk"
                    v-if="localDetails.config.more.stripe.liveMode">
                    <el-input
                      name="stripeProdSk"
                      autocomplete="off"
                      size="medium"
                      placeholder="E.g: sk_xxxxxxxxxx"
                      v-model="localDetails.config.more.stripe.live.secretKey"
                    ></el-input>
                    <p class="form-help-text has-text-danger">Keep it safe. Do not release it in public.</p>
                  </el-form-item>
                  <!-- <div class="list is-gapless has-border-top is-narrow"></div> -->
                  <div class="list is-narrow is-gapless" v-if="!localDetails.config.more.stripe.liveMode">
                    <div class="list-content">
                      <div class="list-title has-text-weight-semibold">Test credential</div>
                    </div>
                  </div>
                  <el-form-item style="width: 46%;" label="Public key" prop="stripeTestPk"
                    v-if="!localDetails.config.more.stripe.liveMode">
                    <template slot="label">
                      Public key
                    </template>
                    <el-input
                      name="stripeTestPk"
                      autocomplete="off"
                      size="medium"
                      placeholder="E.g: pk_test_xxxxxxxxxx"
                      v-model="localDetails.config.more.stripe.sandbox.accessKey"
                    ></el-input>
                  </el-form-item>
                  <el-form-item style="width: 46%;" label="Secret key" prop="stripeTestSk"
                    v-if="!localDetails.config.more.stripe.liveMode">
                    <el-input
                      name="stripeTestSk"
                      autocomplete="off"
                      size="medium"
                      placeholder="E.g: sk_test_xxxxxxxxxx"
                      v-model="localDetails.config.more.stripe.sandbox.secretKey"
                    ></el-input>
                    <p class="form-help-text has-text-danger">Keep it safe. Do not release it in public.</p>
                  </el-form-item>
                  <div class="list is-gapless has-border-top is-narrow"></div>
                  <el-form-item style="width: 90%;" label="Webhook Secret" prop="stripeWebhookSec">
                    <el-input
                      name="stripeWebhookSec"
                      autocomplete="off"
                      size="medium"
                      placeholder="E.g: whsec__xxxxxxxxxx"
                      v-model="localDetails.config.more.stripe.whsec"
                    ></el-input>
                    <p class="form-help-text has-text-danger">Keep it safe. Do not release it in public.</p>
                  </el-form-item>
                  <el-form-item style="width: 90%;" label="Webhook URL">
                    <el-input
                      readonly
                      type="textarea"
                      autocomplete="off"
                      size="medium"
                      v-model="stripeWebhookUrl"
                    ></el-input>
                    <p class="form-help-text">Copy and paste this into your Stripe webhook setting</p>
                  </el-form-item>
                </el-form>

                <el-form
                  v-if="localDetails.config.more.paymentType === 'txrouter' && checkPermission(['TXROUTER_PAYMENT'])"
                  ref="txrouterForm"
                  inline
                  label-position="top"
                  @submit.native.prevent
                  :model="localDetails.config.more.txrouter"
                >
                  <el-form-item
                    class="has-text-right"
                    style="width: 90%;"
                    label="Live mode"
                    prop="txrouterLiveMode"
                  >
                    <BlockFlag
                      class="has-text-weight-semibold"
                      :boo="localDetails.config.more.txrouter.liveMode"
                      falseText="OFF"
                    />&nbsp;
                    <el-switch
                      v-model="localDetails.config.more.txrouter.liveMode"
                      active-color="#15b77c"
                      inactive-color="#909399"
                    />
                  </el-form-item>
                  <el-alert
                    v-if="localDetails.config.more.txrouter.liveMode"
                    style="width: 90%"
                    title="You are in live mode, please make sure all credentials are properly configured"
                    :closable="false"
                    type="warning"
                    show-icon>
                  </el-alert>
                  <div class="list is-narrow is-gapless" v-if="localDetails.config.more.txrouter.liveMode">
                    <div class="list-content">
                      <div class="list-title has-text-weight-semibold">Live credential</div>
                    </div>
                  </div>
                  <el-form-item style="width: 90%;" label="Payment Site URL" prop="txRouterLiveUrl"
                    v-if="localDetails.config.more.txrouter.liveMode">
                    <el-input
                      name="txRouterLiveUrl"
                      autocomplete="off"
                      size="medium"
                      placeholder="E.g: http://txrouter"
                      v-model="localDetails.config.more.txrouter.live.url"
                    ></el-input>
                    <p class="form-help-text">Payment page URL</p>
                  </el-form-item>
                  <el-form-item style="width: 90%;" label="Refund Payment URL" prop="txRouterLiveRefundUrl"
                    v-if="localDetails.config.more.txrouter.liveMode">
                    <el-input
                      name="txRouterLiveRefundUrl"
                      autocomplete="off"
                      size="medium"
                      placeholder="E.g: http://txrouter"
                      v-model="localDetails.config.more.txrouter.live.refundUrl"
                    ></el-input>
                    <p class="form-help-text">Refund Endpoind</p>
                  </el-form-item>
                  <el-form-item style="width: 46%;" label="Access key" prop="txrouterAccessKeyLive"
                    v-if="localDetails.config.more.txrouter.liveMode">
                    <template slot="label">
                      Access Key / Merchant ID
                    </template>
                    <el-input
                      type="text"
                      size="medium"
                      name="txrouterAccessKeyLive"
                      autocomplete="off"
                      placeholder="E.g: xxxxxxxx"
                      v-model="localDetails.config.more.txrouter.live.accessKey"
                    ></el-input>
                    <p class="form-help-text has-text-danger">Keep it safe. Do not release it in public.</p>
                  </el-form-item>
                  <el-form-item style="width: 46%;" label="Secret key" prop="txrouterSecretKeyLive"
                    v-if="localDetails.config.more.txrouter.liveMode">
                    <el-input
                      name="txrouterSecretKeyLive"
                      autocomplete="off"
                      size="medium"
                      placeholder="E.g: xxxxxxxx"
                      v-model="localDetails.config.more.txrouter.live.secretKey"
                    ></el-input>
                    <p class="form-help-text has-text-danger">Keep it safe. Do not release it in public.</p>
                  </el-form-item>
                  <!-- <div class="list is-gapless has-border-top is-narrow"></div> -->
                  <div class="list is-narrow is-gapless" v-if="!localDetails.config.more.txrouter.liveMode">
                    <div class="list-content">
                      <div class="list-title has-text-weight-semibold">Test credential</div>
                    </div>
                  </div>
                  <el-form-item style="width: 90%;" label="Payment Site URL" prop="txRouterTestUrl"
                    v-if="!localDetails.config.more.txrouter.liveMode">
                    <el-input
                      name="txRouterTestUrl"
                      autocomplete="off"
                      size="medium"
                      placeholder="E.g: http://txrouter"
                      v-model="localDetails.config.more.txrouter.sandbox.url"
                    ></el-input>
                    <p class="form-help-text">Payment page URL</p>
                  </el-form-item>
                  <el-form-item style="width: 90%;" label="Refund Payment URL" prop="txRouterSandboxRefundUrl"
                    v-if="!localDetails.config.more.txrouter.liveMode">
                    <el-input
                      name="txRouterSandboxRefundUrl"
                      autocomplete="off"
                      size="medium"
                      placeholder="E.g: http://txrouter"
                      v-model="localDetails.config.more.txrouter.sandbox.refundUrl"
                    ></el-input>
                    <p class="form-help-text">Refund Endpoind</p>
                  </el-form-item>
                  <el-form-item style="width: 46%;" label="Public key" prop="txrouterAccessKeySandbox"
                    v-if="!localDetails.config.more.txrouter.liveMode">
                    <template slot="label">
                      Access key / Merchant ID
                    </template>
                    <el-input
                      name="txrouterAccessKeySandbox"
                      autocomplete="off"
                      size="medium"
                      placeholder="E.g: xxxxxxxx"
                      v-model="localDetails.config.more.txrouter.sandbox.accessKey"
                    ></el-input>
                    <p class="form-help-text has-text-danger">Keep it safe. Do not release it in public.</p>
                  </el-form-item>
                  <el-form-item style="width: 46%;" label="Secret key" prop="txrouterSecretKeySandbox"
                    v-if="!localDetails.config.more.txrouter.liveMode">
                    <el-input
                      name="txrouterSecretKeySandbox"
                      autocomplete="off"
                      size="medium"
                      placeholder="E.g: xxxxxxxx"
                      v-model="localDetails.config.more.txrouter.sandbox.secretKey"
                    ></el-input>
                    <p class="form-help-text has-text-danger">Keep it safe. Do not release it in public.</p>
                  </el-form-item>
                </el-form>

                <el-form
                  v-if="checkPermission(['ADYEN_PAYMENT']) && localDetails.config.more.paymentType === 'adyen'"
                  ref="adyenForm"
                  inline
                  label-position="top"
                  @submit.native.prevent
                  :model="localDetails.config.more.adyen"
                >
                  <el-form-item
                    class="has-text-right"
                    label="Live mode"
                    prop="liveMode"
                  >
                    <BlockFlag
                      class="has-text-weight-semibold"
                      :boo="localDetails.config.more.adyen.liveMode"
                      falseText="OFF"
                    />&nbsp;
                    <el-switch
                      v-model="localDetails.config.more.adyen.liveMode"
                      active-color="#15b77c"
                      inactive-color="#909399"
                    />
                  </el-form-item>
                  <el-alert
                    v-if="localDetails.config.more.adyen.liveMode"
                    style="width: 90%"
                    title="You are in live mode, please make sure all credentials are properly configured"
                    :closable="false"
                    type="warning"
                    show-icon>
                  </el-alert>
                  <div class="list is-narrow is-gapless" v-if="localDetails.config.more.adyen.liveMode">
                    <div class="list-content">
                      <div class="list-title has-text-weight-semibold">Live credential</div>
                    </div>
                  </div>
                  <el-form-item style="width: 46%;" label="Merchant account" prop="adyenProdPk"
                    v-if="localDetails.config.more.adyen.liveMode">
                    <template slot="label">
                      Merchant Account
                    </template>
                    <el-input
                      type="text"
                      size="medium"
                      name="adyenProdPk"
                      autocomplete="off"
                      v-model="localDetails.config.more.adyen.live.accessKey"
                    ></el-input>
                  </el-form-item>
                  <el-form-item style="width: 46%;" label="API key" prop="adyenProdSk"
                    v-if="localDetails.config.more.adyen.liveMode">
                    <el-input
                      name="stripeProdSk"
                      autocomplete="off"
                      size="medium"
                      v-model="localDetails.config.more.adyen.live.secretKey"
                    ></el-input>
                    <p class="form-help-text has-text-danger">Keep it safe. Do not release it in public.</p>
                  </el-form-item>
                  <el-form-item v-if="localDetails.config.more.adyen.liveMode" style="width: 90%;" label="HMAC Signature" prop="adyenProdSignature">
                    <el-input
                      name="adyenProdSignature"
                      autocomplete="off"
                      size="medium"
                      v-model="localDetails.config.more.adyen.live.hmacSignature"
                    ></el-input>
                    <p class="form-help-text has-text-danger">Keep it safe. Do not release it in public.</p>
                  </el-form-item>
                  <el-form-item v-if="localDetails.config.more.adyen.liveMode" style="width: 90%;" label="Origin Key" prop="adyenProdOriginKey">
                    <el-input
                      name="adyenProdOriginKey"
                      autocomplete="off"
                      size="medium"
                      v-model="localDetails.config.more.adyen.live.originKey"
                    ></el-input>
                  </el-form-item>
                  <el-form-item v-if="localDetails.config.more.adyen.liveMode" style="width: 90%;" label="Prefix URL" prop="adyenPrefixUrl">
                    <el-input
                      name="adyenPrefixUrl"
                      autocomplete="off"
                      size="medium"
                      v-model="localDetails.config.more.adyen.prefixUrl"
                    ></el-input>
                  </el-form-item>
                  <!-- Adyen test env fields -->
                  <div class="list is-narrow is-gapless" v-if="!localDetails.config.more.adyen.liveMode">
                    <div class="list-content">
                      <div class="list-title has-text-weight-semibold">Test credential</div>
                    </div>
                  </div>
                  <el-form-item style="width: 46%;" label="Merchant account" prop="adyenTestPk"
                    v-if="!localDetails.config.more.adyen.liveMode">
                    <template slot="label">
                      Merchant Account
                    </template>
                    <el-input
                      name="adyenTestPk"
                      autocomplete="off"
                      size="medium"
                      placeholder="E.g: pk_test_xxxxxxxxxx"
                      v-model="localDetails.config.more.adyen.sandbox.accessKey"
                    ></el-input>
                  </el-form-item>
                  <el-form-item style="width: 46%;" label="Secret key" prop="adyenTestSk"
                    v-if="!localDetails.config.more.adyen.liveMode">
                    <el-input
                      name="adyenTestSk"
                      autocomplete="off"
                      size="medium"
                      placeholder="E.g: sk_test_xxxxxxxxxx"
                      v-model="localDetails.config.more.adyen.sandbox.secretKey"
                    ></el-input>
                    <p class="form-help-text has-text-danger">Keep it safe. Do not release it in public.</p>
                  </el-form-item>
                  <el-form-item v-if="!localDetails.config.more.adyen.liveMode" style="width: 90%;" label="HMAC Signature" prop="adyenTestSignature">
                    <el-input
                      name="adyenTestSignature"
                      autocomplete="off"
                      size="medium"
                      placeholder="Webhook HMAC Signature"
                      v-model="localDetails.config.more.adyen.sandbox.hmacSignature"
                    ></el-input>
                    <p class="form-help-text has-text-danger">Keep it safe. Do not release it in public.</p>
                  </el-form-item>
                  <el-form-item v-if="!localDetails.config.more.adyen.liveMode" style="width: 90%;" label="Origin Key" prop="adyenTestOriginKey">
                    <el-input
                      name="adyenTestOriginKey"
                      autocomplete="off"
                      size="medium"
                      v-model="localDetails.config.more.adyen.sandbox.originKey"
                    ></el-input>
                  </el-form-item>
                  <div class="list is-gapless has-border-top is-narrow"></div>
                  <el-form-item style="width: 90%;" label="Webhook URL">
                    <el-input
                      readonly
                      type="textarea"
                      autocomplete="off"
                      size="medium"
                      v-model="adyenWebhookUrl"
                    ></el-input>
                    <p class="form-help-text">Copy and paste this into your Adyen webhook setting</p>
                  </el-form-item>
                </el-form>
              </div>
              <div class="list is-comfort has-border-top">
                <div class="list-content"></div>
                <div class="list-action">
                  <el-button
                    type="text"
                    :disabled="disabledPrepaidForm"
                    @click="resetPrepaidForm"
                  >RESET</el-button>&nbsp;
                  <el-button
                    type="success"
                    size="medium"
                    :disabled="disabledPrepaidForm"
                    @click="savePrepaidForm"
                  >SAVE</el-button>
                </div>
              </div>
            </div>
          </el-tab-pane>

          <el-tab-pane label="Postpaid" v-if="hasFeatures(['ENABLE_PAYMENT_GATEWAY_POSTPAID']) && checkPermission(['PCI_BOOKING_PAYMENT'])">
            <!-- PCI Booking payment form -->
            <div class="list">
              <div class="list-content">
                <div class="list-title has-text-primary has-text-weight-semibold">PCI Booking Details</div>
              </div>
            </div>
            <div class="box">
              <div class="form-content">
                <el-form
                  ref="pciBookingForm"
                  inline
                  label-position="top"
                  @submit.native.prevent
                  :model="localDetails.config.more.pay_at_hotel"
                >
                  <el-form-item
                    class="has-text-right"
                    label="Live mode"
                    prop="liveMode"
                  >
                    <BlockFlag
                      class="has-text-weight-semibold"
                      :boo="localDetails.config.more.pay_at_hotel.liveMode"
                      falseText="OFF"
                    />&nbsp;
                    <el-switch
                      v-model="localDetails.config.more.pay_at_hotel.liveMode"
                      active-color="#15b77c"
                      inactive-color="#909399"
                    />
                  </el-form-item>
                  <el-alert
                    v-if="localDetails.config.more.pay_at_hotel.liveMode"
                    style="width: 90%"
                    title="You are in live mode, please make sure all credentials are properly configured"
                    :closable="false"
                    type="warning"
                    show-icon>
                  </el-alert>
                  <div class="list is-narrow is-gapless" v-if="localDetails.config.more.pay_at_hotel.liveMode">
                    <div class="list-content">
                      <div class="list-title has-text-weight-semibold">Live credential</div>
                    </div>
                  </div>
                  <el-form-item style="width: 46%;" label="Access Key" prop="pciProdPk"
                    v-if="localDetails.config.more.pay_at_hotel.liveMode">
                    <template slot="label">
                      Access Key
                    </template>
                    <el-input
                      type="text"
                      size="medium"
                      name="adyenProdPk"
                      autocomplete="off"
                      v-model="localDetails.config.more.pay_at_hotel.live.accessKey"
                    ></el-input>
                  </el-form-item>
                  <el-form-item style="width: 46%;" label="Secret key" prop="pciProdSk"
                    v-if="localDetails.config.more.pay_at_hotel.liveMode">
                    <el-input
                      name="pciProdSk"
                      autocomplete="off"
                      size="medium"
                      v-model="localDetails.config.more.pay_at_hotel.live.secretKey"
                    ></el-input>
                    <p class="form-help-text has-text-danger">Keep it safe. Do not release it in public.</p>
                  </el-form-item>
                  <el-form-item v-if="localDetails.config.more.pay_at_hotel.liveMode" style="width: 90%;" label="URL" prop="url">
                    <el-input
                      name="pciUrl"
                      autocomplete="off"
                      size="medium"
                      v-model="localDetails.config.more.pay_at_hotel.live.url"
                    ></el-input>
                    <p class="form-help-text has-text-danger">Keep it safe. Do not release it in public.</p>
                  </el-form-item>
                  <!-- PCI Booking test env fields -->
                  <div class="list is-narrow is-gapless" v-if="!localDetails.config.more.pay_at_hotel.liveMode">
                    <div class="list-content">
                      <div class="list-title has-text-weight-semibold">Test credential</div>
                    </div>
                  </div>
                  <el-form-item style="width: 46%;" label="Access Key" prop="adyenTestPk"
                    v-if="!localDetails.config.more.pay_at_hotel.liveMode">
                    <template slot="label">
                      Access Key
                    </template>
                    <el-input
                      name="adyenTestPk"
                      autocomplete="off"
                      size="medium"
                      placeholder="E.g: pk_test_xxxxxxxxxx"
                      v-model="localDetails.config.more.pay_at_hotel.sandbox.accessKey"
                    ></el-input>
                  </el-form-item>
                  <el-form-item style="width: 46%;" label="Secret key" prop="adyenTestSk"
                    v-if="!localDetails.config.more.pay_at_hotel.liveMode">
                    <el-input
                      name="adyenTestSk"
                      autocomplete="off"
                      size="medium"
                      placeholder="E.g: sk_test_xxxxxxxxxx"
                      v-model="localDetails.config.more.pay_at_hotel.sandbox.secretKey"
                    ></el-input>
                    <p class="form-help-text has-text-danger">Keep it safe. Do not release it in public.</p>
                  </el-form-item>
                  <el-form-item v-if="!localDetails.config.more.pay_at_hotel.liveMode" style="width: 90%;" label="URL" prop="adyenTestOriginKey">
                    <el-input
                      name="adyenTestOriginKey"
                      autocomplete="off"
                      size="medium"
                      v-model="localDetails.config.more.pay_at_hotel.sandbox.url"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </div>
              <div class="list is-comfort has-border-top">
                <div class="list-content"></div>
                <div class="list-action">
                  <el-button
                    type="text"
                    :disabled="pciBookingFormNew"
                    @click="$refs.pciBookingForm.resetFields()"
                  >RESET</el-button>&nbsp;
                  <el-button
                    type="success"
                    size="medium"
                    :disabled="pciBookingFormNew"
                    @click="pciBookingForm({
                      pay_at_hotel: localDetails.config.more.pay_at_hotel
                    })"
                  >SAVE</el-button>
                </div>
              </div>
            </div>
          </el-tab-pane>

          <el-tab-pane label="Cards" v-if="hasFeatures(['ENABLE_RECURRING_PAYMENT']) && checkPermission(['COMPANY_CREDIT_CARDS_VIEW', 'COMPANY_CREDIT_CARDS_EDIT'])">
            <div class="list">
              <div class="list-content">
                <div class="list-title has-text-primary has-text-weight-semibold">Company Credit Cards</div>
              </div>
            </div>
            <article class="box">
              <div class="form-content">
                <div class="list is-comfort" style="margin-bottom: 20px" v-if="checkPermission(['COMPANY_CREDIT_CARDS_EDIT'])">
                  <div class="list-content">
                    <div class="list-value has-text-weight-semibold">Recurring Payment</div>
                    <div class="list-label">
                        - Allow Client Admin to manage company credit card and use later on checkout page
                        <br>
                        - Allow Users to save their credit cards and manage their credit cards on My Account Page
                    </div>
                  </div>
                  <div :class="['list-action is-uppercase is-flex']">
                    <BlockFlag
                      :boo="localDetails.config.more.recurringPayment"
                      falseText="Disabled" />
                    &nbsp;
                    <el-switch
                      v-model="localDetails.config.more.recurringPayment"
                      @change="doAllowRecurringPayment(localDetails.config.more.recurringPayment)"
                      active-color="#15b77c"
                      inactive-color="#909399"/>
                  </div>
                </div>
                <div v-show="localDetails.config.more.recurringPayment">
                  <el-tabs v-model="activeName">
                    <!-- STRIPE -->
                    <el-tab-pane label="Stripe" name="stripe" v-if="localDetails.config.more.paymentType === 'stripe'">
                      <PaymentMethodStripe :appCode="appCode" :stripePk="stripePk" />
                    </el-tab-pane>
                    <!-- PCI BOOKING -->
                    <el-tab-pane label="PCI Booking" name="pcibooking" v-if="hasFeatures(['ENABLE_PAYMENT_GATEWAY_POSTPAID'])">
                      <PaymentMethodPCIBooking :appCode="appCode" :appAccessKey="localDetails.appAccessKey" />
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </article>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <PpGap size="large" />
  </section>
</template>

<script>
import currencies from '../../plugins/currencies'
import countries from '../../plugins/countries'
import PaymentMethodStripe from '@/views/manage/PaymentMethodStripe'
import PaymentMethodPCIBooking from '@/views/manage/PaymentMethodPCIBooking'
import checkPermission from '@/plugins/permission'
import hasFeatures from '@/plugins/feature'

export default {
  name: 'ManageApplicationsDetailsCredentials',
  components: {
    PaymentMethodPCIBooking,
    PaymentMethodStripe
  },
  props: {
    appCode: {
      type: String,
      required: true
    },
    query: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      debounceTimer: undefined,
      localDetails: {},
      loadingNewApp: false,
      currencies,
      countries,
      selectedRow: {},
      deleteCardConfirmationVisibility: false,
      isFetching: false,
      activeName: 'stripe'
    }
  },
  computed: {
    stripePk () {
      return this.localDetails.config.more.stripe.liveMode ? this.localDetails.config.more.stripe.live.accessKey
        : this.localDetails.config.more.stripe.sandbox.accessKey
    },
    stripeClientSecret () {
      return this.$parent.stripeClientSecret
    },
    creditCards () {
      return this.$parent.creditCards
    },
    pciBookingInfo () {
      return this.$parent.pciBookingInfo
    },
    details () {
      return this.$parent.details
    },
    stripeWebhookUrl () {
      return `${process.env.VUE_APP_LV2_API_HOST}/web/callback/stripe/${this.details.appAccessKey}`
    },
    adyenWebhookUrl () {
      return `${process.env.VUE_APP_LV2_API_HOST}/web/callback/adyen-notification/${this.details.appAccessKey}?${this.localDetails.config.more.adyen.liveMode ? 'previewMode=false' : 'previewMode=true'}`
    },
    disabledPrepaidForm () {
      if (this.localDetails.config.more.paymentType === 'stripe') {
        return this.stripeFormNew()
      } else if (this.localDetails.config.more.paymentType === 'adyen') {
        return this.adyenFormNew()
      } else if (this.localDetails.config.more.paymentType === 'txrouter') {
        return this.txrouterFormNew()
      }
      return null
    },
    fbFormNew () {
      return (
        this.localDetails.config.more.facebookApp.enabled ===
          this.details.config.more.facebookApp.enabled &&
        this.localDetails.config.more.facebookApp.clientSecret ===
          this.details.config.more.facebookApp.clientSecret &&
        this.localDetails.config.more.facebookApp.clientId ===
          this.details.config.more.facebookApp.clientId
      )
    },
    gooleMapFormNew () {
      return (
        (this.localDetails.config.more.googleMap &&
          this.localDetails.config.more.googleMap.apiKey) ===
        (this.details.config.more.googleMap &&
          this.details.config.more.googleMap.apiKey)
      )
    },
    pciBookingFormNew () {
      return (
        this.localDetails.config.more.pay_at_hotel.liveMode ===
          this.details.config.more.pay_at_hotel.liveMode &&
        this.localDetails.config.more.pay_at_hotel.live.accessKey ===
          this.details.config.more.pay_at_hotel.live.accessKey &&
        this.localDetails.config.more.pay_at_hotel.live.secretKey ===
          this.details.config.more.pay_at_hotel.live.secretKey &&
        this.localDetails.config.more.pay_at_hotel.live.url ===
          this.details.config.more.pay_at_hotel.live.url &&
        this.localDetails.config.more.pay_at_hotel.sandbox.accessKey ===
          this.details.config.more.pay_at_hotel.sandbox.accessKey &&
        this.localDetails.config.more.pay_at_hotel.sandbox.secretKey ===
          this.details.config.more.pay_at_hotel.sandbox.secretKey &&
        this.localDetails.config.more.pay_at_hotel.sandbox.url ===
          this.details.config.more.pay_at_hotel.sandbox.url
      )
    }
  },
  watch: {
    details: {
      immediate: true,
      handler (nV) {
        this.localDetails = JSON.parse(JSON.stringify(nV))
        this.localDetails.config.more = {
          stripe: {},
          adyen: {},
          googleMap: {},
          ...this.localDetails.config.more
        }
      }
    }
  },
  methods: {
    checkPermission,
    hasFeatures,
    resetPrepaidForm () {
      if (this.localDetails.config.more.paymentType === 'stripe') {
        this.$refs.stripeForm.resetFields()
      } else if (this.localDetails.config.more.paymentType === 'adyen') {
        this.$refs.adyenForm.resetFields()
      } else if (this.localDetails.config.more.paymentType === 'txrouter') {
        this.$refs.txrouterForm.resetFields()
      }
    },
    stripeFormNew () {
      return (
        this.localDetails.config.more.paymentType ===
          this.details.config.more.paymentType &&
        this.localDetails.config.more.stripe.stripeStatementDescriptor ===
          this.details.config.more.stripe.stripeStatementDescriptor &&
        this.localDetails.config.more.stripe.liveMode ===
          this.details.config.more.stripe.liveMode &&
        this.localDetails.config.more.stripe.live.accessKey ===
          this.details.config.more.stripe.live.accessKey &&
        this.localDetails.config.more.stripe.live.secretKey ===
          this.details.config.more.stripe.live.secretKey &&
        this.localDetails.config.more.stripe.sandbox.accessKey ===
          this.details.config.more.stripe.sandbox.accessKey &&
        this.localDetails.config.more.stripe.sandbox.secretKey ===
          this.details.config.more.stripe.sandbox.secretKey &&
        (this.localDetails.config.more.stripe &&
          this.localDetails.config.more.stripe.whsec) ===
          (this.details.config.more.stripe &&
            this.details.config.more.stripe.whsec)
      )
    },
    adyenFormNew () {
      return (
        this.localDetails.config.more.paymentType ===
          this.details.config.more.paymentType &&
        this.localDetails.config.more.adyen.liveMode ===
          this.details.config.more.adyen.liveMode &&
        this.localDetails.config.more.adyen.live.accessKey ===
          this.details.config.more.adyen.live.accessKey &&
        this.localDetails.config.more.adyen.live.secretKey ===
          this.details.config.more.adyen.live.secretKey &&
        this.localDetails.config.more.adyen.live.hmacSignature ===
          this.details.config.more.adyen.live.hmacSignature &&
        this.localDetails.config.more.adyen.sandbox.accessKey ===
          this.details.config.more.adyen.sandbox.accessKey &&
        this.localDetails.config.more.adyen.sandbox.secretKey ===
          this.details.config.more.adyen.sandbox.secretKey &&
        this.localDetails.config.more.adyen.sandbox.hmacSignature ===
          this.details.config.more.adyen.sandbox.hmacSignature &&
        (this.localDetails.config.more.adyen &&
          this.localDetails.config.more.adyen.prefixUrl) ===
          (this.details.config.more.adyen &&
            this.details.config.more.adyen.prefixUrl)
      )
    },
    txrouterFormNew () {
      return (
        this.localDetails.config.more.paymentType ===
          this.details.config.more.paymentType &&
        this.localDetails.config.more.txrouter.liveMode ===
          this.details.config.more.txrouter.liveMode &&
        this.localDetails.config.more.txrouter.sandbox.accessKey ===
          this.details.config.more.txrouter.sandbox.accessKey &&
        this.localDetails.config.more.txrouter.sandbox.secretKey ===
          this.details.config.more.txrouter.sandbox.secretKey &&
        this.localDetails.config.more.txrouter.live.accessKey ===
          this.details.config.more.txrouter.live.accessKey &&
        this.localDetails.config.more.txrouter.live.secretKey ===
          this.details.config.more.txrouter.live.secretKey &&
        this.localDetails.config.more.txrouter.live.url ===
          this.details.config.more.txrouter.live.url &&
        this.localDetails.config.more.txrouter.sandbox.url ===
          this.details.config.more.txrouter.sandbox.url &&
        this.localDetails.config.more.txrouter.live.refundUrl ===
          this.details.config.more.txrouter.live.refundUrl &&
        this.localDetails.config.more.txrouter.sandbox.refundUrl ===
          this.details.config.more.txrouter.sandbox.refundUrl
      )
    },
    savePrepaidForm () {
      this.$refs.paymentForm.validate(valid => {
        if (valid) {
          if (this.localDetails.config.more.paymentType === 'stripe') {
            this.stripeForm({
              paymentType: this.localDetails.config.more.paymentType,
              stripe: this.localDetails.config.more.stripe
            })
          } else if (this.localDetails.config.more.paymentType === 'adyen') {
            this.adyenForm({
              paymentType: this.localDetails.config.more.paymentType,
              adyen: this.localDetails.config.more.adyen
            })
          } else if (this.localDetails.config.more.paymentType === 'txrouter') {
            this.submitTxRouterForm({
              paymentType: this.localDetails.config.more.paymentType,
              txrouter: this.localDetails.config.more.txrouter
            })
          }
        }
      })
    },
    stripeForm (val) {
      const body = {
        more: {
          ...val
        },
        appCode: this.appCode
      }
      this.$refs.stripeForm.validate(valid => {
        if (valid) {
          this.$parent
            .postApplicationEditConfigurations(body)
            .then(() => this.$refs.stripeForm.clearValidate())
        }
      })
    },
    adyenForm (val) {
      const body = {
        more: {
          ...val
        },
        appCode: this.appCode
      }
      this.$refs.adyenForm.validate(valid => {
        if (valid) {
          this.$parent
            .postApplicationEditConfigurations(body)
            .then(() => this.$refs.adyenForm.clearValidate())
        }
      })
    },
    submitTxRouterForm (val) {
      const body = {
        more: {
          ...val
        },
        appCode: this.appCode
      }
      this.$refs.txrouterForm.validate(valid => {
        if (valid) {
          this.$parent
            .postApplicationEditConfigurations(body)
            .then(() => this.$refs.txrouterForm.clearValidate())
        }
      })
    },
    pciBookingForm (val) {
      const body = {
        more: {
          ...val
        },
        appCode: this.appCode
      }
      this.$refs.pciBookingForm.validate(valid => {
        if (valid) {
          this.$parent
            .postApplicationEditConfigurations(body)
            .then(() => this.$refs.pciBookingForm.clearValidate())
        }
      })
    },
    saveV3 (val) {
      const body = {
        ...val,
        appCode: this.appCode
      }
      this.$refs.v3Form.validate(valid => {
        if (valid) {
          // validate v3Keys value
          const specificKeys = val.v3Keys.specificKeys
          const testKeys = specificKeys.map(key => key.test)
          const liveKeys = specificKeys.map(key => key.live)
          if (val.v3Keys.specificMode && (!testKeys.filter(t => t.appId && t.apiKey).length || !liveKeys.filter(t => t.appId && t.apiKey).length)) {
            this.$message.error('Invalid additional keys form.')
            return
          }
          this.$parent
            .postApplicationEditConfigurations(body)
            .then(() => this.$refs.v3Form.clearValidate())
        }
      })
    },
    resetV3Form () {
      this.$refs.v3Form.resetFields()
      this.localDetails.config.v3Keys = this.details.config.v3Keys
    },
    handleAddSpecificKey () {
      this.localDetails.config.v3Keys.specificKeys.push({ live: {}, test: {} })
    },
    submitGoogleMapForm (val) {
      const body = {
        more: {
          googleMap: val
        },
        appCode: this.appCode
      }
      this.$refs.gooleMapForm.validate(valid => {
        if (valid) {
          this.$parent
            .postApplicationEditConfigurations(body)
            .then(() => this.$refs.gooleMapForm.clearValidate())
        }
      })
    },
    async doAllowRecurringPayment (val) {
      const body = {
        more: {
          recurringPayment: val
        },
        appCode: this.appCode
      }
      await this.$parent.postApplicationEditConfigurations(body)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/bulma-variables";
.autouser .el-select-dropdown__item {
  padding: $bleed / 2 $bleed;
  height: auto;
}
.currency-checkbox {
  margin-left: 0;
  width: 90px;
}
.nested--table {
  margin: $bleed * 2;
  border: 1px solid $grey-lighter;
}
.payment-from--name {
  width: 100%;
}
@include mobile {
  .payment-from--name {
    width: 100%;
  }
}
// TODO: put it into variable
.payment-base {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: $text;
  display: block;
  font-size: inherit;
  outline: none;
  padding: 0px 15px;
  width: 100%;
  height: 40px;
  line-height: 40px;
}
.payment-invalid {
  border-color: $danger;
}
.payment-complete {
  border-color: $success;
}
.payment-invalid + .payment-error-message {
  transform: translateY(0);
  opacity: 1;
}
.payment-error-message {
  transition: transform 100ms ease-out;
  transform: translateY(-10px);
  opacity: 0;
}
</style>
