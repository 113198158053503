<template>
  <div>
    <div v-if="error">
      <i class="el-icon-warning has-text-danger is-size-1 is-pulled-left" />
      <div style="margin-left: 70px;">
        <h5 class="is-size-5 has-text-weight-semibold has-text-black-ter">
          Error! {{ error.message }}
        </h5>
        <div class="gap"></div>
        <div class="message is-small"
          v-if="error.suggestedActions">
          <div class="message-body">
            <p class="has-text-dark has-text-weight-semibold">You might want to:</p>
            <ul class="has-text-primary">
              <li v-for="it in error.suggestedActions" :key="it.message">
                {{ it.message }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="gap-x2"></div>
    </div>
    <div
      v-if="isSuccess">
      <i class="el-icon-success has-text-success is-size-1 is-pulled-left" />
      <div style="margin-left: 70px;">
        <h5 class="is-size-5 has-text-weight-semibold has-text-black-ter">
          Invitation had been sent to {{ localForm.email }}
        </h5>
        <div class="list is-gapless">
          <div class="list-content"></div>
          <div class="list-action">
            <el-button
              @click="handleClose"
            >Close</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-content is-gapless"
      v-else>
        <el-form
          label-position="top"
          :model="localForm"
          ref="localForm"
          @submit.native="doSubmit"
          v-if="apps.length > 0">
          <el-form-item
            label="Applications"
            prop="appCode"
            :rules="[
              { required: true, message: 'Application must be selected', trigger: [ 'change', 'blur' ] }
            ]">
            <el-select
              v-model="localForm.appCode"
              value-key="appCode"
              filterable
              default-first-option
              placeholder="Please select one or more applications"
              style="width:100%"
            >
              <el-option
                v-for="item in apps"
                :key="item.appCode"
                :label="`${item.label}`"
                :value="item.appCode">
              </el-option>
            </el-select>
            <p class="form-help-text"><strong>Customer will be able to login to selected Booking sites.</strong></p>
          </el-form-item>
          <el-form-item
            label="Email"
            prop="email"
            :rules="[
              { type: 'email', message: 'Invalid email format', trigger: [ 'change', 'blur', 'input' ] },
              { required: true, message: 'Email is required', trigger: [ 'change', 'blur' ] }
            ]">
            <el-input
              v-model.trim="localForm.email"
              name="formEmail"
              type="email"
              autocomplete="off"
              placeholder="E.g: yourname@gmail.com" />
          </el-form-item>
          <el-form-item
              label="First Name"
              prop="givenName"
              :rules="[
              { required: true, message: 'First name is required', trigger: [ 'change', 'blur' ] }
            ]">
            <el-input v-model.trim="localForm.givenName"
                      name="signUpFormGivenName"
                      type="text"
                      autocomplete="given-name"
                      placeholder="E.g. Alice"/>
          </el-form-item>
          <el-form-item
              label="Last name"
              prop="familyName"
              :rules="[
              { required: true, message: 'Last name is required', trigger: [ 'change', 'blur' ] }
            ]">
            <el-input v-model.trim="localForm.familyName"
                      name="familyName"
                      type="text"
                      autocomplete="family-name"
                      placeholder="E.g. Wong"/>
          </el-form-item>
          <div class="list is-gapless">
            <div class="list-content">
            </div>
            <div class="list-action">
              <el-button
                native-type="submit"
                :loading="isLoading"
                type="success">
                Invite
              </el-button>
            </div>
          </div>
        </el-form>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { applicationsList, customersInvite, usersOne } from '../factories/manage'

export default {
  name: 'UserInvitationForm',
  props: {
    email: {
      type: String
    },
    givenName: {
      type: String
    },
    familyName: {
      type: String
    }
  },
  data () {
    return {
      isSuccess: false,
      isLoading: false,
      error: null,
      localForm: {
        email: this.email,
        givenName: this.givenName,
        familyName: this.familyName,
        appCode: null
      }
    }
  },
  computed: {
    ...mapState({
      apps: state => state.apps.list
    }),
    ...mapGetters([
      'user'
    ])
  },
  created () {
    applicationsList()
    // we want to get availabel apps on current logged in user
    if (this.user.level <= 777) {
      usersOne({ uid: this.user.uid })
    }
  },
  methods: {
    handleClose () {
      this.$emit('close')
    },
    doSubmit (e) {
      e.preventDefault()
      this.isLoading = true
      this.isSuccess = false
      this.error = null
      this.$refs.localForm.validate((valid) => {
        if (valid) {
          Promise.resolve()
            .then(() => {
              return customersInvite({
                ...this.localForm
              })
            })
            .then(() => {
              this.isSuccess = true
            })
            .catch((err) => {
              this.isLoading = false
              if (err.code === 'email_exist') {
                this.error = {
                  message: 'Email already taken.',
                  suggestedActions: [
                    { message: 'Resend the invitation email' }
                  ]
                }
                return
              }
              this.error = {
                message: err.message
              }
            })
        } else {
          this.isLoading = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';

</style>
