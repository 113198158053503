<template>
  <section class="section is-small">
    <!-- Content -->
    <div class="columns">
      <div class="column is-5" v-if="checkPermission(['APPLICATION_STATUS'])">
        <div class="list">
          <div class="list-content">
            <div class="list-title has-text-primary has-text-weight-semibold">Application Status</div>
          </div>
        </div>
        <el-form
          ref="localForm"
          @submit.native.prevent=""
          :model="localDetails">
          <div class="box is-stick">
            <div class="list is-comfort" v-if="checkPermission(['APPLICATION_STATUS'])">
              <div class="list-content">
                <div class="list-value has-text-weight-semibold">Availability search</div>
                <div class="list-label">
                  Allow to check properites availability on booking site. (city/map search, property search and retrieve cancellation policy)
              </div>
              </div>
              <div :class="['list-action is-uppercase is-flex']">
                <BlockFlag :boo="localDetails.availabilitySearch" />
                &nbsp;
                <el-switch
                  v-model="localDetails.availabilitySearch"
                  @change="doDetailsSubmit({ availabilitySearch: localDetails.availabilitySearch })"
                  active-color="#15b77c"
                  inactive-color="#909399"/>
              </div>
            </div>
            <div class="list is-comfort" v-if="checkPermission(['APPLICATION_STATUS'])">
              <div class="list-content">
                <div class="list-value has-text-weight-semibold">Room reservation</div>
                <div class="list-label">
                  Allow to make bookings on booking site. <i>*Payment setup is required</i>
              </div>
              </div>
              <div :class="['list-action is-uppercase is-flex']">
                <BlockFlag :boo="localDetails.reservation" />
                &nbsp;
                <el-switch
                  v-model="localDetails.reservation"
                  @change="doDetailsSubmit({ reservation: localDetails.reservation })"
                  active-color="#15b77c"
                  inactive-color="#909399"/>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <div class="column is-7" v-if="checkPermission(['APPLICATION_USERS_VIEW', 'APPLICATION_USERS_EDIT'])">
        <div class="list">
          <div class="list-content">
            <div
              class="list-title has-text-primary has-text-weight-semibold">Users ({{ localUsers.length }})</div>
          </div>
          <div class="list-action">
          </div>
        </div>
        <div class="box">
          <div class="list has-border-bottom" style="min-height: 54px;">
            <div
              v-if="!addingNewUser"
              class="list-content"></div>
            <template v-else>
            <div class="list-content" v-if="checkPermission(['APPLICATION_USERS_EDIT'])">
              <el-autocomplete
                style="width: 100%;"
                popper-class="my-autocomplete"
                v-model="autoSelectUser.email"
                value-key="email"
                :debounce="600"
                @select="handleAutoSelectUserSelected"
                :fetch-suggestions="getAutoUser"
                placeholder="Search email, display or invite"
                :loading="autoLoading"
                :trigger-on-focus="false">
                <i
                  class="el-icon-search el-input__icon"
                  slot="prefix">
                </i>
                <template slot-scope="{ item }">
                  <div class="list is-narrow" v-if="item.type === 'create'">
                    <div class="list-content">
                      <div class="list-label">No users found. Invite him/her?</div>
                      <div class="list-value">{{ item.email || '–' }}</div>
                    </div>
                  </div>
                  <div class="list is-narrow" v-else>
                    <div class="list-content">
                      <div class="list-value">{{ item.displayName || '–' }}</div>
                      <div class="list-label">{{ item.email || '–' }}</div>
                    </div>
                  </div>
                </template>
              </el-autocomplete>
            </div>
            <div class="list-action" v-if="checkPermission(['APPLICATION_USERS_EDIT'])">
              <el-button
                :disabled="autoSelectUser.roleCode == null || autoSelectUser.uid == null"
                @click="handleNewUser(autoSelectUser)"
                type="success">
                ADD
              </el-button>
            </div>
            </template>
            <div class="list-action" v-if="checkPermission(['APPLICATION_USERS_EDIT'])">
              <el-button
                v-if="!addingNewUser"
                type="primary"
                icon="el-icon-plus"
                @click="addNewUser()"
                size="small"
                >
                ADD USER
              </el-button>
              <el-button
              v-else
              class="is-size-4"
              type="text"
              icon="el-icon-close"
              size="mini"
              @click="addNewUser(false)"
              />
            </div>
          </div>
          <el-table
            v-if="checkPermission(['APPLICATION_USERS_VIEW', 'APPLICATION_USERS_EDIT'])"
            empty-text="This application has no users yet"
            class="users--table"
            :data="localUsers"
            stripe
            header-cell-class-name="users--table-th"
            style="width: 100%">
            <el-table-column
              label="#"
              type="index"
              align="center"
              width="30">
            </el-table-column>
            <el-table-column
              class-name="has-text-ellipsis"
              label="User"
              prop="displayName"
              min-width="140">
              <template slot-scope="{ row }">
                <UserLabel v-bind="row" />
              </template>
            </el-table-column>
            <el-table-column
              prop="inactive"
              label="Status"
              width="110"
              align="center">
              <template slot-scope="{ row }">
                <InactiveFlag :inactive="row && row.apps[0].inactive" />
              </template>
            </el-table-column>
            <el-table-column
              v-if="checkPermission(['APPLICATION_USERS_EDIT'])"
              label=""
              width="100"
              align="center">
              <template slot-scope="{ row }">
              <el-switch
                v-model="row.apps[0].inactive"
                @change="$parent.handleUserRow(row)"
                :active-value="false"
                :inactive-value="true"
                active-color="#15b77c"
                inactive-color="#909399">
              </el-switch>
              &nbsp;
              <el-popover
                placement="top"
                width="180"
                v-model="row.deleteConfirmation">
                <div style="text-align: left;">
                <p class="title is-6">Are you sure?</p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="row.deleteConfirmation = false">NO</el-button>
                  <el-button type="danger" size="mini" @click="$parent.handleUserRow(row, true)">YES</el-button>
                </div>
                </div>
                <el-button
                  slot="reference"
                  class="has-text-danger is-size-6"
                  type="text"
                  icon="el-icon-delete"
                  size="mini">
                </el-button>
              </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-dialog
      title="Invite new user" :visible.sync="inviteNewUserFormVisibility">
      <UserInvitationForm
        @close="hanldeInviteNewUserFormClosed"
        v-if="inviteNewUserFormVisibility"
        v-bind="inviteNewUserForm" />
    </el-dialog>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import UserLabel from '../../components/UserLabel'
import UserInvitationForm from '../../components/UserInvitationForm'
import { usersList, usersAutosuggest, applicationsOne } from '../../factories/manage'
import checkPermission from '@/plugins/permission'

export default {
  name: 'ManageApplicationsDetailsOverview',
  components: {
    UserLabel,
    UserInvitationForm
  },
  props: {
    appCode: {
      type: String,
      required: true
    },
    query: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      inviteNewUserFormVisibility: false,
      inviteNewUserForm: {
        appCode: this.appCode,
        email: ''
      },
      localDetails: {},
      localUsers: [],

      autoSelectUser: {},
      autoUserList: [],
      autoLoading: false,
      addingNewUser: true,
      loadingNewApp: false
    }
  },
  computed: {
    details () {
      return this.$parent.details
    },
    users () {
      return this.$parent.users
    },
    ...mapGetters(['appRoles']),
    userIndex () {
      return this.localUsers.map(ele => ele.uid)
    },
    localAllUsersList () {
      return JSON.parse(JSON.stringify(this.users.filter(ele => !this.userIndex.includes(ele.uid)))).map(ele => ({ ...ele, roleCode: 'AGENT' })).sort() || []
    }
  },
  watch: {
    'users': {
      immediate: true,
      handler (nV) {
        if (!nV) return
        this.localUsers = this.removeNull(JSON.parse(JSON.stringify(nV))).sort()
      }
    },
    'details': {
      immediate: true,
      handler (nV) {
        this.localDetails = JSON.parse(JSON.stringify(nV))
      }
    }
  },
  methods: {
    checkPermission,
    removeNull (val) {
      if (val == null) return val
      return val.filter(ele => ele != null)
    },
    getAutoUser (q, cb) {
      if (q !== '' && q.length > 2) {
        this.autoLoading = true
        usersAutosuggest({ q: q.toLowerCase().trim() })
          .then(({ rows }) => {
            this.autoLoading = false
            this.autoUserList = rows.filter((ele) => !this.userIndex.includes(ele.uid))
            if (this.autoUserList.length > 0) {
              cb(this.autoUserList)
            } else {
              /**
               * If no user find we suggested to invite them
               */
              const l = [{ email: q, type: 'create' }]
              cb(l)
            }
          })
          .catch(err => {
            this.autoLoading = false
            console.error(err)
          })
      } else {
        const l = []
        cb(l)
      }
    },
    doResetAutoSelectUserToInitialState () {
      this.autoSelectUser = {}
    },
    addNewUser (boo) {
      this.addingNewUser = boo
      if (!boo) {
        this.doResetAutoSelectUserToInitialState()
      }
    },
    handleNewUser (val) {
      this.$parent.handleNewUser(val)
        .then(() => {
          this.doResetAutoSelectUserToInitialState()
        })
    },
    handleAutoSelectUserSelected (val) {
      if (val.type === 'create') {
        this.inviteNewUserFormVisibility = true
        this.autoSelectUser = { email: val.email }
        this.inviteNewUserForm.email = val.email
        return
      }
      this.autoSelectUser = val
    },
    doDetailsSubmit (val, field) {
      const body = {
        ...val,
        appCode: this.appCode
      }
      if (field == null) {
        this.$parent.postApplicationEdit(body)
          .then(() => this.$refs.localForm.clearValidate())
      } else {
        this.$refs.localForm.validateField(field, (error) => {
          if (!error) {
            this.$parent.postApplicationEdit(body)
              .then(() => this.$refs.localForm.clearValidate())
          }
        })
      }
    },
    hanldeInviteNewUserFormClosed () {
      this.inviteNewUserFormVisibility = false
      this.doResetAutoSelectUserToInitialState()
      applicationsOne({ appCode: this.appCode })
      usersList({ appCode: this.appCode })
    },
    sort (val) {
      const cloned = JSON.parse(JSON.stringify(val))
      return cloned.sort((a, b) => b.createdAt - a.createdAt)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/bulma-variables';
  .autouser .el-select-dropdown__item {
    padding: $bleed / 2 $bleed;
    height: auto;
  }
  // .users--table /deep/ .users--table-th {
  //   padding-bottom: $bleed;
  //   color: $grey;
  //   border-color: $grey-lighter;
  //   border-width: 2px;
  //   background-color: $white-bis;
  // }
  // .users--table /deep/ .is-hidden {
  //   display: table-cell !important;
  // }
  .nested--table {
    margin: $bleed * 2;
    border: 1px solid $grey-lighter;
  }
</style>
