import lv2, {
  axiosErrorHandler
} from './lv2Api'

import {
  Message
} from 'element-ui'
import store from '../store'

/**
 * Stripe Card Management
 */
export const stripeCardIntent = (body) => {
  return lv2.post('/config/stripe/intent', body, {})
    .then(({
      data
    }) => {
      store.commit('SET_STRIPE_CLIENT_SECRET', data.outlets.clientSecret)
    })
    .catch((err) => {
      const parseErr = axiosErrorHandler(err)
      Message({
        showClose: true,
        duration: 0,
        dangerouslyUseHTMLString: true,
        message: `<div>Fail!, ${parseErr.message}<br> ${parseErr.meta ? parseErr.meta.reqId : ''}</div>`,
        type: 'error'
      })
      store.dispatch('setMeta', parseErr.meta)
      throw parseErr
    })
}

export const stripeAddCard = (body) => {
  return lv2.post('/config/stripe/addCard', body, {})
    .then(({
      data
    }) => {
      Message({
        message: 'A new card has been added!',
        type: 'success'
      })
      return data.outlets
    })
    .catch((err) => {
      const parseErr = axiosErrorHandler(err)
      Message({
        showClose: true,
        duration: 0,
        dangerouslyUseHTMLString: true,
        message: `<div>Fail!, ${parseErr.message}<br> ${parseErr.meta ? parseErr.meta.reqId : ''}</div>`,
        type: 'error'
      })
      store.dispatch('setMeta', parseErr.meta)
      throw parseErr
    })
}

export const stripeRemoveCard = (body) => {
  return lv2.post('/config/stripe/removeCard', body, {})
    .then(({
      data
    }) => {
      Message({
        message: 'The card has been removed!',
        type: 'success'
      })
      return data.outlets
    })
    .catch((err) => {
      const parseErr = axiosErrorHandler(err)
      Message({
        showClose: true,
        duration: 0,
        dangerouslyUseHTMLString: true,
        message: `<div>Fail!, ${parseErr.message}<br> ${parseErr.meta ? parseErr.meta.reqId : ''}</div>`,
        type: 'error'
      })
      store.dispatch('setMeta', parseErr.meta)
      throw parseErr
    })
}

export const stripeCards = (params) => {
  return lv2.get('/config/stripe/cards', {
    params
  }).then(({
    data
  }) => {
    store.commit('SET_STRIPE_CREDIT_CARDS', data.outlets.list)
  }).catch((err) => {
    const parseErr = axiosErrorHandler(err)
    Message({
      showClose: true,
      duration: 0,
      dangerouslyUseHTMLString: true,
      message: `<div>Fail!, ${parseErr.message}<br> ${parseErr.meta ? parseErr.meta.reqId : ''}</div>`,
      type: 'error'
    })
    store.dispatch('setMeta', parseErr.meta)
    throw parseErr
  })
}

/**
 * PCI Booking
 */
export const pciBookingSession = (params) => {
  return lv2.get('/config/pci-booking/session', { params })
    .then(({
      data
    }) => {
      store.commit('SET_PCI_BOOKING_INFO', {
        token: data.outlets.paymentToken,
        apiUrl: data.outlets.paymentApi,
        brand: data.outlets.paymentBrand
      })
    })
    .catch((err) => {
      const parseErr = axiosErrorHandler(err)
      Message({
        showClose: true,
        duration: 0,
        dangerouslyUseHTMLString: true,
        message: `<div>Fail!, ${parseErr.message}<br> ${parseErr.meta ? parseErr.meta.reqId : ''}</div>`,
        type: 'error'
      })
      store.dispatch('setMeta', parseErr.meta)
      throw parseErr
    })
}

export const pciBookingRemoveCard = (params) => {
  return lv2.delete('/config/pci-booking/removeCard', { params })
    .then(({
      data
    }) => {
      Message({
        message: 'The card has been removed!',
        type: 'success'
      })
      return data.outlets
    })
    .catch((err) => {
      const parseErr = axiosErrorHandler(err)
      Message({
        showClose: true,
        duration: 0,
        dangerouslyUseHTMLString: true,
        message: `<div>Fail!, ${parseErr.message}<br> ${parseErr.meta ? parseErr.meta.reqId : ''}</div>`,
        type: 'error'
      })
      store.dispatch('setMeta', parseErr.meta)
      throw parseErr
    })
}

export const pciBookingCards = (params) => {
  return lv2.get('/config/pci-booking/cards', {
    params
  }).then(({
    data
  }) => {
    store.commit('SET_PCI_BOOKING_CREDIT_CARDS', data.outlets.list)
  }).catch((err) => {
    const parseErr = axiosErrorHandler(err)
    Message({
      showClose: true,
      duration: 0,
      dangerouslyUseHTMLString: true,
      message: `<div>Fail!, ${parseErr.message}<br> ${parseErr.meta ? parseErr.meta.reqId : ''}</div>`,
      type: 'error'
    })
    store.dispatch('setMeta', parseErr.meta)
    throw parseErr
  })
}
