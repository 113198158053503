<template>
  <section class="section is-small">
    <div class="" v-if="localDetails">
      <div class="list is-gapless">
        <div class="list-pre">
          <HistoryLink
            :historyBack="true"
            label="back"
            :route="{ name: 'manageCustomers' }">
            <i class="el-icon-back is-size-4" />
          </HistoryLink>
        </div>
        <div class="list-content">
          <h4 class="title is-5">{{details.displayName || details.email}}</h4>
        </div>
        <div class="list-action">
        </div>
      </div>
      <div class="columns">
        <div class="column is-5">
          <div class="list">
            <div class="list-content">
              <div class="list-label has-text-primary has-text-weight-semibold">Details</div>
            </div>
          </div>
        <el-form
          ref="localForm"
          @submit.native.prevent=""
          :model="localDetails">
          <div class="box is-stick">
            <div class="list has-shadow-bottom is-comfort" v-if="checkPermission(['USERS_EDIT'])">
              <div class="list-content">
                <div class="list-value  has-text-weight-semibold">User status</div>
                <div class="list-label"
                  v-if="details.inactive">
                  <strong>User can not login and all his activities has been suspended.</strong>
                </div>

              </div>
              <div :class="['list-action is-uppercase is-flex']">
                <InactiveFlag :inactive="details.inactive" />
                &nbsp;
                <el-switch
                  v-model="localDetails.inactive"
                  @change="doDetailsSubmit({ inactive: localDetails.inactive })"
                  :active-value="false"
                  :inactive-value="true"
                  active-color="#15b77c"
                  inactive-color="#909399"/>
              </div>
            </div>
            <div class="list is-comfort has-shadow-bottom">
              <div class="list-content">
                <div class="list-label">Email</div>
                  <el-form-item
                    prop="email"
                    :rules="[
                      {
                        required: true,
                        message: 'This is required.',
                        trigger: ['blur', 'change', 'input']
                      },
                      {
                        type: 'email',
                        message: 'Please input correct email address',
                        trigger: ['blur', 'change'],
                      },
                    ]"
                  >
                    <el-input
                      :disabled="!checkPermission(['USERS_EDIT'])"
                      name="formEmail"
                      size="small"
                      placeholder="Current Email address"
                      v-model="localDetails.email">
                    </el-input>
                  </el-form-item>
              </div>
              <div class="list-action">
                <el-button type="primary"
                  size="small"
                  :disabled="localDetails.email === details.email"
                  @click="doDetailsSubmit({ email: details.email, newEmail: localDetails.email }, 'email')"
                >
                  SAVE
              </el-button>
              </div>
            </div>
            <div class="list is-comfort has-shadow-bottom">
              <div class="list-content">
                <div class="list-label">Display name</div>
                  <el-form-item
                    prop="displayName"
                    :rules="[
                    { required: true, message: 'This is required.', trigger: ['blur', 'change', 'input']}
                    ]">
                    <el-input
                      :disabled="!checkPermission(['USERS_EDIT'])"
                      name="formDisplayName"
                      size="small"
                      placeholder="How to address this user?"
                      v-model="localDetails.displayName">
                    </el-input>
                  </el-form-item>
              </div>
              <div class="list-action">
                <el-button type="primary"
                  size="small"
                  :disabled="localDetails.displayName === details.displayName"
                  @click="doDetailsSubmit({ displayName: localDetails.displayName }, 'displayName')">SAVE</el-button>
              </div>
            </div>
            <div class="list is-comfort">
              <div class="list-content">
                <div class="list-label">Invited by</div>
                <div class="list-value">{{ details.invitedBy && details.invitedBy.displayName }}</div>
              </div>
            </div>
          </div>
          </el-form>
          <div class="box">
            <div class="list">
              <div class="list-content">
                <div class="list-label has-text-primary has-text-weight-semibold">
                  <i class="el-icon-time"/> History
                </div>
              </div>
            </div>
            <div class="list is-small has-shadow-bottom"
              v-for="it in sort(history)"
              :key="it.createdAt">
              <div class="list-content">
                <div class="list-label">{{ it.createdAt | date }}</div>
                <div class="list-value">
                  {{ getType(it.type) }} &nbsp;
                <el-popover
                  v-if="it.req"
                  placement="top-start"
                  title="Request"
                  trigger="click"
                >
                  <pre class="is-size-7">{{ it.req }}</pre>
                  <a class="is-size-7" slot="reference">Request</a>
                </el-popover>
                &nbsp;
                <el-popover
                  v-if="it.res"
                  placement="top-start"
                  title="Response"
                  trigger="click"
                >
                  <pre class="is-size-7">{{ it.res }}</pre>
                  <a class="is-size-7" slot="reference">Response</a>
                </el-popover>
                </div>
              </div>
              <div class="list-action has-text-right" style="width: 100px;">
                <div class="list-label">By</div>
                <div class="list-value has-text-ellipsis">{{ it.by || '–' }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="list-content">
        <div class="list-value has-text-weight-semibold">User not found or you don't have permission to view this user</div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { customersOne, customersEdit } from '../../factories/manage'
import checkPermission from '@/plugins/permission'

export default {
  name: 'ManageCustomerDetails',
  props: {
    uid: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      localDetails: {}
    }
  },
  computed: {
    ...mapState({
      details: state => state.customersDetails.details,
      history: state => state.customersDetails.details.history
    })
  },
  watch: {
    'details': {
      immediate: true,
      handler (nV) {
        console.log(nV)
        this.localDetails = JSON.parse(JSON.stringify(nV))
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    customersOne({ uid: to.params.uid })
      .then(() => next())
      .catch((err) => {
        console.error(err)
        next()
      })
  },
  methods: {
    checkPermission,
    removeNull (val) {
      if (val == null) return val
      return val.filter(ele => ele != null)
    },
    doDetailsSubmit (val, field) {
      const body = {
        ...val,
        uid: this.uid
      }
      const submit = () => {
        customersEdit(body)
          .then(() => {
            return customersOne({ uid: this.uid })
          })
          .then(() => {
            this.$refs.localForm.clearValidate()
          })
          .catch((err) => {
            console.error(err)
          })
      }

      if (field == null) {
        submit()
      } else {
        this.$refs.localForm.validateField(field, (error) => {
          if (!error) {
            submit()
          }
        })
      }
    },
    sort (val) {
      if (!val) return
      const cloned = JSON.parse(JSON.stringify(val))
      return cloned.sort((a, b) => b.createdAt - a.createdAt)
    },
    getType (historyType) {
      let result = historyType
      switch (historyType) {
        case 'CREATED':
          result = 'User has been created'
          break
        case 'EDITED':
          result = 'User has been edited'
          break
        case 'CONFIRMATION_ISSUED':
          result = 'Confirmation link has been sent'
          break
        case 'INVITATION_ISSUED':
          result = 'Send invitation email'
          break
        case 'INVITATION_REISSUED':
          result = 'Resend invitation email'
          break
        case 'INVITATION_ACCEPTED':
          result = 'Invitation has been accepted'
          break
        case 'FORGOT_PASSWORD':
          result = 'Forgot Password'
          break
      }
      return result
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/bulma-variables';
  .autouser .el-select-dropdown__item {
    padding: $bleed / 2 $bleed;
    height: auto;
  }
  .users--table /deep/ .users--table-th {
    padding-bottom: $bleed;
    color: $grey;
    border-color: $grey-lighter;
    border-width: 2px;
    background-color: $white-bis;
  }
  .users--table /deep/ .is-hidden {
    display: table-cell !important;
  }
  .nested--table {
    margin: $bleed * 2;
    border: 1px solid $grey-lighter;
  }
</style>
